/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo, useRef } from 'react';

import { Button, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { SearchIcon } from '@coursera/cds-icons';

import { useDisableScrollOnMobile } from 'bundles/front-page/hooks/useDisableScrollOnMobile';
import { useScrollToSearchBarOnFocused } from 'bundles/front-page/hooks/useScrollToSearchBarOnFocused';
import type { SearchBarLocation } from 'bundles/search-common/constants';
import { SEARCH_BAR_LOCATION } from 'bundles/search-common/constants';

import _t from 'i18n!nls/front-page';

type Props = {
  children: JSX.Element;
  searchButtonOnClick?: (e: React.SyntheticEvent) => void;
  isFocused: boolean;
  pageLocation: SearchBarLocation;
  isLazyLoading?: boolean;
};

const styles = {
  container: (theme: Theme, isFocused: boolean) => css`
    display: flex;
    max-width: 600px;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      max-width: initial;
    }

    @media (max-width: 991px) {
      width: ${isFocused ? '85%' : '100%'};
      padding: ${isFocused ? '4px 0 0 4px' : '0'};
    }
  `,

  searchButtonContainer: (theme: Theme) => css`
    background-color: white;
    display: flex;
    align-items: center;
    padding: 4px;
    border-top: 1px solid ${theme.palette.gray[300]};
    border-right: 1px solid ${theme.palette.gray[300]};
    border-bottom: 1px solid ${theme.palette.gray[300]};
    border-radius: 0 100px 100px 0;
  `,
  searchButtonHeader: (isFocused: boolean) => css`
    border-radius: 100px;
    padding: ${isFocused ? '7px 12px' : '7px'};
    height: 30px;

    .cds-button-endIcon {
      margin-left: ${isFocused ? '4px' : 0};
    }
  `,
  searchButtonBanner: (isFocused: boolean) => css`
    border-radius: 100px;
    padding: ${isFocused ? '14px 12px' : '14px'};
    height: 44px;

    .cds-button-endIcon {
      margin-left: ${isFocused ? '4px' : 0};
    }
  `,
};

const HeroBannerSearchBar = ({ children, searchButtonOnClick, isFocused, isLazyLoading, pageLocation }: Props) => {
  const theme = useTheme();
  const searchBarRef = useRef<null | HTMLDivElement>(null);
  useDisableScrollOnMobile(isFocused);
  useScrollToSearchBarOnFocused(isFocused, pageLocation, searchBarRef);

  return (
    <div ref={searchBarRef} css={styles.container(theme, isFocused)}>
      {children}
      <div css={styles.searchButtonContainer}>
        <Button
          onClick={searchButtonOnClick}
          onMouseDown={searchButtonOnClick}
          variant="primary"
          icon={<SearchIcon size="small" />}
          css={
            pageLocation === SEARCH_BAR_LOCATION.FrontPage
              ? styles.searchButtonHeader(isFocused)
              : styles.searchButtonBanner(isFocused)
          }
          aria-label={_t('Submit Search')}
          type={isLazyLoading ? 'submit' : 'button'}
        >
          {isFocused && _t('Search')}
        </Button>
      </div>
    </div>
  );
};

export default memo(HeroBannerSearchBar);
