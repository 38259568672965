import type { ComponentType } from 'react';

import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';
import type { PaymentProcessorType } from 'bundles/payments/common/PaymentProcessorTypes';
import type { ProductType } from 'bundles/payments/common/ProductType';
import ProductTypeObject from 'bundles/payments/common/ProductType';
import type { SubscriptionPaymentInformation } from 'bundles/payments/common/types';
import SubscriptionStatus from 'bundles/subscriptions/common/SubscriptionStatus';

import NaptimeResource from './NaptimeResource';

type Fields = Array<keyof Subscriptions>;

type Query = {
  fields?: Fields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subcomponents?: Array<ComponentType<any>>;
};
class Subscriptions extends NaptimeResource {
  static RESOURCE_NAME = 'subscriptions.v1' as const;

  id!: string;

  subscriptionId?: string;

  cartId?: number;

  isActive!: boolean;

  // Timestamp in milliseconds
  startsAt?: number;

  nextBillingAt!: number;

  paymentInformation?: SubscriptionPaymentInformation;

  productItemId!: string;

  productSku?: string;

  productType!: ProductType;

  status?: keyof typeof SubscriptionStatus;

  paymentProcessorId?: PaymentProcessorType;

  static findByUserAndProduct(userId: $TSFixMe, productId: $TSFixMe, data: $TSFixMe) {
    return this.finder('findByUserAndProduct', Object.assign({ params: { userId, productId } }, data));
  }

  static findByUser(userId: $TSFixMe, data: $TSFixMe) {
    return this.finder('findByUser', Object.assign({ params: { userId } }, data));
  }

  static me({ fields = [], subcomponents }: Query = {}) {
    return this.finder('me', { params: {}, fields, subcomponents });
  }

  @requireFields('status')
  get isCancelled() {
    return this.status === SubscriptionStatus.CANCELLED;
  }

  @requireFields('status')
  get isFreeTrial() {
    return this.status === SubscriptionStatus.FREE_TRIAL;
  }

  @requireFields('status')
  get isInactive() {
    return this.status === SubscriptionStatus.INACTIVE;
  }

  @requireFields('status')
  get isPastDue() {
    return this.status === SubscriptionStatus.PAST_DUE;
  }

  @requireFields('status')
  get hasSubscriptionNextBillDetails() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"ACTIVE" | "CANCELLED" | "FREE_T... Remove this comment to see the full error message
    return [SubscriptionStatus.FREE_TRIAL, SubscriptionStatus.ACTIVE].includes(this.status) && this.nextBillingAt;
  }

  @requireFields('productType')
  get isSpecializationSubscription() {
    return this.productType === ProductTypeObject.SPECIALIZATION;
  }

  @requireFields('productType')
  get isCatalogSubscription() {
    return this.productType === ProductTypeObject.CATALOG_SUBSCRIPTION;
  }

  @requireFields('productType')
  get isCredentialTrackSubscription() {
    return (
      this.productType === ProductTypeObject.CREDENTIAL_TRACK_SUBSCRIPTION ||
      this.productType === ProductTypeObject.CREDENTIAL_TRACK_SUBSCRIPTION_V2
    );
  }

  @requireFields('productType')
  get isCourseraPlusSubscription() {
    return this.productType === ProductTypeObject.COURSERA_PLUS_SUBSCRIPTION;
  }

  @requireFields('productType')
  get isCourseraLiteSubscription() {
    return this.productType === ProductTypeObject.COURSERA_TIER_LITE;
  }

  @requireFields('paymentInformation')
  get paymentWalletId() {
    return (
      this.paymentInformation &&
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.paymentInformation['org.coursera.payment.WalletSubscriptionPaymentInformation'].paymentWalletId
    );
  }

  @requireFields('paymentInformation')
  get isAppleInApp() {
    return (
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.paymentInformation && !!this.paymentInformation['org.coursera.payment.IapSubscriptionPaymentInformation']
    );
  }

  @requireFields('paymentInformation')
  get stripeSubscriptionPaymentInformation() {
    return (
      this.paymentInformation &&
      'org.coursera.payment.StripeSubscriptionPaymentInformation' in this.paymentInformation &&
      this.paymentInformation['org.coursera.payment.StripeSubscriptionPaymentInformation']
    );
  }
}

export default Subscriptions;
