import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import { get as getUser, isAuthenticatedUser } from 'js/lib/user';

import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import CourseraPlusProductVariants, {
  isMonthlyWithFreeTrial,
} from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { constants } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import { useNaptime } from 'bundles/naptimejs';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import SubscriptionTrialsV1 from 'bundles/naptimejs/resources/subscriptionTrials.v1';
import { isNewCPlusEntryPointsVariant, isNewCPlusEntryPointsVariantC } from 'bundles/page-header/utils/experimentUtils';
import { COURSERA_PLUS_SUBSCRIPTION } from 'bundles/payments/common/ProductType';

type PropsFromNaptime = {
  subscriptionTrials: Array<SubscriptionTrialsV1>;
};

type PropsFromCaller = {
  productItemId: SubscriptionTiersProductVariant;
};

export type NewCPlusEntryPointsEligibilityStatus = {
  shouldShowCourseraPlusBanner: boolean;
  shouldHideReferralLink: boolean;
  isEligibleUser: boolean;
};

const useCourseraPlusFreeTrials = ({ productItemId }: PropsFromCaller) => {
  const { COURSERA_PLUS_SUBSCRIPTION_UNDERLYING_PRODUCT_ITEM_ID } = constants;
  const shouldFetchTrials = isMonthlyWithFreeTrial(productItemId);

  const {
    data: { subscriptionTrials },
  } = useNaptime<PropsFromNaptime>(() => {
    if (shouldFetchTrials) {
      return {
        subscriptionTrials: isAuthenticatedUser()
          ? SubscriptionTrialsV1.finder('findByUserAndUnderlyingProduct', {
              params: {
                userProductItemId: tupleToStringKey([
                  getUser().id,
                  COURSERA_PLUS_SUBSCRIPTION,
                  COURSERA_PLUS_SUBSCRIPTION_UNDERLYING_PRODUCT_ITEM_ID,
                ]),
              },
            })
          : [],
      };
    } else {
      return { subscriptionTrials: [] };
    }
  }, [productItemId, shouldFetchTrials]);

  return subscriptionTrials;
};

const useNewCPlusEntryPointsEligibilityCheck = (): NewCPlusEntryPointsEligibilityStatus => {
  const { subscriptions } = useSubscriptionsByUser();
  const subscriptionTrials = useCourseraPlusFreeTrials({
    productItemId: CourseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
  });

  // length = 0 means that the user hasn't used the free trial before
  const isPlusMonthlyFreeTrialAvailable = subscriptionTrials?.length === 0;

  const hasActiveCourseraPlusOrLiteSubscription = subscriptions?.find(
    (subscription) =>
      subscription.isActive && (subscription.isCourseraPlusSubscription || subscription.isCourseraLiteSubscription)
  );

  const isEligibleUser = !hasActiveCourseraPlusOrLiteSubscription && isPlusMonthlyFreeTrialAvailable;

  return {
    shouldShowCourseraPlusBanner: isEligibleUser && isNewCPlusEntryPointsVariant(),
    shouldHideReferralLink: isEligibleUser && isNewCPlusEntryPointsVariantC(),
    isEligibleUser,
  };
};

export default useNewCPlusEntryPointsEligibilityCheck;
