import type { LocationDescriptor } from 'react-router';

import requestCountry from 'js/lib/requestCountry';

import type { CtaLine } from 'bundles/coursera-plus/components/ctaContentful/CTAContentful';
import type { ConnectedPiece } from 'bundles/coursera-plus/components/ctaContentful/CTAContentfulLine';
import {
  BigButton,
  BigButtonFullWidthMdAndDown,
  BigButtonFullWidthSmAndDown,
  BigButtonNoExpand,
  BigButtonSecondary,
  Link,
  LinkOnDark,
  SmallButton,
} from 'bundles/coursera-plus/components/ctaContentful/EnrollButtonPresentationContentful';
import CourseraPlusProductVariants, {
  courseraLiteProductVariants,
} from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import type { CourseraPlusCtaPartPicked } from 'bundles/coursera-plus/contentful/fetchContentful';
import courseraPlusEpicClient from 'bundles/epic/clients/courseraPlus';
import type OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';

import _t from 'i18n!nls/coursera-plus';

export const HIDDEN_SLUG_FOR_DEFAULT_LANDING_PAGE = 'insite';

export const heroDesignChoices = {
  YES_I_CAN: 'Yes I can',
  YELLOW_DOTS: 'Yellow Dots',
  NO_HERO_IMAGE: 'No Hero Image',
  TEST_DESIGN: 'Test Design',
};

export const landingPageTypeChoices = {
  COMPUTER_SCIENCE_COLLECTION: 'Bundles Landing Page (Computer Science)',
  PURCHASE_TRACKER: 'Purchase Tracker Landing Page',
  COURSERA_LITE_LANDING_PAGE: 'Coursera Lite Landing Page',
};

export const contentfulValuesForProductVariant = {
  'Monthly with Free Trial': CourseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
  'Annual with No Free Trial': CourseraPlusProductVariants.ANNUAL_NO_FREE_TRIAL,
  'Annual special pricing with no free trial':
    CourseraPlusProductVariants.ANNUAL_SPECIAL_DISCOUNTED_PRICING_NO_FREE_TRIAL,
  'Coursera Lite Monthly with Free Trial': courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL,
} as const;

export const getProductFromContentful = (contentful: string): SubscriptionTiersProductVariant | undefined => {
  const value = Object.entries(contentfulValuesForProductVariant).find((e) => {
    return e[0] === contentful;
  });
  return value ? value[1] : undefined;
};

export const PRODUCT_CHOICES = {
  primary: 'Primary',
  secondary: 'Secondary',
};

export const CTA_LOCATIONS = {
  hero: 'hero',
  heroRedesigned: 'hero_redesigned',
  footer: 'footer',
  sticky: 'sticky_banner',
  stickyRedesigned: 'sticky_banner_redesigned',
  learnerStats: 'learner_statistics',
  footerRedesigned: 'footer_redesigned',
};

export const PRESENTATIONS = {
  plain: 'Plain',
  bigButton: 'Big button',
  smallButton: 'Small Button',
  link: 'Link',
};

interface SomeCtaPiece {
  presentation?: string | null;
}

export const isPieceForCheckout = (piece: SomeCtaPiece): boolean => {
  return piece.presentation !== PRESENTATIONS.plain;
};

export const isPresentationButton = (presentation: string): boolean => {
  if (presentation === PRESENTATIONS.bigButton || presentation === PRESENTATIONS.smallButton) {
    return true;
  }
  return false;
};

export const pieceForPrimaryButton = (
  presentation: $TSFixMe,
  label: $TSFixMe,
  isFirstCheckoutInHero: boolean
): ConnectedPiece => ({
  product: PRODUCT_CHOICES.primary,
  presentation,
  messages: [{ entryTitle: null, pretranslated: null, freeform: label }],
  isFirstCheckoutInHero,
});

export const artificialCtaLines = (label: string): Array<CtaLine> => [
  {
    lineNumber: 1,
    firstLineWithCheckout: true,
    pieces: [{ product: PRODUCT_CHOICES.primary, freeform: label, presentation: PRESENTATIONS.bigButton }],
  },
];

export const searchLabel = (): string => _t('Search Coursera Plus');

export const isActionPresentation = (presentation: string | null | undefined) => {
  return typeof presentation === 'string' && presentation !== PRESENTATIONS.link;
};

const LOCATIONS_WITH_SECONDARY_BUTTON = [CTA_LOCATIONS.learnerStats, CTA_LOCATIONS.footerRedesigned];

export const actionComponent = (presentation: string, singleBigButton: boolean, ctaLocation: string) => {
  if (presentation === PRESENTATIONS.bigButton) {
    if (singleBigButton) {
      if (LOCATIONS_WITH_SECONDARY_BUTTON.includes(ctaLocation)) {
        return BigButtonSecondary;
      }
      if (ctaLocation === CTA_LOCATIONS.stickyRedesigned) {
        return BigButtonFullWidthMdAndDown;
      }
      if (ctaLocation === CTA_LOCATIONS.heroRedesigned) {
        return BigButtonFullWidthSmAndDown;
      }
      return BigButton;
    }
    return BigButtonNoExpand;
  }
  if (presentation === PRESENTATIONS.smallButton) {
    return SmallButton;
  }
  if (ctaLocation === CTA_LOCATIONS.footer) {
    return LinkOnDark;
  }
  return Link;
};

const preview = 'preview';
const noFreeTrial = 'nofreetrial';

const isMarkedInSearch = (location: LocationDescriptor, key: string) => {
  const keys = Object.keys(location.query);
  return !!keys.find((k) => k.toLocaleLowerCase() === key);
};

export const isPreview = (router: $TSFixMe) => {
  return isMarkedInSearch(router.location, preview);
};

export const isMockNoTrial = (location: LocationDescriptor) => {
  return isMarkedInSearch(location, noFreeTrial);
};

export const SHOW_PART_CONDITIONS = {
  freeTrialAvailable: 'Only if free trial is available',
  freeTrialNotAvailable: 'Only if free trial is not available',
};

export const showPart = (part: CourseraPlusCtaPartPicked, hasFreeTrial: boolean): boolean => {
  const { conditionOfShowing } = part;
  if (conditionOfShowing === SHOW_PART_CONDITIONS.freeTrialAvailable) {
    return hasFreeTrial;
  }
  if (conditionOfShowing === SHOW_PART_CONDITIONS.freeTrialNotAvailable) {
    return !hasFreeTrial;
  }
  return true;
};

export const renewSubscriptionLabel = (): string => _t('Renew Subscription');

export const indicateRedirectedFromExpired = 'redirectedfromexpired';

export const previewIsCourseraLiteLandingPageEnabled = () => {
  return courseraPlusEpicClient.preview('subscriptionTiersLiteLandingPageVariant') !== 'optOut';
};

export const generateSubscriptionTiersLiteLandingPageVariant = () => {
  return courseraPlusEpicClient.get('subscriptionTiersLiteLandingPageVariant');
};

export const getIsCourseraLiteLandingPageEnabled = (openCourseMemberships?: OpenCourseMembershipsV1[] | null) => {
  const isAuditLearner = openCourseMemberships?.some((membership) => membership?.isLearner);

  return requestCountry.get() !== 'IN' && previewIsCourseraLiteLandingPageEnabled() && !isAuditLearner;
};
