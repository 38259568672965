/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { Global, css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import moment from 'moment-timezone';

import { useTheme } from '@coursera/cds-core';

import { previewAdminChatHours, previewEducatorChatHours } from 'bundles/common/utils/globalHelpButtonUtils';

import _t from 'i18n!nls/about';

import type { PageType } from '../../utils/initializeSFChat';
import { initializeSFChatConfig } from '../../utils/initializeSFChat';
import HelpBoxContainer from './HelpBoxContainer';
import Popover from './HelpPopover';
import PreChatForm from './HelpPreChat';

const useStyles = () => {
  const theme = useTheme();

  return {
    global: css`
      // Need global CSS to override default styling of the Salesforce chat minimized button
      .embeddedServiceSidebarMinimizedDefaultUI {
        background-color: ${theme.palette.blue[600]} !important;
        border-color: ${theme.palette.blue[600]} !important;
        right: ${theme.spacing(80)} !important;
      }
    `,
  };
};

type HelpBoxText = 'CHAT_WITH_US';

type HelpBoxProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  isAdminChat?: boolean;
  isEducatorChat?: boolean;
};

const getText = (text: HelpBoxText) => {
  switch (text) {
    case 'CHAT_WITH_US':
      return _t('Chat with us');
    default:
      return '';
  }
};

const HelpBox: React.FC<HelpBoxProps> = ({ isOpen, setIsOpen, isAdminChat, isEducatorChat }) => {
  const [isAgentAvailable, setIsAgentAvailable] = useState(false);
  const styles = useStyles();

  useEffect(() => {
    /*
    Educator and admin chat are only available during operating hours. We store operating hours as a config value in EPIC rather than in FE code to avoid re-deploying all apps if the hours change
    If the current time is outside operating hours, we setIsAgentAvailable to false in order to display a link to the help center rather than the pre-chat form
    Otherwise, we initialize and display Salesforce chat
    */

    const getChatDisplay = (pageType: PageType, startTime: string, endTime: string) => {
      const now = moment();
      // Chat operating hours are based on pacific time so we use the 'America/Los_Angeles' timezone
      const chatStartTime = moment.tz(startTime, 'HH:mm', 'America/Los_Angeles');
      const chatEndTime = moment.tz(endTime, 'HH:mm', 'America/Los_Angeles');

      if (now.isBetween(chatStartTime, chatEndTime)) {
        initializeSFChatConfig(pageType, setIsAgentAvailable);
      } else {
        setIsAgentAvailable(false);
      }
    };

    if (isAdminChat) {
      const { startTime: adminChatStartHour, endTime: adminChatEndHour } = previewAdminChatHours();
      getChatDisplay('admin', adminChatStartHour, adminChatEndHour);
    }

    if (isEducatorChat) {
      const { startTime: educatorChatStartHour, endTime: educatorChatEndHour } = previewEducatorChatHours();
      getChatDisplay('educator', educatorChatStartHour, educatorChatEndHour);
    }
  }, [isAdminChat, isEducatorChat]);

  const closePopover = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Global styles={styles.global} />
      <div className="rc-Help" style={{ minWidth: 0, bottom: 20, right: 20 }}>
        <Popover offset={{ bottom: 80, right: 20 }} isOpen={isOpen} onClose={closePopover}>
          {/* Popover contains pre-chat form for either admin or educator chat, depends if the current page is an admin or educator page */}
          <HelpBoxContainer title={getText('CHAT_WITH_US')} onClose={closePopover}>
            <PreChatForm
              onClose={closePopover}
              isAgentAvailable={isAgentAvailable}
              isAdminChat={isAdminChat}
              isEducatorChat={isEducatorChat}
            />
          </HelpBoxContainer>
        </Popover>
      </div>
    </>
  );
};

export default HelpBox;
