/**
 * Group model
 */
import cloneDeep from 'lodash/cloneDeep';

import CSVConstants from 'bundles/groups-common/constants/CSVConstants';
import GroupScope from 'bundles/groups/models/GroupScope';

class Group {
  id: string;

  typeName: string;

  definition: {
    name: string;
    slug: string;
    locale: string | undefined;
    scopedId: {
      id: string;
      name: string;
    };
  };

  scope: GroupScope;

  constructor({ id, typeName, definition }: $TSFixMe) {
    this.id = id;
    this.typeName = typeName;
    this.definition = definition;
    if (typeName === 'programGroup') {
      this.scope = new GroupScope({ id, name: 'program' });
    } else {
      this.scope = new GroupScope(definition.scopeId);
    }
  }

  get name() {
    return this.definition.name;
  }

  set name(value) {
    this.definition.name = value;
  }

  get slug() {
    return this.definition.slug;
  }

  get locale() {
    return this.definition.locale;
  }

  set locale(value) {
    this.definition.locale = value;
  }

  get emailDomain() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'definition' does not exist on type 'Grou... Remove this comment to see the full error message
    return this.definition.emailDomain;
  }

  set emailDomain(value) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'definition' does not exist on type 'Grou... Remove this comment to see the full error message
    this.definition.emailDomain = value;
  }

  get courseId() {
    return this.scope.courseId;
  }

  get LMSType() {
    let type;
    switch (this.typeName) {
      case 'blackboardPrivateCommunity':
        if (this.emailDomain) {
          type = CSVConstants.Types.BlackboardV2;
        } else {
          type = CSVConstants.Types.BlackboardV1;
        }
        break;
      case 'moodlePrivateCommunity':
        type = CSVConstants.Types.Moodle;
        break;
      case 'sakaiPrivateCommunity':
        type = CSVConstants.Types.Sakai;
        break;
      case 'canvasPrivateCommunity':
        type = CSVConstants.Types.Canvas;
        break;
      case 'betaTesterPrivateCommunity':
        type = CSVConstants.Types.BetaTester;
        break;
      case 'degreePrivateCommunity':
        type = CSVConstants.Types.Degree;
        break;
      default:
        type = CSVConstants.Types.Generic;
    }

    return type;
  }

  isGenericGroup() {
    return this.typeName === 'genericPrivateCommunity';
  }

  isBlackboardGroup() {
    return this.typeName === 'blackboardPrivateCommunity';
  }

  toObject() {
    return {
      id: this.id,
      typeName: this.typeName,
      definition: this.definition,
    };
  }

  toJSON() {
    return {
      ...this.toObject(),
      scope: this.scope.toJSON(),
    };
  }

  clone() {
    return new Group(cloneDeep(this));
  }
}

export default Group;
