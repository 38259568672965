/** @jsx jsx */
import { css, jsx } from '@emotion/react';

type Props = {
  text?: string | JSX.Element;
  shouldUseBareComponent?: boolean;
};

export const style = css({
  '.rc-TagBadge, &.rc-TagBadge': {
    span: {
      fontSize: 12,
      float: 'right',
      fontWeight: 600,
      fontFamily: '"Source Sans Pro",Arial,sans-serif',
      width: 'fit-content',
      textDecoration: 'none',
      color: 'black',
      backgroundColor: '#FFE057',
      borderRadius: '10px',
      padding: '0px 15px',
      verticalAlign: 'middle',
    },
  },
});

export default function TagBadge({ text, shouldUseBareComponent }: Props) {
  return (
    <div className="rc-TagBadge" {...(shouldUseBareComponent ? {} : { css: style })}>
      <span>{text}</span>
    </div>
  );
}
