import type React from 'react';

import { FormLabel as MuiFormLabel } from '@material-ui/core';
import { useFormControl as useMuiFormControl } from '@material-ui/core/FormControl';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import type { OverrideProps } from '@coursera/cds-common';

import { useFormControlContext } from '@core/forms/FormControl/FormControlContext';
import i18nMessages from '@core/forms/i18n';
import type { RequireOneOf } from '@core/types/utils/RequireOneOf';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import getFormLabelCss, { classes } from './getFormLabelCss';

export type InputLabelProps = {
  /**
   The label content for the input component and will be read out by screenreaders.
   * For a11y, this is required for all input components, even when using renderLabel.
   */
  label: string;

  /**
   * Optional custom renderer for the label. Ensure that the custom label has an id,
   * and that you pass an `aria-labelledby` to the input component with that id.
   *
   * Also ensure the `labelElement` from the function argument is used somewhere in the custom label.
   * This component comes baked in with some a11y best practices. We cannot enforce usage of
   * `labelElement` within the custom component code, but we highly recommend it be used.
   *
   * @param `labelElement` The label element created internally is passed back and should be added somewhere inside the custom label component to maintain a11y.
   */
  renderLabel?: (labelElement: React.ReactElement) => React.ReactElement;
};

export type OptionalInputLabelProps = RequireOneOf<{
  /**
   * The label can be omitted for cases where a visible label isn't required.
   * If it isn't present, an aria-label or aria-labelledby attribute is required to provide context for screen readers.
   */
  label: string;
  /**
   * Specifies an accessible label for the input when a visible label is not provided.
   * An aria-label or aria-labelledby attribute is required if the label is omitted.
   */
  'aria-label': string;

  /**
   * References the ID of an element that serves as an accessible label for the input when a visible label is not provided.
   * An aria-label or aria-labelledby attribute is required if the label is omitted.
   *
   * Recommended to use in conjunction with `renderLabel` for a11y. Pass the same id used for the custom label.
   */
  'aria-labelledby': string;
}> &
  Pick<InputLabelProps, 'renderLabel'>;

export type RequireIndicatorProps = {
  /**
   * Defines in what form the necessity indicator should be shown
   * @default 'none'
   *
   * 'none' - no indicator used only form backwards compatibility
   * 'text' - shows (required) or (optional) text
   * 'icon' - shows * for required
   */
  necessityIndicator?: 'text' | 'icon' | 'none';
};

export type BaseProps = {
  /**
   * Determines if label should render the required indicator
   */
  required?: boolean;

  /**
   * Allows to add (required) to the label so that custom fields could also be announced as required.
   * NOTE: If field is optional this prop has no affect
   * Example: SelectField
   */
  announceRequiredIndicator?: boolean;

  /**
   * Defines a classes for root element
   */
  className?: string;

  /**
   * For internal use only
   * @ignore
   */
  renderLabel?: InputLabelProps['renderLabel'];
} & RequireIndicatorProps;

export interface FormLabelMapType<D extends React.ElementType = 'label'> {
  props: BaseProps;
  defaultComponent: D;
}

export type FormLabelProps<
  D extends React.ElementType = FormLabelMapType['defaultComponent']
> = OverrideProps<FormLabelMapType<D>, D> & {
  component?: React.ElementType;
};

/**
 * Main Input label component
 * @param props
 */
const FormLabel = (
  props: FormLabelProps
): React.ReactElement<FormLabelProps> => {
  const {
    children,
    className,
    required,
    necessityIndicator = 'none',
    announceRequiredIndicator,
    renderLabel,
    ...rest
  } = props;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const { indicatorLabel, onDark, onLight, ...muiClasses } = classes;

  const muiFormControl = useMuiFormControl();
  const formControlContext = useFormControlContext({
    id: props.htmlFor,
    labelId: props.id,
  });

  const isRequired = muiFormControl?.required || required;

  const showNecessityIndicator =
    necessityIndicator && necessityIndicator !== 'none';

  const labelElement = (
    <MuiFormLabel
      className={clsx(
        {
          [onDark]: formControlContext.invert,
          [onLight]: !formControlContext.invert,
        },
        className
      )}
      classes={muiClasses}
      css={getFormLabelCss}
      htmlFor={formControlContext.id}
      id={formControlContext.labelId}
      required={required}
      {...rest}
    >
      {children}

      {!isRequired && showNecessityIndicator && (
        <>
          {' '}
          <Typography
            aria-hidden="true"
            className={indicatorLabel}
            color="supportText"
            component="span"
            variant="subtitleMedium"
          >
            {`(${stringFormatter.format('optional')})`}
          </Typography>
        </>
      )}

      {isRequired && showNecessityIndicator && (
        <>
          {' '}
          <Typography
            aria-hidden="true"
            className={indicatorLabel}
            color="error"
            component="span"
            variant="subtitleMedium"
          >
            {necessityIndicator === 'text' &&
              `(${stringFormatter.format('required')})`}
            {necessityIndicator === 'icon' && '*'}
          </Typography>
        </>
      )}

      {announceRequiredIndicator && isRequired && (
        <VisuallyHidden>{`${stringFormatter.format(
          'required'
        )}`}</VisuallyHidden>
      )}
    </MuiFormLabel>
  );

  return renderLabel ? renderLabel(labelElement) : labelElement;
};

export default FormLabel;
