/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import classNames from 'classnames';
import { compose, setDisplayName } from 'recompose';

import Imgix from 'js/components/Imgix';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import { Typography } from '@coursera/cds-core';

import BrowseCarousel from 'bundles/browse/components/BrowseCarousel';
import type { Domain } from 'bundles/browse/components/Domain/types/Domain';
import type { Subdomain } from 'bundles/browse/components/Domain/types/SubDomain';
import { DomainGetAllQuery } from 'bundles/browse/components/Queries';
import { domainBackgroundScoreMap } from 'bundles/browse/constants';
import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/browse';

// note: to increase performance, we decided to move all of the CSS injection to the page component
const commonStyles = css`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 28px 0 48px 0;
  height: auto;

  .explore-topics-skills-title {
    margin-bottom: 20px;
  }

  .topic-skills-wrapper {
    .promoted-topic-column,
    .topic-column {
      padding-right: 10px;
      padding-left: 10px;
      width: 340px;
    }

    .topic-column {
      .explore-domains-card:first-of-type {
        margin-bottom: 20px;
      }
    }

    .explore-domains-card {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      background: black;

      &:focus {
        outline-offset: 0;
        outline: 5px solid #9ecaed;
        outline: 5px auto -webkit-focus-ring-color;
      }

      .domain-card-name {
        display: block;
        font-family: inherit;
        position: absolute;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: white;
        margin: 0;
        bottom: 20px;
        left: 20px;
      }

      &.isFullHeight .domain-card-name {
        top: 50px;
        left: 39px;
        bottom: initial;
      }

      .topic-image {
        opacity: 0.58;
      }

      &:hover img {
        opacity: 0.5;
      }
    }
  }
`;

export const style = css`
  .rc-TopicAndSkills {
    max-width: 1200px;
    ${commonStyles}
  }

  .rc-TopicAndSkillsCDS {
    ${commonStyles}
    @media (min-width: 992px) and (max-width: 1024px) {
      width: 90%;
    }
  }
`;

const carouselResponsiveProperty = [
  { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
  { breakpoint: 10000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
];

const carouselResponsivePropertyCDS = [
  { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
  { breakpoint: 10000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
];

const TOPIC_COLUMN_HEIGHT = 340;
const TOPIC_HALF_COLUMN_HEIGHT = 160;

type PropsFromCaller = {
  /**
   * A callback function for generating the domain page url.
   */
  onGetDomainPageUrl?: (domain: Domain) => string;
  /**
   * Custom domain card click handler
   */
  onClick?: (url: string, domain: Domain) => void;
  /**
   * Do not render the title for this component. Being used for content-curation
   */
  omitTitle?: boolean;
};

type PropsFromGraphQL = {
  domains: Array<Domain>;
  loading?: boolean;
};

type PropsFromRouter = {
  router: InjectedRouter;
};

type PropsToComponent = PropsFromCaller & PropsFromRouter & PropsFromGraphQL;

export class TopicAndSkills extends React.Component<PropsToComponent> {
  handleClick = (domain: Domain) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { onClick, router } = this.props;
    const url = this.getDomainPageUrl(domain);

    event.preventDefault();

    if (onClick) {
      onClick(url, domain);
    } else {
      router.push(url);
    }
  };

  getDomainPageUrl = (domain: Domain) => {
    const { onGetDomainPageUrl } = this.props;
    const domainSlug = domain.slug || domain.id;

    if (onGetDomainPageUrl) {
      return onGetDomainPageUrl(domain);
    } else {
      return `/browse/${domainSlug}`;
    }
  };

  getLinkHeight = (isFullHeight?: boolean) => {
    if (isFullHeight) {
      return TOPIC_COLUMN_HEIGHT;
    } else {
      return TOPIC_HALF_COLUMN_HEIGHT;
    }
  };

  renderDomainCard(domain: Domain, isFullHeight?: boolean) {
    const fullHeight = isFullHeight;
    if (!domain) {
      return null;
    }

    const imageUrl = domainBackgroundScoreMap[domain.id]?.url;
    const domainPageUrl = this.getDomainPageUrl(domain);

    return (
      <TrackedA
        style={{
          height: this.getLinkHeight(fullHeight),
        }}
        href={domainPageUrl}
        data={{ domain: domain.id }}
        trackingName="explore_domains_card"
        className={classNames('explore-domains-card nostyle', { isFullHeight })}
        onMouseDown={(event) => event.preventDefault()}
        onClick={this.handleClick(domain)}
        aria-label={domain.name}
        data-e2e="explore-domains-card"
      >
        <Imgix
          className="topic-image"
          src={imageUrl}
          alt={domain.name}
          imgParams={{ fit: 'crop', auto: 'compress' }}
          maxHeight={this.getLinkHeight(fullHeight)}
        />

        <span className="domain-card-name">
          <FormattedMessage message={_t('{variable}')} variable={domain.name} />
        </span>
      </TrackedA>
    );
  }

  renderDomains() {
    const { domains } = this.props;
    const sortedDomains = [...domains].sort((a, b) => {
      return domainBackgroundScoreMap[b.id]?.score - domainBackgroundScoreMap[a.id]?.score;
    });

    // first column is promoted domain which take the full height
    const otherDomainCards = [
      <div className="promoted-topic-column" key="promoted-column">
        {this.renderDomainCard(sortedDomains[0], true)}
      </div>,
    ];

    // the rest of the columns are shared by two domains
    // this means stricly a columns must have 2 domains
    // and we don't show if we have 1 extra domain
    for (let idx = 1; idx < sortedDomains.length - 1; idx += 2) {
      otherDomainCards.push(
        <div className="topic-column" key={`topic-column-${idx}`}>
          {this.renderDomainCard(sortedDomains[idx])}
          {sortedDomains[idx + 1] && this.renderDomainCard(sortedDomains[idx + 1])}
        </div>
      );
    }

    return otherDomainCards;
  }

  render() {
    const { omitTitle, domains } = this.props;
    const useCdsGrid = GrowthDiscoveryExperiments.get('showEvolveCollectionBrowse');

    if (domains.length < 1) {
      return null; // just don't render if api fails
    }

    return (
      <section
        className={useCdsGrid ? 'rc-TopicAndSkillsCDS' : 'rc-TopicAndSkills'}
        data-e2e="topics-and-skills-section"
        aria-label={_t('Explore Topics and Skills Carousel')}
      >
        {!omitTitle && (
          <Typography className="explore-topics-skills-title" variant="h1" component="h2">
            {_t('Explore Topics and Skills')}
          </Typography>
        )}
        <BrowseCarousel
          style={{ height: TOPIC_COLUMN_HEIGHT }}
          loadOnlyOnClient={false}
          responsive={useCdsGrid ? carouselResponsivePropertyCDS : carouselResponsiveProperty}
          slidesToShow={3}
          slidesToScroll={3}
          rootClassName="topic-skills-wrapper"
          trackingData={{ collectionName: 'Topics and Skills' }}
          enableRtl
        >
          {this.renderDomains()}
        </BrowseCarousel>
      </section>
    );
  }
}

type graphqlProps = {
  data?: {
    loading?: boolean;
    DomainsV1Resource?: { domains?: { elements?: Array<Subdomain> } };
  };
};

const DomainGraphqlOptions = {
  options: () => ({
    ...apolloFetchPolicy({ cacheForLoggedOutOnly: false }),
    errorPolicy: 'all' as const,
  }),
  props: ({ data }: graphqlProps) => ({
    domains: (!data?.loading && data?.DomainsV1Resource?.domains?.elements) || [],
    loading: data?.loading,
  }),
};

export default compose<PropsToComponent, PropsFromCaller>(
  connectToRouter<PropsFromRouter, {}>((router) => ({ router })),
  graphql(DomainGetAllQuery, DomainGraphqlOptions),
  setDisplayName('TopicAndSkillsHOC')
)(TopicAndSkills);
