import * as React from 'react';

import Notification from 'bundles/notification-center/components/Notification';
import type {
  AccountProfileCompletionNudgeNotification as AccountProfileCompletionNudgeNotificationType,
  Notification as NotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: AccountProfileCompletionNudgeNotificationType;
  onClick: (notification: NotificationType) => void;
};

function AccountProfileCompletionNudgeNotification({ notification, onClick }: Props) {
  return (
    <Notification onClick={onClick} href="/account-profile" notification={notification}>
      <p>
        {_t(
          `Add your location and career preferences to your profile now so recruiters know what role(s) you're interested in as you keep building your skills.`
        )}
      </p>
      <p>{_t('Take me to my profile.')}</p>
    </Notification>
  );
}

export default AccountProfileCompletionNudgeNotification;
