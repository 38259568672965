/* eslint-disable camelcase */

/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import user from 'js/lib/user';

import {
  Button,
  InlineNotification,
  Link,
  SelectField,
  SelectOption,
  TextField,
  Typography2,
  useTheme,
} from '@coursera/cds-core';
import { ExternalLinkIcon, MessageIcon } from '@coursera/cds-icons';

import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/about';

import { initializeSFChat } from '../../utils/initializeSFChat';
import HelpBoxCard from './HelpBoxCard';

type PreChatFormProps = {
  onClose: () => void;
  isAgentAvailable: boolean;
  isAdminChat?: boolean;
  isEducatorChat?: boolean;
};

type formDataState = {
  firstName: string;
  lastName: string;
  email: string;
  preferredSupportLanguage: string;
  agentSkill: string;
  reasonForContacting: string;
  description: string;
};

type HelpPreChatText =
  | 'ERROR'
  | 'CONTACT_CUSTOMER_SUPPORT'
  | 'SUBMIT_A_TICKET'
  | 'REASON_FOR_CONTACTING_US'
  | 'SELECT'
  | 'DESCRIPTION_OF_ISSUE'
  | 'SHARE_DETAILS'
  | 'INPUT_LENGTH'
  | 'CONNECT_TO_CHAT'
  | 'OTHER_REASON_FOR_CONTACTING'
  | 'SUBMIT_A_CASE'
  | 'AGENT_NOT_AVAILABLE'
  | 'PREFERRED_LANGUAGE';

type LinkToHelpCenterProps = { message: HelpPreChatText; url: string; trackingName: string };

const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      margin-top: ${theme.spacing(16)};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(32)};
    `,
    characterCount: css`
      margin-top: ${theme.spacing(8)};
      display: flex;
      justify-content: end;
    `,
    linkWithIcon: css`
      display: flex;
      flex-direction: row;
    `,
  };
};

// The assumption that everything after the first space is a user's last name is consistent with the rest of the codebase
const getSplitName = (fullName: string) => {
  const [userFirstName, ...userLastNames] = fullName.trim().split(' ');
  return { userFirstName, userLastName: userLastNames.join(' ') };
};

const getLanguage = (userLocale: string | undefined) => {
  // Learner and educator chat are available in English and Spanish, any other language defaults to English
  return userLocale === 'es-LA' ? 'Spanish' : 'English';
};

const getPreferredLanguageOptions = () => [_t('English'), _t('Spanish')];

const getReasonForContactOptions = () => {
  return {
    // learner: [
    //   _t('Courses & certificates'),
    //   _t('Payments & subscriptions'),
    //   _t('Coursera policies'),
    //   _t('Platform Issues & Access'),
    // ],
    admin: [
      _t('Platform Access Questions'),
      _t('Learner Experience Questions'),
      _t('Dashboard / Reports Questions'),
      _t('Data Download / Update Requests'),
      _t('Other'),
    ],
    educator: [
      _t('Coursera Onboarding'),
      _t('Authoring Guidance'),
      _t('Roles & Permissions'),
      _t('Quizzes, SGAs, & Peer Reviews'),
      _t('Data & Dashboards'),
      _t('Other'),
    ],
  };
};

const getText = (text: HelpPreChatText, descriptionLength?: number) => {
  switch (text) {
    case 'ERROR':
      return _t('There was an error opening the chat');
    case 'CONTACT_CUSTOMER_SUPPORT':
      return _t('Please contact Customer Support instead.');
    case 'SUBMIT_A_TICKET':
      return _t('Submit a ticket');
    case 'REASON_FOR_CONTACTING_US':
      return _t('Reason for contacting us');
    case 'SELECT':
      return _t('Select');
    case 'DESCRIPTION_OF_ISSUE':
      return _t('Description of issue');
    case 'SHARE_DETAILS':
      return _t('Share a few details so our specialist can assist you faster');
    case 'INPUT_LENGTH':
      return _t('#{length} of 255 characters', { length: descriptionLength });
    case 'CONNECT_TO_CHAT':
      return _t('Connect to chat');
    case 'OTHER_REASON_FOR_CONTACTING':
      return _t("For help with other issues, submit a case and we'll email you back.");
    case 'SUBMIT_A_CASE':
      return _t('Submit a case');
    case 'AGENT_NOT_AVAILABLE':
      return _t('Please fill out a form for assistance outside of chat operating hours.');
    case 'PREFERRED_LANGUAGE':
      return _t('Preferred language');
    default:
      return '';
  }
};

const LinkToHelpCenter = ({ message, url, trackingName }: LinkToHelpCenterProps) => {
  return (
    <div css={useStyles().container}>
      <HelpBoxCard>
        <Typography2 component="p" variant="bodyPrimary">
          {getText(message)}
        </Typography2>
        <Link
          component={TrackedA}
          trackingName={trackingName}
          css={useStyles().linkWithIcon}
          href={url}
          target="_blank"
          variant="quiet"
          typographyVariant="bodyPrimary"
          icon={<ExternalLinkIcon />}
          iconPosition="after"
        >
          {getText('SUBMIT_A_CASE')}
        </Link>
      </HelpBoxCard>
    </div>
  );
};

const ChatErrorNotification = ({ url }: { url: string }) => {
  return (
    <InlineNotification severity="error" title={getText('ERROR')}>
      <>
        <Typography2 component="p">{getText('CONTACT_CUSTOMER_SUPPORT')}</Typography2>
        <Link href={url} target="_blank" variant="quiet" typographyVariant="bodyPrimary">
          {getText('SUBMIT_A_TICKET')}
        </Link>
      </>
    </InlineNotification>
  );
};

const PreChatForm: React.FC<PreChatFormProps> = ({ onClose, isAgentAvailable, isAdminChat, isEducatorChat }) => {
  const [formDataState, setFormDataState] = useState<formDataState>({
    firstName: '',
    lastName: '',
    email: '',
    preferredSupportLanguage: '',
    agentSkill: '',
    reasonForContacting: '',
    description: '',
  });
  const [reasonsForContact, setReasonsForContact] = useState<string[]>([]);
  const [isChatError, setIsChatError] = useState(false);
  const { firstName, lastName, email, preferredSupportLanguage, agentSkill, reasonForContacting, description } =
    formDataState;
  const styles = useStyles();
  const helpCenterUrl = isAdminChat
    ? 'https://business.coursera.help/hc/en/requests/new'
    : 'https://partner.coursera.help/hc/en/requests/new';

  useEffect(() => {
    const { admin, educator } = getReasonForContactOptions();
    setReasonsForContact(isAdminChat ? admin : educator);

    const { full_name, email_address, locale } = user.get();
    const { userFirstName, userLastName } = getSplitName(full_name);
    const userLanguage = getLanguage(locale);

    setFormDataState((prevState) => ({
      ...prevState,
      firstName: userFirstName,
      lastName: userLastName,
      email: email_address,
      // Admin chat is only available in English. Educator chat is available in English and Spanish.
      preferredSupportLanguage: isAdminChat ? 'English' : userLanguage,
      agentSkill: isAdminChat ? 'Admin_English' : `Educator_${userLanguage}`,
    }));
  }, [isAdminChat]);

  const handleReasonUpdate = (e: React.ChangeEvent<{ value: unknown }>) => {
    setFormDataState((prevState) => ({
      ...prevState,
      reasonForContacting: e.target.value as string,
    }));
  };

  const handleDescriptionUpdate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setFormDataState((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  // For educator chat, users can select between English and Spanish as their preferred language
  const handlePreferredLanguageUpdate = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setFormDataState((prevState) => ({
      ...prevState,
      preferredSupportLanguage: value,
      agentSkill: `Educator_${value}`,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    const formData = {
      firstName,
      lastName,
      email,
      preferredSupportLanguage,
      agentSkill,
      reasonForContacting,
      description,
    };

    try {
      const pageType = isAdminChat ? 'admin' : 'educator';
      initializeSFChat(pageType, formData);
      onClose();
    } catch (err) {
      setIsChatError(true);
    }
  };

  return (
    // Hours of operation for admin and educator chat are 8am-5pm PST. If no agent is available for chat, we display link to the Educator Resource Center or Admin Help Center where the user can submit a case
    <>
      {/* If an agent is available for chat, display the pre-chat form */}
      {isAgentAvailable && (
        <form onSubmit={handleSubmit} css={styles.container}>
          <SelectField
            label={getText('REASON_FOR_CONTACTING_US')}
            placeholder={getText('SELECT')}
            fullWidth
            autoFocus
            onChange={handleReasonUpdate}
            value={reasonForContacting}
          >
            {reasonsForContact.map((reason) => {
              return (
                <SelectOption value={reason} key={reason}>
                  {reason}
                </SelectOption>
              );
            })}
          </SelectField>

          {/* If the user selects "Other" reason for contacting, we display a link to the Educator Resource Center or Admin Help Center where they can submit a case. "Other" issues are better addressed async outside of live chat. */}
          {reasonForContacting === 'Other' && (
            <LinkToHelpCenter
              message="OTHER_REASON_FOR_CONTACTING"
              url={helpCenterUrl}
              trackingName="help_link_other_reason"
            />
          )}
          {/* For all other reasons for contact, the user submits a pre-chat form to initiate chat */}
          {reasonForContacting !== 'Other' && (
            <>
              <div>
                <TextField
                  label={getText('DESCRIPTION_OF_ISSUE')}
                  placeholder={getText('SHARE_DETAILS')}
                  multiline
                  fullWidth
                  maxLength={255}
                  onChange={handleDescriptionUpdate}
                  value={description}
                />
                <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.characterCount}>
                  {getText('INPUT_LENGTH', description.length)}
                </Typography2>
              </div>
              {/* User can select their preferred language in educator chat only */}
              {isEducatorChat && (
                <SelectField
                  label={getText('PREFERRED_LANGUAGE')}
                  placeholder={preferredSupportLanguage}
                  fullWidth
                  onChange={handlePreferredLanguageUpdate}
                  value={preferredSupportLanguage}
                >
                  {getPreferredLanguageOptions().map((language) => {
                    return (
                      <SelectOption value={language} key={language}>
                        {language}
                      </SelectOption>
                    );
                  })}
                </SelectField>
              )}
              <Button
                type="submit"
                variant="primary"
                icon={<MessageIcon size="medium" />}
                iconPosition="before"
                fullWidth
              >
                {getText('CONNECT_TO_CHAT')}
              </Button>
              {isChatError && <ChatErrorNotification url={helpCenterUrl} />}
            </>
          )}
        </form>
      )}
      {/* If an agent isn't available, display link to Educator Resource Center or Admin Help Center */}
      {!isAgentAvailable && (
        <LinkToHelpCenter message="AGENT_NOT_AVAILABLE" url={helpCenterUrl} trackingName="help_link_no_agent" />
      )}
    </>
  );
};

export default PreChatForm;
