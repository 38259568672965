import type React from 'react';
import { graphql } from 'react-apollo';

import { compose } from 'recompose';

import { getSavingProductType } from 'bundles/browse/components/utils/BookmarkUtils';
import { FindSavedProductsByListOfProductsQuery } from 'bundles/browse/components/utils/RestQueries';
import type {
  PropsForSavedDataLookup,
  WithSavedDataFromProductsReturnType,
  withSavedProductsProps,
} from 'bundles/browse/types/withSavedDataFromProducts';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';

const withSavedProducts = <T extends {}>(
  BaseComponent: React.ComponentType<T & withSavedProductsProps & PropsFromCourseraPlusProductOwnerships>
): React.ComponentType<T> =>
  compose<T & withSavedProductsProps, T>(
    withCourseraPlusProductOwnerships(),
    graphql<PropsForSavedDataLookup & PropsFromCourseraPlusProductOwnerships, any, any, withSavedProductsProps>(
      FindSavedProductsByListOfProductsQuery,
      {
        skip: ({ products, ownsCourseraPlus }) => (products || []).length === 0 || !ownsCourseraPlus,
        options: ({ products }) => {
          const formattedProductsForLookup = (products || []).map((product) => {
            const { id, offeringType } = product;

            return {
              product_id: id,
              product_type: getSavingProductType(offeringType),
            };
          });

          return {
            variables: {
              input: {
                product: [...formattedProductsForLookup],
              },
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
          };
        },
        // @ts-expect-error Need to figure this out before landing
        props: ({ data }: WithSavedDataFromProductsReturnType) => {
          const { loading, FindSavedProductsByListOfProductsResponse } = data || {};
          const savedProducts = FindSavedProductsByListOfProductsResponse?.savedProduct || [];

          return {
            savedProductData: savedProducts,
            loadingSavedProducts: loading,
          };
        },
      }
    )
  )(BaseComponent);

export default withSavedProducts;
