/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import AcademicIntegrity from 'bundles/epic/data/defaults/AcademicIntegrity.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  defaultPlagiarismDetectionEnabled: boolean;
  enableGibberishDeterrence: boolean;
  enablePlagiarismDetection: boolean;
  idvDynamicTemplateEnabled: boolean;
  cdsComponentsEnabled: boolean;
  enableIntegrityPortal: boolean;
  enableC4CIntegrityPortal: boolean;
  enableGibberishDeterrenceAuthoring: boolean;
  enablePlagiarismDetectionAuthoring: boolean;
  preventCopyingQuestionPrompt: boolean;
  enableHonorlock: boolean;
  enableVivaExam: any;
  enablePeerReviewPreviewFlowDeprecation: boolean;
  enableIdVerificationFingerprint: boolean;
  enableFileUploadPlagiarismDetection: boolean;
  enableFileUploadPlagiarismAuthoring: boolean;
  enableLockedBrowser: boolean;
  idVerificationFingerprintExclusionList: any;
  enableAutoProctor: boolean;
  enableProctoringResultsAppEnabled: boolean;
  enableVivaExamGraderView: boolean;
};

const NAMESPACE = 'AcademicIntegrity';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([AcademicIntegrity as $TSFixMe]);

const AcademicIntegrityEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default AcademicIntegrityEpicClient;
