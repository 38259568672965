import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import LearnerIncentiveNotification from 'bundles/learner-incentives/components/LearnerIncentiveNotification';
import DegreeApplicationDeadlineNotification from 'bundles/notification-center/components/DegreeApplicationDeadlineNotification';
import NotificationHeader from 'bundles/notification-center/components/NotificationHeader';
import NotificationListEmpty from 'bundles/notification-center/components/NotificationListEmpty';
import type {
  NotificationCenterMessagesQueryResponse,
  NotificationCenterMessagesQueryVariables,
} from 'bundles/notification-center/components/data/NotificationCenterMessagesQuery';
import AccountProfileCompletionNudgeNotification from 'bundles/notification-center/components/notifications/AccountProfileCompletionNudgeNotification';
import AssignmentHasBeenPeerReviewedMessageNotification from 'bundles/notification-center/components/notifications/AssignmentHasBeenPeerReviewedMessageNotification';
import CalendarSyncNotification from 'bundles/notification-center/components/notifications/CalendarSyncNotification';
import CapstoneCompletionNotification from 'bundles/notification-center/components/notifications/CapstoneCompletionNotification';
import DeadlineOverdueNotification from 'bundles/notification-center/components/notifications/DeadlineOverdueNotification';
import DeadlineReminderNotification from 'bundles/notification-center/components/notifications/DeadlineReminderNotification';
import DegreePathwaysCompletionNudgeNotification from 'bundles/notification-center/components/notifications/DegreePathwaysCompletionNudgeNotification';
import DiscussionForumNewAnswerForFollowerNotification from 'bundles/notification-center/components/notifications/DiscussionForumNewAnswerForFollowerNotification';
import DiscussionForumNewAnswerForQuestionCreatorNotification from 'bundles/notification-center/components/notifications/DiscussionForumNewAnswerForQuestionCreatorNotification';
import EnterpriseContentAssignmentNotification from 'bundles/notification-center/components/notifications/EnterpriseContentAssignmentNotification';
import PeerReviewGradeReadyMessageNotification from 'bundles/notification-center/components/notifications/PeerReviewGradeReadyMessageNotification';
import ProductPromotionNotification from 'bundles/notification-center/components/notifications/ProductPromotionNotification';
import SetWeeklyGoalReminderNotification from 'bundles/notification-center/components/notifications/SetWeeklyGoalReminderNotification';
import UserVerificationNotification from 'bundles/notification-center/components/notifications/UserVerificationNotification';
import VerifiedCertificateCongratsNotification from 'bundles/notification-center/components/notifications/VerifiedCertificateCongratsNotification';
import type { Notification as NotificationType } from 'bundles/notification-center/types';

import 'css!./__styles__/NotificationListView';

type Props = {
  unreadCount: number;
  notifications: Array<NotificationType>;
  refetchNotifications: (
    variables?: NotificationCenterMessagesQueryVariables
  ) => Promise<ApolloQueryResult<NotificationCenterMessagesQueryResponse>>;
  markAllNotificationsAsRead: () => void;
  markNotificationAsRead: (id: string) => void;
};

// TODO: Improve a11y. see https://medium.com/@im_rahul/focus-trapping-looping-b3ee658e5177
class NotificationListView extends React.Component<Props> {
  componentDidMount() {
    const { refetchNotifications } = this.props;
    // refetch notifications on mount to catch any updates in the current user session
    refetchNotifications();
  }

  handleMarkAllAsRead = () => {
    const { markAllNotificationsAsRead } = this.props;
    markAllNotificationsAsRead();
  };

  handleMarkAsRead = (notification: NotificationType) => {
    const { markNotificationAsRead } = this.props;
    markNotificationAsRead(notification.id);
  };

  render() {
    const { unreadCount, notifications } = this.props;

    const NotificationsComponents = notifications
      .map((notification) => {
        let component;

        switch (notification.messageType) {
          case 'LearnerGoalSettingSetGoalMessage': // no epic check is needed since BE does the epic check
            component = (
              <SetWeeklyGoalReminderNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;

          case 'DeadlineOverdueMessage':
            component = (
              <DeadlineOverdueNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'DeadlineReminderMessage':
            component = (
              <DeadlineReminderNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'UserVerificationMessage':
            component = (
              <UserVerificationNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'CalendarSyncMessage':
            component = (
              <CalendarSyncNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'PartnerIncentiveMessage':
            component = (
              <LearnerIncentiveNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'VerifiedCertificateCongratsMessage':
            component = (
              <VerifiedCertificateCongratsNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );

            break;
          case 'AssignmentHasBeenPeerReviewedMessage':
            component = (
              <AssignmentHasBeenPeerReviewedMessageNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );

            break;
          case 'PeerReviewGradeReadyMessage':
            component = (
              <PeerReviewGradeReadyMessageNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );

            break;
          case 'DiscussionForumNewAnswerForFollowerMessage':
            component = (
              <DiscussionForumNewAnswerForFollowerNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );

            break;
          case 'DiscussionForumNewAnswerForQuestionCreatorMessage':
            component = (
              <DiscussionForumNewAnswerForQuestionCreatorNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );

            break;

          case 'CapstoneCompletionNotificationMessage':
            component = (
              <CapstoneCompletionNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;

          case 'AccountProfileCompletionNudgeMessage':
            component = (
              <AccountProfileCompletionNudgeNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'EnterpriseContentAssignmentMessage':
            component = (
              <EnterpriseContentAssignmentNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'ProductPromotionMessage':
            component = (
              <ProductPromotionNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'DegreeDeadlineOneWeekAheadMessage':
          case 'DegreeDeadlineFourWeeksAheadMessage':
            component = (
              <DegreeApplicationDeadlineNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          case 'DegreePathwaysCompletionNudgeMessage':
            component = (
              <DegreePathwaysCompletionNudgeNotification
                key={notification.id}
                notification={notification}
                onClick={this.handleMarkAsRead}
              />
            );
            break;
          default:
            component = null;
            break;
        }

        return component;
      })
      .filter((v) => !!v);

    return (
      <div className="rc-NotificationListView">
        <span className="notification-list-view-triangle" />

        <div className="notification-list-view-content">
          <NotificationHeader unreadCount={unreadCount} onMarkAllAsRead={this.handleMarkAllAsRead} />

          {NotificationsComponents.length === 0 && <NotificationListEmpty />}

          <div className="notification-list-view-notifications">{NotificationsComponents}</div>
        </div>
      </div>
    );
  }
}

export default NotificationListView;
