/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { ButtonBase, Typography2 } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-course-coach/assets/CoachIcon';

import _t from 'i18n!nls/learning-assistant-chat-ai';

export type Props = {
  'aria-label'?: string;
  onClick: () => void;
} & React.ComponentPropsWithRef<'button'>;

const styles = {
  container: css`
    --button-size: 48px;

    /* CDS overrides */
    --cds-color-neutral-primary: #0f1114;
    --cds-color-interactive-stroke-primary-focus: #002761;
    --cds-color-interactive-stroke-primary-focus-invert: #e3eeff;

    height: var(--button-size);
    width: 144px;
    position: fixed;
    bottom: 24px;
    right: 24px;
    border-radius: 100px;
    background-color: var(--cds-color-neutral-background-primary);
    box-shadow: 0 1px 2px 0 rgba(0 0 0 /30%), 0 1px 3px 1px rgba(0 0 0 /15%);
    gap: var(--cds-spacing-100);
    justify-content: flex-start;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: #f4f7fa;

      .button-label {
        color: #1e72eb;
      }
    }

    &:focus {
      &::after {
        border-radius: 100px;
      }
    }
  `,
  iconContainer: css`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    box-shadow: 0 1px 3px 1px rgba(0 0 0 /15%);
    background-color: var(--cds-color-neutral-background-primary);
  `,
};

/**
 * Rebrand OnDemandButton
 * Trigger button for users to open up Coach chat on-demand.
 */
export const OnDemandButton = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const { onClick, 'aria-label': ariaLabel, ...rest } = props;
  const [isCoachWinking, setIsCoachWinking] = useState(false);

  const handleMouseEnter = () => {
    setIsCoachWinking(true);
  };

  const handleMouseLeave = () => {
    setIsCoachWinking(false);
  };

  const handleFocus = () => {
    setIsCoachWinking(true);
  };

  const handleBlur = () => {
    setIsCoachWinking(false);
  };

  const handleOnClick = () => {
    onClick();
    setIsCoachWinking(false);
  };

  let iconToRender;
  if (isCoachWinking) {
    iconToRender = <CoachIcon state="wink" />;
  } else {
    iconToRender = <CoachIcon />;
  }

  return (
    <ButtonBase
      ref={ref}
      aria-label={ariaLabel || _t('Chat with Coursera Coach, your AI-powered learning assistant')}
      css={styles.container}
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
    >
      <div css={styles.iconContainer}>{iconToRender}</div>
      <Typography2 component="p" variant="actionPrimary" className="button-label">
        {_t('Ask Coach')}
      </Typography2>
    </ButtonBase>
  );
});

export default OnDemandButton;
