import * as React from 'react';
import { memo } from 'react';

import classNames from 'classnames';

import { Box, gradient as CUIGradient } from '@coursera/coursera-ui';

import Card from 'bundles/growth-ui/components/Card';
import CardSection, { CARD_SECTION_TYPE } from 'bundles/growth-ui/components/CardSection';
import CardText, { CARD_TEXT_TYPE } from 'bundles/growth-ui/components/CardText';
import PartnerLogo from 'bundles/growth-ui/components/PartnerLogo';
import TagBadge from 'bundles/growth-ui/components/TagBadge';

import 'css!./__styles__/ProductCard';

export const PRODUCT_CARD_TYPE = {
  COURSE: 'course',
  DEGREE: 'degree',
  S12N: 's12n',
};

export const PRODUCT_CARD_SIZE = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
};

const cardConfigMap: Record<string, { titleMaxLines: number; subtitleMaxLines: number }> = {
  xs: {
    titleMaxLines: 2,
    subtitleMaxLines: 2,
  },
  sm: {
    titleMaxLines: 3,
    subtitleMaxLines: 2,
  },
  md: {
    titleMaxLines: 2,
    subtitleMaxLines: 2,
  },
  lg: {
    titleMaxLines: 3,
    subtitleMaxLines: 3,
  },
};

type Gradient = { deg: number; start: string; end: string };

type Props = {
  gradient?: Gradient;
  badge?: string;
  imageSrc: string;
  logoSrc: string;
  title: string;
  titleLang?: string;
  titleMaxLines?: number;
  subtitle?: string | JSX.Element;
  subtitleMaxLines?: number;
  label: string | JSX.Element;
  tag?: string;
  actionsElement?: JSX.Element;
  htmlAttributes?: Record<string, unknown>;
  isInteractive?: boolean;
  isThemeDark?: boolean;
  onClick?: () => void;
  showGradient: boolean;
  type: string;
  size: string;
  tagClassNames?: string;
  children?: JSX.Element;
  useDivForTitleElement?: boolean;
  dir?: string;
  titleHref?: string;
  shouldUseBareComponent?: boolean;
};

/** @deprecated replaced by the new ProductCardV2, do not use this component at all cost */
export default memo(function ProductCard({
  gradient = CUIGradient.productCardSpecialization,
  badge,
  imageSrc,
  logoSrc,
  title,
  titleLang,
  titleMaxLines,
  subtitle,
  subtitleMaxLines,
  label,
  tag,
  actionsElement,
  htmlAttributes,
  isInteractive,
  isThemeDark,
  onClick,
  showGradient,
  type = PRODUCT_CARD_TYPE.COURSE,
  size = PRODUCT_CARD_SIZE.LG,
  tagClassNames,
  children,
  useDivForTitleElement,
  dir,
  titleHref,
  shouldUseBareComponent,
}: Props) {
  const shouldShowGradient = showGradient === undefined ? type === PRODUCT_CARD_TYPE.S12N : showGradient;
  const configData = cardConfigMap[size];

  const onClickHandler = (event: MouseEvent) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  const onClickProp = onClick ? { onClick: onClickHandler } : {};

  const titleHtmlAttr = titleLang ? { lang: titleLang } : undefined;

  const isRightToLeft = dir === 'rtl';

  return (
    <div className="rc-ProductCard" data-size={size}>
      <Card
        className="productCard-card"
        isInteractive={!!onClick || isInteractive}
        isThemeDark={isThemeDark || shouldShowGradient}
        htmlAttributes={{
          ...onClickProp,
          ...htmlAttributes,
          'data-is-rtl': !!isRightToLeft,
        }}
      >
        <CardSection
          className="productCard-cardImageContainer"
          type={CARD_SECTION_TYPE.IMAGE}
          htmlAttributes={{ 'data-show-gradient': !!shouldShowGradient }}
          shouldUseBareComponent={shouldUseBareComponent}
        >
          <span
            className="productCard-cardImage"
            aria-hidden={true}
            style={{
              backgroundImage: imageSrc ? `url(${imageSrc})` : '',
            }}
          />
        </CardSection>
        {shouldShowGradient && (
          <div
            className="productCard-gradient"
            style={{ backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.start}, ${gradient.end})` }}
          />
        )}
        {logoSrc && (
          <PartnerLogo
            logoSrc={logoSrc}
            className="productCard-PartnerLogo"
            htmlAttributes={{ 'data-is-rtl': !!isRightToLeft }}
            shouldUseBareComponent={shouldUseBareComponent}
          />
        )}
        {tag && (
          <div className={classNames('productCard-tag', tagClassNames)} data-is-rtl={!!isRightToLeft}>
            {tag}
          </div>
        )}
        <div className="productCard-badgeWrapper">
          <TagBadge text={badge} shouldUseBareComponent={shouldUseBareComponent} />
        </div>
        <CardSection
          className="productCard-titleSection"
          htmlAttributes={{ 'data-show-gradient': !!shouldShowGradient }}
          shouldUseBareComponent={shouldUseBareComponent}
        >
          <CardText
            className="productCard-title"
            type={CARD_TEXT_TYPE.TITLE}
            text={title}
            maxLines={titleMaxLines || configData.titleMaxLines}
            htmlAttributes={titleHtmlAttr}
            shouldRenderDiv={useDivForTitleElement}
            href={titleHref}
          />
          <CardText
            className="productCard-subtitle"
            type={CARD_TEXT_TYPE.SUBTITLE}
            text={subtitle}
            maxLines={subtitleMaxLines || configData.subtitleMaxLines}
          />
        </CardSection>
        {children}
        {(label || actionsElement) && (
          <CardSection className="productCard-cardActions" shouldUseBareComponent={shouldUseBareComponent}>
            <Box alignItems="center" justifyContent="between" flexDirection={isRightToLeft ? 'row-reverse' : 'row'}>
              <CardText type={CARD_TEXT_TYPE.LABEL} text={label} />
              {actionsElement}
            </Box>
          </CardSection>
        )}
      </Card>
    </div>
  );
});

type ProductCardPlaceholderProps = {
  hidePartnerLogo?: boolean;
  hideLabel?: boolean;
  size?: string;
  dir?: string;
  shouldUseBareComponent?: boolean;
};

export const ProductCardPlaceholder = memo(
  ({ hidePartnerLogo, hideLabel, size = 'lg', dir, shouldUseBareComponent }: ProductCardPlaceholderProps) => {
    const isRightToLeft = dir === 'rtl';

    return (
      <div className="rc-ProductCard placeholder" data-size={size}>
        <Card>
          <CardSection
            className="productCard-cardImageContainer"
            type={CARD_SECTION_TYPE.IMAGE}
            shouldUseBareComponent={shouldUseBareComponent}
          >
            <span className="productCard-shimmer productCard-cardImage" />
          </CardSection>
          {!hidePartnerLogo && (
            <PartnerLogo
              className="productCard-PartnerLogo"
              htmlAttributes={{ 'data-is-rtl': !!isRightToLeft }}
              shouldUseBareComponent={shouldUseBareComponent}
            />
          )}
          <CardSection className="productCard-titleSection" shouldUseBareComponent={shouldUseBareComponent}>
            <div className="productCard-title productCard-shimmer" />
            <div className="productCard-subtitle productCard-shimmer" />
          </CardSection>
          {!hideLabel && (
            <CardSection className="productCard-cardActions" shouldUseBareComponent={shouldUseBareComponent}>
              <div className="productCard-numberOfCourses productCard-shimmer" />
            </CardSection>
          )}
        </Card>
      </div>
    );
  }
);
