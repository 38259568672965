/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import LearnerProfile from 'bundles/epic/data/defaults/LearnerProfile.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  enableProjectEnhancements: boolean;
  enableAutoPublishedProjects: 'disableAutoPublish' | 'enableAutoPublish';
  useAnonymizedLearnerData: boolean;
  enableLearnerProfileFullStory: boolean;
  enableLearnerProfileEditFullStory: boolean;
  enableOptionalFieldProfile: boolean;
  enableProfileSlugs: boolean;
  enableResumeUpload: boolean;
  enableGlobalCHSOptInFlow: boolean;
  enableConsumerRoleDiscoveryPages: boolean;
  enableConsumerCareerAcademyBanner: boolean;
  enableConsumerAutoEnrollment: boolean;
};

const NAMESPACE = 'LearnerProfile';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([LearnerProfile as $TSFixMe]);

const LearnerProfileEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default LearnerProfileEpicClient;
