import config from 'js/app/config';
import cookie from 'js/lib/cookie';
import type { Location } from 'js/lib/useRouter';

import { PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE } from 'bundles/onboarding-2018/constants';

export const setProfileCompleterCookieForExistingUsers = () =>
  cookie.set(PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE, 'yes-please', { minutes: 5 });

export function getProfileCompleterCookieForExistingUsers() {
  return cookie.get(PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE);
}

export const getAndDeleteProfileCompleterCookieForExistingUsers = () => {
  const enrollmentCookie = cookie.get(PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE);
  if (enrollmentCookie) cookie.remove(PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE);
  return enrollmentCookie;
};

export const checkIfProfileCompleterForExistingUsersApplicable = () => {
  return !!getAndDeleteProfileCompleterCookieForExistingUsers();
};

export const hasAddedInformationToOnboardingModal = (demographics: $TSFixMe) => {
  /*
    NOTE: Used specifically to help with the "Profile Completion for Existing Users"
          experiment. As such, we only care about specific parts of the onboarding
          modal: any boolean status is meaningless without it's conext, so we're ignoring
          those, along with a few other bits of not-present-on-the-modal demographic info.
          See below for the properties that are being ignored.

    employmentStatus, interestedCareers, isCurrentEmployer, isCurrentStudent,
    skillsPossessed, studentStatus
  */
  const { currentIndustry, currentOccupation, currentOccupationLevel, currentOrLatestSchool, employer } =
    demographics || {};
  const currentIndustryIsPopulated =
    currentIndustry?.definition && (!!currentIndustry.definition.name || !!currentIndustry.definition.id);
  const currentOccupationIsPopulated =
    currentOccupation?.definition && (!!currentOccupation.definition.name || !!currentOccupation.definition.id);
  const currentOccupationLevelIsNotDefault = currentOccupationLevel > 0;
  const schoolIsPopulated = currentOrLatestSchool && !!currentOrLatestSchool.name;
  const employerIsPopulated = !!employer;

  return (
    currentIndustryIsPopulated ||
    currentOccupationIsPopulated ||
    currentOccupationLevelIsNotDefault ||
    schoolIsPopulated ||
    employerIsPopulated
  );
};

export const shouldBranchForXdpLogic = ({ appName }: $TSFixMe) => appName && appName.includes('xdp');
