import { capitalize } from 'lodash';

import type {
  ClickAutocompleteSuggestions,
  Partner,
  Product,
  SuggestionSection,
  SuggestionState,
  SuggestionType,
} from '@coursera/event-pulse-types';

import type {
  DiscoveryCollection,
  DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity,
} from 'bundles/browse/components/types/DiscoveryCollections';
import {
  ON_SITE_SEARCH_PATH,
  RECENTLY_VIEWED_LOCAL_STORAGE_NAME,
  RECENT_SEARCHES_LOCAL_STORAGE_NAME,
} from 'bundles/search-common/constants';
import {
  AS_YOU_TYPE_SEARCH_GROUP_ID,
  AS_YOU_TYPE_SEARCH_PREFIX,
  AUTOCOMPLETE_MODES,
  AUTOCOMPLETE_PHOTO_SIZE,
  AUTOCOMPLETE_PRODUCT_TYPE_TO_ICON,
  AUTOCOMPLETE_PRODUCT_TYPE_TO_TRACKING_NAME,
  type AutocompleteMode,
  RECENTLY_VIEWED_PREFIX,
  RECENT_SEARCHES_PREFIX,
  SEE_ALL_TEXT_PREFIX,
  SearchesIcon,
  TRENDING_SEARCHES_GROUP_ID,
  TRENDING_SEARCHES_PREFIX,
  ZERO_STATE_ITEMS_PER_SECTION,
  autocompletePrefixes,
  getAutocompleteProductTypePrettyName,
  getTop10PartnerOptions,
} from 'bundles/search-common/constants/autocomplete';
import type {
  SearchArticleHit,
  SearchHit,
  SearchProductHit,
  SearchResult,
  SearchResults,
  SearchSuggestionHit,
} from 'bundles/search-common/providers/searchTypes';
import type { AutoCompleteHits, Hits, SuggestionHits } from 'bundles/search-common/types/autocomplete';
import {
  getItemsListFromLocalStorageByKeyAndTrimOutdated,
  getProductType,
} from 'bundles/search-common/utils/SearchUtils';

import _t from 'i18n!nls/search';

// helper type
type AutocompleteSection = Hits & { sectionTitle?: string | boolean; hits?: AutoCompleteHits };
type ReturnValue = AutocompleteSection[];
type SearchProductHitWithTracking = SearchProductHit & { indexName?: string | null };
type SearchSuggestionHitWithTracking = SearchSuggestionHit & { indexName?: string | null };
type SearchArticleHitWithTracking = SearchArticleHit & { indexName?: string | null };
type HitWithTracking = (
  | SearchProductHitWithTracking
  | SearchSuggestionHitWithTracking
  | SearchArticleHitWithTracking
)[];

function filterUndefined<T>(item: T | undefined | null): item is T {
  return !!(item as T);
}

const formatTerms = (terms: (string | undefined)[]) => {
  if (!terms) return [];
  const sectionItems = terms.map((termName: string | undefined, termPosition: number) => ({
    name: termName,
    image: SearchesIcon,
    indexPosition: 0,
    objectID: `zero-state-data~${termName}`,
    hitPosition: termPosition,
  }));
  return sectionItems;
};

// Type guard to check if an entity has a difficulty level
function getDifficultyLevel(entity: DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity | null | undefined): string {
  if (entity && typeof entity.difficultyLevel === 'string') {
    return capitalize(entity.difficultyLevel);
  } else {
    return '';
  }
}

export const getRecentlyViewedAutocompleteItems = (
  recentlyViewedCollection?: DiscoveryCollection | null
): AutocompleteItems => {
  const recentlyViewedProducts = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
  const products = [];

  const localRecentlyViewedProducts = recentlyViewedProducts.map((recentItem) => {
    const productType = recentItem.productType ?? '';
    const difficulty = recentItem.difficulty ?? '';

    const supportText = [recentItem.partnerName, productType, difficulty].filter(Boolean).join(' • ');

    return {
      id: `${RECENTLY_VIEWED_PREFIX}${recentItem.name}` || '',
      name: recentItem.name || '',
      imageUrl: recentItem.partnerLogo || '',
      supportText: supportText || '',
      path: recentItem.path || '',
      eventProductId: recentItem.id,
    };
  });
  products.push(...localRecentlyViewedProducts);
  // If there are fewer than 3 recently viewed products, append products from recentlyViewedCollection
  // collection lags so we need to use local storage for most recent viewed product
  if (products.length < 3 && recentlyViewedCollection?.entities) {
    const existingProductNames = new Set(products.map((product) => product.name));

    const additionalProducts = recentlyViewedCollection.entities
      .filter((entity) => entity && !existingProductNames.has(entity.name))
      .slice(0, 3 - products.length)
      .map((entity) => {
        const difficultyLevel = getDifficultyLevel(entity as DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity);
        const marketingProductType = getProductType(
          (entity as DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity)?.productCard?.marketingProductType as string
        );

        const supportText = [
          entity?.partners?.map((partner) => partner?.name).join(', '),
          marketingProductType,
          difficultyLevel,
        ]
          .filter(Boolean)
          .join(' • ');

        return {
          id: `${RECENTLY_VIEWED_PREFIX}${entity?.name}` || '',
          name: entity?.name || '',
          imageUrl: entity?.partners ? entity?.partners[0]?.logo || '' : '',
          supportText: supportText || '',
          path: entity?.url || '',
          eventProductId: entity?.id,
        };
      });

    products.push(...additionalProducts);
  }
  return products.slice(0, 3);
};
export const getRecentlySearchedAutocompleteItems = (): AutocompleteItems => {
  const recentlySearchedTerms = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
  return recentlySearchedTerms
    .map((term) => ({
      id: `${RECENT_SEARCHES_PREFIX}${term}`,
      name: term,
    }))
    .slice(0, 5);
};

const getRecentlyViewedSectionItems = () => {
  const recentlyViewedProducts = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
  if (recentlyViewedProducts.length === 0) return [];
  const sectionItems = recentlyViewedProducts.map((recentItem, itemPosition) => ({
    name: recentItem.name,
    partners: [recentItem.partnerName],
    objectUrl: recentItem.path,
    image: recentItem.image,
    indexPosition: 1,
    objectID: `zero-state-data~${recentItem.name}`,
    hitPosition: itemPosition,
  }));
  return sectionItems.slice(0, ZERO_STATE_ITEMS_PER_SECTION);
};

export const getFirstSectionData = (
  collectionRecommendations?: DiscoveryCollection | null
): AutocompleteSection | undefined => {
  const moduleID = collectionRecommendations?.id || '';
  const getSectionTitle = (id: string) => {
    let translatedSectionTitle = '';
    if (id.includes('recent-views')) translatedSectionTitle = _t('Based on your searches');
    if (id.includes('cold-start')) translatedSectionTitle = _t('Recommended for you');
    if (id.includes('trendingByEnrollmentsNumericTag')) translatedSectionTitle = _t('Most Popular Specializations');
    return translatedSectionTitle;
  };
  const inputRecommendationsData = moduleID.includes('trendingByEnrollmentsNumericTag')
    ? collectionRecommendations?.entities?.filter(
        (e) =>
          e?.__typename === 'DiscoveryCollections_specialization' ||
          e?.__typename === 'DiscoveryCollections_professionalCertificate'
      )
    : collectionRecommendations?.entities;
  const recentlyViewedData = getRecentlyViewedSectionItems();
  const { sectionTitle, sectionItems } =
    recentlyViewedData.length > 0
      ? { sectionTitle: _t('Recently viewed'), sectionItems: recentlyViewedData as Partial<Hits>[] }
      : {
          sectionTitle: getSectionTitle(moduleID),
          sectionItems: inputRecommendationsData
            ?.filter((rec) => !!rec)
            .map((rec) => ({
              ...rec,
              image: { imageUrl: rec?.imageUrl, size: AUTOCOMPLETE_PHOTO_SIZE },
              partners: rec?.partners?.map((p) => p?.name),
              objectUrl: rec?.url,
            })) as Partial<Hits>[],
        };
  if (sectionItems) {
    return {
      sectionTitle,
      hits: sectionItems
        .slice(0, ZERO_STATE_ITEMS_PER_SECTION)
        .map((suggestion) => ({ ...suggestion, sectionTitle, trackingName: 'zero_state_product' } as SuggestionHits)),
    } as AutocompleteSection;
  }
  return undefined;
};

const getSecondSectionData = (suggestionHits?: SearchSuggestionHit[]): AutocompleteSection | undefined => {
  const recentSearches = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
  const { sectionItems, sectionTitle } =
    recentSearches.length > 0
      ? {
          sectionItems: formatTerms(recentSearches),
          sectionTitle: _t('Recent Searches'),
        }
      : {
          sectionItems:
            suggestionHits &&
            formatTerms(suggestionHits.map((term: SearchSuggestionHit) => term.name).filter(filterUndefined)),
          sectionTitle: _t('Popular right now'),
        };

  if (sectionItems) {
    return {
      sectionTitle,
      hits: sectionItems.slice(0, ZERO_STATE_ITEMS_PER_SECTION).map(
        (suggestion) =>
          ({
            ...suggestion,
            sectionTitle,
            trackingName: 'zero_state_suggestion',
          } as SuggestionHits)
      ),
    } as AutocompleteSection;
  }
  return undefined;
};

const getSearchZeroStateSections = (
  collectionRecommendations?: DiscoveryCollection | null,
  suggestionHits?: SearchSuggestionHit[]
): ReturnValue => {
  return [getFirstSectionData(collectionRecommendations), getSecondSectionData(suggestionHits)].filter(filterUndefined);
};

const mapSearchSuggestionHits = (hits: HitWithTracking, indexPosition = 0): AutoCompleteHits => {
  return hits?.map(
    (hit, hitPosition: number): SuggestionHits => ({
      ...hit,
      name: hit.name || undefined,
      partners: (hit?.__typename === 'Search_ProductHit' && hit?.partners) || undefined,
      hitPosition,
      indexName: hit.indexName || '',
      indexPosition,
      tagline: (hit?.__typename === 'Search_ProductHit' && hit.tagline) || '',
      objectUrl:
        ((hit?.__typename === 'Search_ProductHit' || hit?.__typename === 'Search_ArticleHit') && hit?.url) || '',
      trackingName: AUTOCOMPLETE_PRODUCT_TYPE_TO_TRACKING_NAME[hit?.__typename || 'Search_ProductHit'],
      image:
        hit?.__typename === 'Search_ProductHit'
          ? { imageUrl: hit?.imageUrl, size: AUTOCOMPLETE_PHOTO_SIZE }
          : AUTOCOMPLETE_PRODUCT_TYPE_TO_ICON[hit?.__typename || 'Search_SuggestionHit'],
    })
  );
};

const mapSearchSuggestionSection = (hits: HitWithTracking, indexPosition = 0): AutocompleteSection | undefined => {
  const isDirectMatchIndex = hits[0]?.__typename === 'Search_ProductHit';
  const rawHits = hits[0];
  if (!rawHits) return undefined;
  const suggestionHits = mapSearchSuggestionHits(hits.slice(0, isDirectMatchIndex ? 1 : 15), indexPosition);

  return {
    // the item below is just to comply with the weird typing of react-autosuggest
    ...suggestionHits[0],
    index: '',
    name: '',
    objectUrl: '',
    trackingName: '',
    indexName: '',
    image: { size: 16, ...suggestionHits[0].image },
    partners: suggestionHits[0].partners || [],
    tagline: suggestionHits[0].tagline || '',
    objectID: suggestionHits[0].objectID || '',
    hitPosition: suggestionHits[0].hitPosition || 0,
    indexPosition: suggestionHits[0].indexPosition || 0,
    // until this line
    sectionTitle:
      hits[0]?.__typename !== 'Search_ProductHit'
        ? getAutocompleteProductTypePrettyName()[hits[0]?.__typename || 'Search_SuggestionHit']
        : undefined,
    // If it's index used for direct match, we just show one result on this section
    hits: suggestionHits,
  };
};

const getSearchSuggestions = (
  query: string,
  hits: SearchProductHitWithTracking[] = [],
  suggestionHits: SearchSuggestionHitWithTracking[] = [],
  articleHits: SearchArticleHitWithTracking[] = []
): ReturnValue => {
  // For each index, take the first n hits according to the config
  // Product spec is to show direct match if user typed more than 3 characters
  const shouldShowDirectMatch = query.length >= 3;
  if (shouldShowDirectMatch) {
    const mainSection = mapSearchSuggestionSection(hits, 0);
    const suggestionSection = mapSearchSuggestionSection(suggestionHits, 1);
    const articleSection = mapSearchSuggestionSection(articleHits, 2);
    return [mainSection, suggestionSection, articleSection].filter(filterUndefined);
  } else {
    const suggestionSection = mapSearchSuggestionSection(suggestionHits, 0);
    const articleSection = mapSearchSuggestionSection(articleHits, 1);
    return [suggestionSection, articleSection].filter(filterUndefined);
  }
};

export function getAutoCompleteSections(
  query: string,
  mainSearchResult?: SearchResult,
  suggestionsData?: SearchResult,
  articlesData?: SearchResult,
  collectionRecommendations?: DiscoveryCollection | null
): ReturnValue {
  const { elements, source } = mainSearchResult || {};
  const indexName = source?.indexName;
  const hits = (elements as SearchProductHit[])?.map((hit: SearchProductHit) => ({ ...hit, indexName }));

  const { elements: suggestionElements, source: suggestionSource } = suggestionsData || {};
  const suggestionIndexName = suggestionSource?.indexName;
  const suggestionHits = (suggestionElements as SearchSuggestionHit[])?.map((hit: SearchSuggestionHit) => ({
    ...hit,
    indexName: suggestionIndexName,
  }));

  const { elements: articleElements, source: articleSource } = articlesData || {};
  const articleIndexName = articleSource?.indexName;
  const articleHits = (articleElements as SearchArticleHit[])?.map((hit: SearchArticleHit) => ({
    ...hit,
    indexName: articleIndexName,
  }));

  if (elements?.length === 0 || query.length < 1) {
    return getSearchZeroStateSections(collectionRecommendations, suggestionHits);
  } else {
    return getSearchSuggestions(query, hits, suggestionHits, articleHits);
  }
}

export type AutocompleteItems = {
  id: string;
  name: string;
  imageUrl?: string;
  supportText?: string;
  path?: string;
  textValue?: string;
  eventProductId?: string;
}[];

export type AutocompleteItemGroup = {
  id: string;
  title: string;
  children: AutocompleteItems;
};

export const mapSearchDataToAutocompleteItems = (elements: SearchHit[], groupId: string): AutocompleteItems => {
  const prefix = groupId === AS_YOU_TYPE_SEARCH_GROUP_ID ? AS_YOU_TYPE_SEARCH_PREFIX : TRENDING_SEARCHES_PREFIX;
  return elements
    .map(
      (item) =>
        item.name &&
        item.id && {
          id: `${prefix}${item.id}`,
          name: item.name,
        }
    )
    .filter(Boolean);
};

export function getSearchUpdatedUrl(searchText: string = '', query: Record<string, string>) {
  const params = new URLSearchParams(query);
  params.set('query', searchText);
  return ON_SITE_SEARCH_PATH + '?' + params.toString().replace(/\+/g, '%20');
}

//* infer mode from query and recent items */
export function inferAutocompleteMode(
  query: string,
  recentlySearchedItems: AutocompleteItems,
  recentlyViewedItems: AutocompleteItems
): AutocompleteMode {
  let autocompleteMode: AutocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE;
  // if the user has searched before and the current query is the same as the previous,
  // show the "repeat search" state
  if ((recentlySearchedItems.length || recentlyViewedItems.length) && !query.length) {
    autocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH;
    // otherwise,if there's a query, we should show the "as you type" state
  } else if (query.length) {
    autocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE;
  }
  return autocompleteMode;
}

export const isExactPartnerNameMatch = (query: string) =>
  getTop10PartnerOptions().some((partner) => partner.name === query);

export function createRepeatSearchAutocompleteItems(
  recentlySearchedItems: AutocompleteItems,
  recentlyViewedData: AutocompleteItems,
  trendingResults: SearchResults | undefined
): AutocompleteItemGroup[] {
  const repeatSearchResults = [];
  if (recentlySearchedItems.length > 0) {
    repeatSearchResults.push({
      id: 'recentSearches',
      title: _t('Recent searches'),
      children: recentlySearchedItems,
    });
  }

  if (recentlyViewedData.length > 0) {
    repeatSearchResults.push({ id: 'recentViewed', title: _t('Recently viewed'), children: recentlyViewedData });
  }

  repeatSearchResults.push({
    id: TRENDING_SEARCHES_GROUP_ID,
    title: _t('Trending topics'),
    children: mapSearchDataToAutocompleteItems(
      trendingResults?.[0].elements.slice(0, 3) || [],
      TRENDING_SEARCHES_GROUP_ID
    ),
  });

  return repeatSearchResults;
}

export function isLoosePartnerNameMatch(partnerName: string, searchText: string): boolean {
  const lowerCaseSearchText = searchText.toLowerCase().trim();
  const lowerCasePartnerName = partnerName.toLowerCase();
  if (!lowerCasePartnerName || !lowerCaseSearchText) return false;
  const isExactMatchFromStart = lowerCasePartnerName.startsWith(lowerCaseSearchText);
  const hasWordMatch = lowerCasePartnerName.split(' ').some((word) => word.startsWith(lowerCaseSearchText));
  return isExactMatchFromStart || hasWordMatch;
}

export function createAsYouTypeAutocompleteItems(
  mainSearchDataElements: SearchHit[] | undefined,
  searchText: string
): AutocompleteItemGroup[] {
  const autocompleteOptions = mapSearchDataToAutocompleteItems(
    mainSearchDataElements || [],
    AS_YOU_TYPE_SEARCH_GROUP_ID
  );

  const partnerMatch = getTop10PartnerOptions().find(({ name }) => isLoosePartnerNameMatch(name, searchText));

  const hasExactSuggestionMatch = autocompleteOptions.some(
    (option) => option.name.toLowerCase() === searchText.trim().toLowerCase()
  );

  // replace last item with partner match if one exists
  if (partnerMatch) {
    autocompleteOptions[autocompleteOptions.length - 1] = partnerMatch;
  }
  // manually add "see all results" option
  if (searchText.trim() && !hasExactSuggestionMatch) {
    autocompleteOptions.push({
      id: `${SEE_ALL_TEXT_PREFIX}${searchText}`,
      name: _t('See all results for "#{searchText}"', { searchText }),
      textValue: searchText,
    });
  }
  return [
    {
      id: 'search',
      title: '',
      children: autocompleteOptions,
    },
  ];
}

export function createZeroStateAutocompleteItems(trendingResults: SearchResults | undefined): AutocompleteItemGroup[] {
  return [
    {
      id: TRENDING_SEARCHES_GROUP_ID,
      title: _t('Trending topics'),
      children: mapSearchDataToAutocompleteItems(trendingResults?.[0].elements || [], TRENDING_SEARCHES_GROUP_ID),
    },
  ];
}

function getSuggestionSectionAndType(sectionPrefix: string | undefined, displayedQuery: string) {
  let suggestionSection: SuggestionSection = 'suggested_searches';
  let suggestionType: SuggestionType = 'search_query';
  const partnerMatch = isExactPartnerNameMatch(displayedQuery);
  switch (sectionPrefix) {
    case RECENT_SEARCHES_PREFIX:
      suggestionSection = 'recent_searches';
      break;
    case SEE_ALL_TEXT_PREFIX:
      suggestionType = 'all_results_for';
      break;
    case RECENTLY_VIEWED_PREFIX:
      suggestionType = 'product';
      suggestionSection = 'recently_viewed';
      break;
    case TRENDING_SEARCHES_PREFIX:
      suggestionSection = 'trending';
      break;
    default:
      break;
  }
  if (partnerMatch) {
    suggestionType = 'partner';
  }

  return { suggestionSection, suggestionType };
}

function findRecentlyViewedProduct(recentlyViewedItems: AutocompleteItems, displayedQuery: string): Product | null {
  const recentlyViewedItemMatch = recentlyViewedItems.find((item) => item.name === displayedQuery);
  const productId = recentlyViewedItemMatch?.eventProductId?.split('~').pop();
  if (productId && recentlyViewedItemMatch) {
    return {
      id: productId,
      name: recentlyViewedItemMatch.name,
      slug: recentlyViewedItemMatch.path,
    };
  }
  return null;
}

function getAssociatedPartner(displayedQuery: string): Partner | null {
  if (isExactPartnerNameMatch(displayedQuery)) {
    const partnerMatch = getTop10PartnerOptions().find(({ name }) => isLoosePartnerNameMatch(name, displayedQuery));
    if (partnerMatch) {
      return { primaryPartnerId: partnerMatch.partnerId };
    }
  }
  return null;
}

function getSuggestionState(autocompleteMode: AutocompleteMode): SuggestionState {
  switch (autocompleteMode) {
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE:
      return 'zero_state';
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE:
      return 'as_you_type';
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH:
      return 'repeat_search';
    default:
      return 'zero_state';
  }
}
export function getAutocompleteEventingData(
  suggestionItem: string,
  autocompleteMode: AutocompleteMode,
  recentlyViewedItems: AutocompleteItems
) {
  const sectionPrefix = autocompletePrefixes.find((prefix) => suggestionItem.startsWith(prefix));
  const displayedQuery = sectionPrefix ? suggestionItem.replace(sectionPrefix, '') : suggestionItem;
  const searchSuggestions = displayedQuery;

  const { suggestionSection, suggestionType } = getSuggestionSectionAndType(sectionPrefix, displayedQuery);
  const product = findRecentlyViewedProduct(recentlyViewedItems, displayedQuery);
  const associatedPartner = getAssociatedPartner(displayedQuery);
  const suggestionState = getSuggestionState(autocompleteMode);

  const result: ClickAutocompleteSuggestions = {
    suggestionState,
    suggestionSection,
    suggestionType,
    searchSuggestions,
    page: {},
  };

  if (associatedPartner) {
    result.associatedPartner = associatedPartner;
  }

  if (product) {
    result.product = product;
  }

  return result;
}
