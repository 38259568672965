import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import Notification from 'bundles/notification-center/components/Notification';
import type {
  EnterpriseContentAssignmentNotification as EnterpriseContentAssignmentNotificationType,
  Notification as NotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: EnterpriseContentAssignmentNotificationType;
  onClick: (notification: NotificationType) => void;
};

const EnterpriseContentAssignmentNotification: React.FC<Props> = ({ notification, onClick }) => {
  const {
    data: { adminName, contentType, contentName, courseName, programContentDeepLinkUrl, xdpUrl },
  } = notification;

  const getContentType = (contentType: string) => {
    if (contentType === 'COURSE') {
      return _t('Course');
    } else if (contentType === 'SPECIALIZATION') {
      return _t('Specialization');
    } else if (contentType === 'LEARNING_PATH') {
      return _t('Learning Path');
    }
    return '';
  };

  const getMessage = () => {
    if (contentType && (contentName || courseName)) {
      return (
        <FormattedHTMLMessage
          message={_t('<b>{adminName}</b> assigned you the <span>{contentType}</span> <b>{contentName}</b>.')}
          adminName={adminName}
          contentType={getContentType(contentType)}
          contentName={courseName || contentName}
          tagName="span"
        />
      );
    } else if (courseName || contentName) {
      return (
        <FormattedHTMLMessage
          message={_t('<b>{adminName}</b> assigned you <b>{contentName}</b>.')}
          adminName={adminName}
          contentName={courseName || contentName}
          tagName="span"
        />
      );
    } else {
      return (
        <FormattedHTMLMessage
          message={_t(`<b>{adminName}</b> assigned you content.`)}
          adminName={adminName}
          tagName="span"
        />
      );
    }
  };

  return (
    <Notification onClick={onClick} href={programContentDeepLinkUrl || xdpUrl} notification={notification}>
      {getMessage()}
    </Notification>
  );
};

export default EnterpriseContentAssignmentNotification;
