import * as React from 'react';
import { useEffect } from 'react';

import { Link } from '@coursera/cds-core';
import type { EnterpriseProperties, Notification as NotificationEventType, Product } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { useEnterpriseProperties } from 'bundles/account-profile/common/eventing/useEnterpriseProperties';
import Notification from 'bundles/notification-center/components/Notification';
import type {
  DegreePathwaysCompletionNudgeMessageNotification as DegreePathwaysCompletionNudgeMessageType,
  Notification as NotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: DegreePathwaysCompletionNudgeMessageType;
  onClick: (notification: NotificationType) => void;
};

type DegreePathwaysCompletionNudgeNotificationEventTracking = {
  notification: NotificationEventType;
  product: Product;
  enterprise: EnterpriseProperties;
};

export enum DegreePathwaysNotificationVariant {
  FIRST_MODULE_COMPLETION = 'FIRST_MODULE_COMPLETION',
  SECOND_MODULE_COMPLETION = 'SECOND_MODULE_COMPLETION',
  THIRD_MODULE_COMPLETION = 'THIRD_MODULE_COMPLETION',
}

const getDegreePathwaysNotificationVariantMessage = (
  variant: DegreePathwaysNotificationVariant,
  specializationName: string
) => {
  switch (variant) {
    case DegreePathwaysNotificationVariant.FIRST_MODULE_COMPLETION:
      return _t(
        "You're making progress—complete #{specializationName} and you could earn academic credit toward a degree.",
        { specializationName }
      );
    case DegreePathwaysNotificationVariant.SECOND_MODULE_COMPLETION:
      return _t(
        'Boost your earning potential—job candidates with degrees often make higher salaries. Completion of #{specializationName} could get you one step closer.',
        { specializationName }
      );
    case DegreePathwaysNotificationVariant.THIRD_MODULE_COMPLETION:
      return _t(
        "You're almost there—complete #{specializationName} and you could earn academic credit toward a degree.",
        {
          specializationName,
        }
      );
    default:
      return '';
  }
};

const DegreePathwaysCompletionNudgeNotification = ({ notification, onClick }: Props) => {
  const track = useTracker();
  const enterpriseProperties = useEnterpriseProperties();

  const { data } = notification;
  const { degreeName, degreeId, degreeSlug, variant } = data;
  const degreeUrl =
    variant === DegreePathwaysNotificationVariant.FIRST_MODULE_COMPLETION
      ? `/degrees/${degreeSlug}`
      : `/degrees/${degreeSlug}?nextStep=emailMeInfo`;

  const trackingData: DegreePathwaysCompletionNudgeNotificationEventTracking = {
    notification: {
      templateId: notification.messageType,
      channelType: 'notification_center' as const,
    },
    product: {
      id: degreeId,
      name: degreeName,
      slug: degreeSlug,
      type: 'degree' as const,
    },
    enterprise: {
      organizationSlug: enterpriseProperties?.organizationSlug,
      programSlug: enterpriseProperties?.programSlug,
    },
  };

  const notificationOnClickEventing = () => {
    onClick(notification);
    track('click_notification', trackingData);
  };

  useEffect(() => {
    if (!notification.isRead) {
      track('view_notification', trackingData);
    }
    // only run once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const specializationName = notification.data.specializationName ?? '';

  return (
    <Notification href={degreeUrl} notification={notification} onClick={notificationOnClickEventing}>
      {getDegreePathwaysNotificationVariantMessage(variant, specializationName)}{' '}
      <Link href={degreeUrl} variant="quiet" onClick={notificationOnClickEventing}>
        {_t('View related degree')}
      </Link>
    </Notification>
  );
};
export default DegreePathwaysCompletionNudgeNotification;
