/* eslint-disable react-hooks/exhaustive-deps */
import { SEARCH_SUGGESTIONS_INDEX_ID } from 'bundles/search-common/constants';
import 'bundles/search-common/constants/filter';
import type { SearchConfig } from 'bundles/search-common/providers/searchTypes';

export const useAutocompleteSearchConfigs = (): SearchConfig[] => {
  const configs: SearchConfig[] = [
    {
      id: SEARCH_SUGGESTIONS_INDEX_ID,
      entityType: 'SUGGESTIONS' as const,
      limit: 8,
    },
  ];

  return configs;
};

export default useAutocompleteSearchConfigs;
