/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import URI from 'jsuri';
import { compose } from 'recompose';

import Imgix from 'js/components/Imgix';
import imgix from 'js/lib/imgix';
import { isRightToLeft } from 'js/lib/language';

// Save/Unsave Experiment
import SavingBookmark from 'bundles/browse/components/SavingBookmark';
import type { DiscoveryCollectionEntity } from 'bundles/browse/components/types/DiscoveryCollections';
import { getProductCardTextColor } from 'bundles/browse/components/utils/BookmarkUtils';
import type { propsFromWithCourseraPlusOwnershipData } from 'bundles/browse/components/withBrowseCourseraPlusOwnerships';
import { withCourseraPlusOwnershipData } from 'bundles/browse/components/withBrowseCourseraPlusOwnerships';
import {
  PRODUCT_CARD_HEIGHT,
  PRODUCT_CARD_HEIGHT_MOBILE,
  PRODUCT_CARD_SQUARE_PARTNER_LOGO_SIDE_LENGTH,
  PRODUCT_CARD_WIDTH,
} from 'bundles/browse/constants';
import type { SavedProductStringTypes } from 'bundles/browse/types/SavedProductQueries';
import type { PropsFromWithMediaMaxWidthScreenTablet } from 'bundles/browse/utils';
import { getDiscoverCollectionProductCardDisplayProps, withMediaMaxWidthScreenTablet } from 'bundles/browse/utils';
import { buildNewWindowOnClickViaUrl } from 'bundles/common/utils/newWindowUtils';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import ProductCard, { ProductCardPlaceholder } from 'bundles/growth-ui/components/ProductCard';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { shouldShowRecentlyUpdatedBadge } from 'bundles/product-card/utils/recentlyUpdatedBadgeUtils';
import { savedProductTypes } from 'bundles/unified-description-page-common/constants';

import _t from 'i18n!nls/browse';

// taken from LegacyBrowseProductCard.tsx
export const legacyStyle = css`
  .rc-BrowseProductCard,
  &.rc-BrowseProductCard {
    min-width: 268px;
    margin-right: 20px;
    display: inline-block;
    position: relative;

    .collection-product-card {
      background: transparent;

      &:focus {
        outline-offset: 3px;
        outline: 5px solid #9ecaed;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .partner-name {
      line-height: 18px;
    }

    .coursera-plus-pill {
      margin: 12px 0;
    }

    ${/* Save/Unsave Experiment */ ''}
    .rc-Bookmark {
      position: absolute;
      bottom: 16px;
      right: 20px;
    }
  }
`;

export const style = css`
  .rc-BrowseProductCard,
  &.rc-BrowseProductCard {
    min-width: 268px;
    margin-right: 20px;
    display: inline-block;
    position: relative;

    &:last-child {
      margin: 0;
    }

    .collection-product-card {
      background: transparent;

      &:focus {
        outline-offset: 3px;
        outline: 5px solid #9ecaed;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .partner-name {
      line-height: 18px;
    }

    .coursera-plus-pill {
      margin: 12px 0;
    }

    ${/* Save/Unsave Experiment */ ''}
    .rc-Bookmark {
      position: absolute;
      bottom: 16px;
      right: 20px;
    }
  }
`;

type TrackingData = Record<string, unknown>;

type PropsForSavedExperiment = {
  saved?: boolean;
  loadingSavedProducts?: boolean;
};

type PropsNowAccessedForSavedExperiment = {
  isRhymeProject?: boolean;
  productVariant?: string;
};

export type PropsFromCaller = {
  tag?: string;
  tagClassNames?: string;
  queryParameters?: { [index: string]: string };
  product: DiscoveryCollectionEntity;
  isLoading?: boolean;
  trackingData?: TrackingData;
  primaryLanguages?: Array<string>;
  forceOpenInNewTab?: boolean;
  isInteractive?: boolean;
  showFreeBadge?: boolean;
  alwaysShowCourseraPlusPill?: boolean;
  shouldUseBareComponent?: boolean;
} & PropsForSavedExperiment &
  PropsNowAccessedForSavedExperiment;

type PropsToComponent = PropsFromCaller &
  PropsFromWithMediaMaxWidthScreenTablet &
  propsFromWithCourseraPlusOwnershipData;

type PlaceholderProps = Partial<PropsToComponent> & PropsFromWithMediaMaxWidthScreenTablet;

/** @deprecated replaced by the new ProductCardV2, do not use this component at all cost */
export const BrowseProductCardPlaceholderRender: React.FC<PlaceholderProps> = memo(
  ({ matches, shouldUseBareComponent }) => {
    return matches ? (
      <div
        className="rc-BrowseProductCard"
        style={{ position: 'relative', width: PRODUCT_CARD_WIDTH, height: PRODUCT_CARD_HEIGHT_MOBILE }}
        {...(shouldUseBareComponent ? {} : { css: style })}
      >
        <ProductCardPlaceholder size="sm" />
      </div>
    ) : (
      <div
        className="rc-BrowseProductCard"
        style={{ position: 'relative', width: PRODUCT_CARD_WIDTH, height: PRODUCT_CARD_HEIGHT }}
      >
        <ProductCardPlaceholder />
      </div>
    );
  }
);

/** @deprecated replaced by the new ProductCardV2, do not use this component at all cost */
export const BrowseProductCardPlaceholder = withMediaMaxWidthScreenTablet(BrowseProductCardPlaceholderRender);

const getPartnerWithBadge = (logoSrc: string, altText: string, partnerName?: string) => {
  return (
    <div>
      <div className="partner-name">{partnerName}</div>
      <div className="coursera-plus-pill">
        <Imgix data-test="badge" src={logoSrc} maxWidth={32} maxHeight={14} alt={altText} />
      </div>
    </div>
  );
};

/** @deprecated replaced by the new ProductCardV2, do not use this component at all cost */
export class BrowseProductCard extends React.PureComponent<PropsToComponent> {
  static defaultProps = {
    isInteractive: true,
  };

  renderProductCardSubtitle() {
    const { product, ownsCourseraPlus, ownsCourseraLite, alwaysShowCourseraPlusPill } = this.props;

    if (!product) {
      return undefined;
    }

    const partnerName = product.partners?.[0]?.name ?? undefined;
    const isCourseOrProject = [
      'DiscoveryCollections_guidedProject',
      'DiscoveryCollections_project',
      'DiscoveryCollections_course',
    ].includes(product.__typename);
    const isPartOfCourseraPlus =
      (product.__typename === 'DiscoveryCollections_guidedProject' && product.isPartOfCourseraPlus) ||
      (product.__typename === 'DiscoveryCollections_project' && product.isPartOfCourseraPlus) ||
      (product.__typename === 'DiscoveryCollections_course' && product.isPartOfCourseraPlus) ||
      (product.__typename === 'DiscoveryCollections_professionalCertificate' && product.isPartOfCourseraPlus) ||
      (product.__typename === 'DiscoveryCollections_specialization' && product.isPartOfCourseraPlus);
    const showCourseraPlusPill = (ownsCourseraPlus && isPartOfCourseraPlus) || alwaysShowCourseraPlusPill;
    const showCourseraLitePill = ownsCourseraLite && isPartOfCourseraPlus;

    if (showCourseraPlusPill) {
      return getPartnerWithBadge(
        isCourseOrProject ? logo.PLUS_PILL_BLUE : logo.PLUS_PILL_WHITE,
        _t('Coursera Plus'),
        partnerName
      );
    }

    if (showCourseraLitePill) {
      return getPartnerWithBadge(
        isCourseOrProject ? logo.LITE_PILL_BLUE : logo.LITE_PILL_WHITE,
        _t('Coursera Lite'),
        partnerName
      );
    }

    return partnerName;
  }

  render() {
    const {
      matches,
      product,
      trackingData,
      isLoading,
      tag,
      tagClassNames,
      primaryLanguages,
      forceOpenInNewTab,
      queryParameters,
      isInteractive,
      showFreeBadge,
      saved,
      loadingSavedProducts,
      shouldUseBareComponent,
    } = this.props;

    if (!product) return null;

    if (isLoading) {
      return <BrowseProductCardPlaceholder />;
    }

    const cardHeight = matches ? PRODUCT_CARD_HEIGHT_MOBILE : PRODUCT_CARD_HEIGHT;
    const cardSize = matches ? 'sm' : 'lg';
    const isS12n = product.__typename === 'DiscoveryCollections_specialization';
    const isS12nOrProfCert =
      product.__typename === 'DiscoveryCollections_specialization' ||
      product.__typename === 'DiscoveryCollections_professionalCertificate';
    const isCostFree =
      (product.__typename === 'DiscoveryCollections_guidedProject' && product.isCostFree) ||
      (product.__typename === 'DiscoveryCollections_project' && product.isCostFree) ||
      (product.__typename === 'DiscoveryCollections_course' && product.isCostFree);
    const computedType = isS12n ? 's12n' : 'course';

    let computedSaveType = savedProductTypes.COURSE as SavedProductStringTypes;
    if (isS12n) computedSaveType = savedProductTypes.SPECIALIZATION as SavedProductStringTypes;
    if (product.__typename === 'DiscoveryCollections_professionalCertificate')
      computedSaveType = savedProductTypes.PROFESSIONAL_CERTIFICATE as SavedProductStringTypes;
    if (product.__typename === 'DiscoveryCollections_guidedProject')
      computedSaveType = savedProductTypes.GUIDED_PROJECT as SavedProductStringTypes;

    const { cardHref, gradient, label, labelAsText } = getDiscoverCollectionProductCardDisplayProps(product, true);
    const cardHrefWithQueryParamsUri = new URI(cardHref);

    if (queryParameters) {
      Object.entries(queryParameters).forEach((param) => {
        cardHrefWithQueryParamsUri.addQueryParam(param[0], param[1]);
      });
    }

    const cardHrefWithQueryParamsUrl = cardHrefWithQueryParamsUri.toString();

    const imageSrc = imgix.processImage(product.imageUrl, { width: PRODUCT_CARD_WIDTH });
    const logoSrc = imgix.processImage(product.partners?.[0]?.logo ?? undefined, {
      width: PRODUCT_CARD_SQUARE_PARTNER_LOGO_SIDE_LENGTH,
      height: PRODUCT_CARD_SQUARE_PARTNER_LOGO_SIDE_LENGTH,
    });
    const titleLang = primaryLanguages && primaryLanguages.length === 1 ? primaryLanguages[0] : undefined;

    const shouldShowFreeBadgeLogic = showFreeBadge && !isS12nOrProfCert && isCostFree;
    let badge = shouldShowFreeBadgeLogic ? _t('Free') : undefined;

    if (product.slug && shouldShowRecentlyUpdatedBadge(product.slug)) {
      badge = _t('Recently Updated');
    }

    const TrackedContainer = isInteractive
      ? (TrackedLink2 as React.ComponentClass<React.ComponentProps<typeof TrackedLink2>>)
      : (TrackedDiv as React.ComponentClass<React.ComponentProps<typeof TrackedDiv>>);

    return (
      <div
        data-e2e={isS12nOrProfCert ? 'browse-s12n-card' : 'browse-course-card'}
        className="rc-BrowseProductCard"
        style={{ width: PRODUCT_CARD_WIDTH, height: cardHeight }}
        {...(shouldUseBareComponent ? {} : { css: style })}
      >
        <TrackedContainer
          trackingName="collection_product_card"
          data={trackingData}
          className="nostyle collection-product-card"
          aria-label={`${product.name}. ${product.partners?.[0]?.name ? product.partners[0].name + '.' : ''} ${
            shouldShowFreeBadgeLogic ? _t('Free') : ''
          } ${labelAsText}.`}
          lang={titleLang}
          withVisibilityTracking={true}
          tabIndex={0}
          {...(isInteractive
            ? {
                href: cardHrefWithQueryParamsUrl,
                onClick: forceOpenInNewTab
                  ? buildNewWindowOnClickViaUrl({ href: cardHrefWithQueryParamsUrl })
                  : undefined,
              }
            : {})}
        >
          <ProductCard
            badge={badge}
            gradient={gradient}
            imageSrc={imageSrc}
            logoSrc={logoSrc}
            title={product.name}
            titleLang={titleLang}
            subtitle={this.renderProductCardSubtitle()}
            isInteractive={isInteractive}
            showGradient={isS12nOrProfCert}
            type={computedType}
            size={cardSize}
            label={label}
            tag={tag}
            tagClassNames={tagClassNames}
            dir={isRightToLeft(_t.getLocale()) ? 'rtl' : 'ltr'}
            useDivForTitleElement
            titleHref={cardHrefWithQueryParamsUrl}
            shouldUseBareComponent={shouldUseBareComponent}
          />
          {!loadingSavedProducts && (
            <SavingBookmark
              saved={saved}
              color={getProductCardTextColor(computedType)}
              product={{ product_id: product.id, product_type: computedSaveType }}
              isRightAligned={true}
            />
          )}
        </TrackedContainer>
      </div>
    );
  }
}

/** @deprecated replaced by the new ProductCardV2, do not use this component at all cost */
export default memo(
  compose<PropsToComponent, PropsFromCaller>(
    withCourseraPlusOwnershipData,
    withMediaMaxWidthScreenTablet
  )(BrowseProductCard)
);
