import growthAcquisitionExperiments from 'bundles/epic/clients/GrowthAcquisition';

export const generateImpressionForNewCPlusEntryPoints = () => growthAcquisitionExperiments.get('newCPlusEntryPoints');

export const previewNewCPlusEntryPoints = () => growthAcquisitionExperiments.preview('newCPlusEntryPoints');

export const isNewCPlusEntryPointsVariantB = () => previewNewCPlusEntryPoints() === 'B';

export const isNewCPlusEntryPointsVariantC = () => previewNewCPlusEntryPoints() === 'C';

export const isNewCPlusEntryPointsVariant = () => isNewCPlusEntryPointsVariantC() || isNewCPlusEntryPointsVariantB();
