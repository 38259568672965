import URI from 'jsuri';

import type { CartCreateOptions } from 'bundles/payments/common/types';
import subscriptionsApi from 'bundles/subscriptions/api/subscriptionsV1';

export const cancel = (id: string) => {
  const uri = new URI();
  uri.addQueryParam('action', 'cancel');
  uri.addQueryParam('id', id);

  return subscriptionsApi.post(uri.toString());
};

export const invalidate = (id: string) => {
  const uri = new URI();
  uri.addQueryParam('action', 'invalidate');
  uri.addQueryParam('id', id);

  return subscriptionsApi.post(uri.toString());
};

export const refund = (id: string) => {
  const uri = new URI();
  uri.addQueryParam('action', 'refund');
  uri.addQueryParam('id', id);

  return subscriptionsApi.post(uri.toString());
};

export const createCart = (options: CartCreateOptions) => {
  const uri = new URI();
  uri.addQueryParam('action', 'createCart');
  return subscriptionsApi.post(uri.toString(), { data: options });
};
