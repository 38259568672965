import _ from 'lodash';

import user from 'js/lib/user';

import type { Partner } from 'bundles/admin/constants/types';
import { DATA_COORDINATOR, PARTNER_ADMIN } from 'bundles/common/constants/PartnerRoles';

class AdminPermission {
  userAccessPermissions: {};

  constructor(userAccessPermissions: { [key: string]: string }) {
    this.userAccessPermissions = userAccessPermissions;
  }

  grantedPermissionOrIsSuperUser(permission: string) {
    if (user.isSuperuser()) {
      return true;
    } else {
      return this.grantedPermission(permission);
    }
  }

  grantedPermission(permission: string) {
    for (const props in this.userAccessPermissions) {
      if (props.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  canViewCourses() {
    return this.grantedPermissionOrIsSuperUser('adminCourse');
  }

  canViewInstitutions() {
    return this.grantedPermissionOrIsSuperUser('adminInstitution');
  }

  canViewAnalytics() {
    return this.grantedPermissionOrIsSuperUser('partnerAnalytics');
  }

  canViewS12n() {
    return this.grantedPermissionOrIsSuperUser('adminS12n');
  }

  canViewS12nV2() {
    return this.grantedPermissionOrIsSuperUser('adminS12nV2');
  }

  canViewDegrees() {
    return this.grantedPermissionOrIsSuperUser('adminDegree');
  }

  /* TODO: add better gating based off the group summaries response */
  canViewGroups() {
    return this.grantedPermissionOrIsSuperUser('adminGroup');
  }

  /* essentially if the user can do ANY admin functionality then they should see the link */
  canViewAdminLink() {
    return (
      this.canViewCourses() ||
      this.canViewInstitutions() ||
      this.canViewAnalytics() ||
      this.canViewS12n() ||
      this.canViewDegrees() ||
      this.canViewGroups()
    );
  }

  canViewDegreeAsLearner(degreeId: string) {
    // degreeType could be credentialTrack or base
    const [degreeType, degreeIdentifier] = degreeId.split('~');
    return this.grantedPermissionOrIsSuperUser(`instanceId~${degreeType}!~${degreeIdentifier},name~adminDegree`);
  }

  canViewDegreeById(degreeId: string) {
    // degreeType could be credentialTrack or base
    const [degreeType, degreeIdentifier] = degreeId.split('~');
    return this.grantedPermissionOrIsSuperUser(
      `instanceId~${degreeType}!~${degreeIdentifier},name~adminDegree,verb~read`
    );
  }

  canCreateGroup() {
    return this.grantedPermissionOrIsSuperUser('name~adminGroup,verb~create');
  }

  canCreateCourse(partnerId?: number) {
    /* For user with only 1 partner association, selectedPartnerId is never set. Adding checking only when we have value set */
    const instanceIdCheck = partnerId ? `instanceId~${partnerId},` : '';
    return this.grantedPermissionOrIsSuperUser(instanceIdCheck + `name~adminCourse,verb~create`);
  }

  canCopyCourse(partnerId?: number, courseId?: string) {
    const partnerIdCheck = partnerId ? `instanceId~${partnerId},` : '';
    const courseIdCheck = courseId ? `instanceId~${courseId},` : '';
    // This must be kept in sync with the BE logic as this is a derived permission.
    return (
      this.grantedPermissionOrIsSuperUser(courseIdCheck + `name~adminCourse,verb~update`) &&
      this.grantedPermissionOrIsSuperUser(partnerIdCheck + `name~adminCourse,verb~create`)
    );
  }

  canCreateGroupForCourse(courseId: string) {
    return this.grantedPermissionOrIsSuperUser(`instanceId~${courseId},name~adminGroup,verb~create`);
  }

  canCreateS12n(instanceId: number) {
    /* For user with only 1 partner association, selectedPartnerId is never set. Adding checking only when we have value set */
    const instanceIdCheck = instanceId ? `instanceId~${instanceId},` : '';
    return this.grantedPermissionOrIsSuperUser(instanceIdCheck + `name~adminS12n,verb~create`);
  }

  canAddInstructorForPartner(partners: Array<Partner>) {
    const allowedRolesList = [DATA_COORDINATOR, PARTNER_ADMIN];
    const partner = _.find(partners, (data) => _.intersection(allowedRolesList, data.myRoles).length > 0);
    return user.isSuperuser() || !!partner;
  }

  canManageInstitutionStaff(partnerId?: number) {
    const instanceIdCheck = partnerId ? `instanceId~${partnerId},` : '';
    return this.grantedPermissionOrIsSuperUser(instanceIdCheck + 'name~adminInstitutionStaff,verb~customVerb!~manage');
  }

  canUpdateCustomRoles(partnerId?: number) {
    const instanceIdCheck = partnerId ? `instanceId~${partnerId},` : '';
    return this.grantedPermissionOrIsSuperUser(instanceIdCheck + 'name~adminCustomRoles,verb~update');
  }

  // Disbursement dashboards are not automatically accessible to SUs(Super Users), a SU must have the Finance Admin Role to view this dashboard.
  canViewDisbursementsDashboard(partnerId?: number) {
    const instanceIdCheck = partnerId ? `instanceId~${partnerId},` : '';
    return this.grantedPermissionOrIsSuperUser(instanceIdCheck + 'name~partnerFinanceAnalytics,verb~read');
  }

  canManagePartnerSettings(partnerId: number | string) {
    return this.grantedPermissionOrIsSuperUser(`instanceId~${partnerId},name~partnerSetting,verb~customVerb!~manage`);
  }
}

export default AdminPermission;
