import { useMemo } from 'react';

import { useCoachPermissions } from 'bundles/ai-course-coach/utils/CoachPermissionWrapper';
import { getGlobalAppName } from 'bundles/ai-course-coach/utils/getGlobalAppName';
import { isCoachEnabledByApp } from 'bundles/ai-course-coach/utils/isCoachEnabledByApp';
import leeCoachEpicClient from 'bundles/epic/clients/leeCoach';
import leeCoreLexEpicClient from 'bundles/epic/clients/leeCoreLex';
import { createFeatureScope } from 'bundles/product-features';

// Feature flags for Product Features System
// https://coursera.atlassian.net/wiki/spaces/ENT/pages/1327235526/Product+Features+Developer+Guide
export const { ProductFeaturesQuery, useProductFeatures } = createFeatureScope({
  enterprise: {
    enableAiCoach: true /* value is unused */,
    enableAiCoachEarlyAccess: true /* value is unused */,
  },
});

// Feature flags for EPIC
export const isShowClipRecommendations = () => {
  return leeCoreLexEpicClient.get('showClipRecommendations');
  //   https://tools.coursera.org/epic/experiment/E2xr4cpFEe2TRhJQjI_Svw
};

/**
 * EPIC checks for Consumer Expansion plan.
 * See [doc](https://docs.google.com/document/d/1ud2J-FcmeCFzoQGeoE2Xv7TdfwVwkvRVUKgHHua8ZEg/edit)
 * for detailed plan
 */
export const isCoachConsumerExpansionEpic = (): boolean => {
  return leeCoachEpicClient.get('accessFromOriginalExperiment');
};

/**
 * EPIC check to enable FullStory for "Coach superusers".
 *
 * See COACH-539 for more details
 */
export const isCoachSuperuserFullStoryEnabled = (): boolean => {
  return leeCoachEpicClient.get('enableCoachSuperuserFullStory');
};

/**
 * EPIC check to enable Recap feature by courseId
 *
 * See COACH-125 for project details, COACH-584 for experiment setup details.
 *
 * Enterprise: https://tools.coursera.org/epic/experiment/VP980excEe6PRg7CBHvMLQ
 * Consumer: https://tools.coursera.org/epic/experiment/jsU5tf52Ee6MMwr_5jAxkQ
 */
export const isCoachRecapEnabled = (courseId?: string): boolean => {
  if (!courseId) {
    return false;
  }

  const enterpriseEnabled = leeCoachEpicClient.get('enableRecapEnterprise', { course_id: courseId });
  const consumerEnabled = leeCoachEpicClient.get('enableRecapConsumer', { course_id: courseId });

  return enterpriseEnabled || consumerEnabled;
};

/**
 * EPIC check to enable Embedded Coach experience for "C+ users".
 *
 */

export const isCoachGAEpic = (): boolean => {
  return leeCoachEpicClient.get('showCoach');
};

export const useCoachEpicEnabledState = () => {
  const appName = useMemo(() => getGlobalAppName(), []);
  const coachIncludedByRoute = useMemo(() => isCoachEnabledByApp(appName), [appName]);

  const coachEnabled = useCoachPermissions();

  return useMemo(
    () => ({
      showEmbeddedCoach: coachIncludedByRoute && coachEnabled.showEmbeddedCoach,
    }),
    [coachEnabled.showEmbeddedCoach, coachIncludedByRoute]
  );
};
