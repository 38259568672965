import * as React from 'react';
import { useEffect } from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { type EventData, useTracker } from '@coursera/event-pulse/react';

import { useEnterpriseProperties } from 'bundles/account-profile/common/eventing/useEnterpriseProperties';
import Notification from 'bundles/notification-center/components/Notification';
import type {
  Notification as NotificationType,
  SetWeeklyGoalReminderNotification as SetWeeklyGoalReminderNotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: SetWeeklyGoalReminderNotificationType;
  onClick: (notification: NotificationType) => void;
};

function SetWeeklyGoalReminderNotification({ notification, onClick }: Props) {
  const enterpriseProperties = useEnterpriseProperties();
  const courseSlug = notification.data.courseSlug;
  const eventingData: EventData<'view_notification' | 'click_notification'> = {
    notification: {
      templateId: notification.messageType,
      channelType: 'notification_center' as const,
    },
    ...(enterpriseProperties?.organizationSlug
      ? {
          enterprise: {
            organizationSlug: enterpriseProperties?.organizationSlug,
          },
        }
      : {}),
  };
  const track = useTracker();

  // useVisibilityTracker will track the view event every time the notification is in the viewport (e.g. if you close and re-open the notification center)
  // regardless of if you've read it before. To avoid that, we use the track function and manually call the view event.
  useEffect(() => {
    if (!notification.isRead) {
      track('view_notification', eventingData);
    }
    // only run once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickWithTracking = (notificationObj: NotificationType) => {
    onClick(notificationObj);
    track('click_notification', eventingData);
  };

  // The reason we are sending them to the course welcome page is so that the redirect will bring them to their relevant module.
  // Also, if the user clicks the notification while inside the core learning experience, we want to keep them there.
  const courseWelcomePageWithSetGoalParam = `/learn/${courseSlug}/home/welcome?setGoal=true`;

  return (
    <Notification
      onClick={handleClickWithTracking}
      href={courseWelcomePageWithSetGoalParam}
      notification={notification}
    >
      <FormattedHTMLMessage
        message={_t('Take charge of your learning by <u>setting a weekly learning goal now</u>.')}
      />
    </Notification>
  );
}

export default SetWeeklyGoalReminderNotification;
