/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose, getContext, setDisplayName } from 'recompose';

import { color, spacing } from '@coursera/coursera-ui';

export const CARD_SECTION_TYPE = {
  BLOCK: 'block',
  HEADER: 'header',
  FOOTER: 'footer',
  IMAGE: 'image',
};

const CONFIG = {
  border: `1px solid ${color.divider}`,
  headerFooter: {
    background: color.bgGray,
    backgroundThemeDark: color.bgGrayThemeDark,
  },
  paddingHorizontal: spacing.lg as string,
  paddingVertical: spacing.sm as string,
};

export const style = css`
  .rc-CardSection,
  &.rc-CardSection {
    &[data-type='block'] {
      padding: ${CONFIG.paddingVertical} ${CONFIG.paddingHorizontal};
    }

    &[data-type='header'] {
      background-color: ${CONFIG.headerFooter.background};
      border-bottom: ${CONFIG.border};
      padding: ${CONFIG.paddingVertical} ${CONFIG.paddingHorizontal};

      &[data-dark-theme='true'] {
        background-color: ${CONFIG.headerFooter.backgroundThemeDark};
      }
    }

    &[data-type='footer'] {
      background-color: ${CONFIG.headerFooter.background};
      border-bottom: ${CONFIG.border};
      padding: ${CONFIG.paddingVertical} ${CONFIG.paddingHorizontal};

      &[data-dark-theme='true'] {
        background-color: ${CONFIG.headerFooter.backgroundThemeDark};
      }
    }

    &[data-type='image'] {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &[data-full-bleed='true'] {
      padding: 0;
    }

    &[data-dark-theme='true'] {
      border-color: ${color.dividerThemeDark};
      color: ${color.primaryTextThemeDark};
    }
  }
`;

type PropsFromCaller = {
  children: JSX.Element | JSX.Element[];
  htmlAttributes?: Record<string, unknown>;
  isFullBleed?: boolean;
  className?: string;
  type?: string;
  shouldUseBareComponent?: boolean;
};

type Props = PropsFromCaller & {
  isThemeDark?: boolean;
};

function CardSection({
  children,
  htmlAttributes,
  isFullBleed,
  isThemeDark,
  className,
  type = CARD_SECTION_TYPE.BLOCK,
  shouldUseBareComponent,
}: Props) {
  return (
    <div
      className={classNames('rc-CardSection', className)}
      data-type={type}
      data-full-bleed={!!isFullBleed}
      data-dark-theme={!!isThemeDark}
      {...(shouldUseBareComponent ? {} : { css: style })}
      {...htmlAttributes}
    >
      {children}
    </div>
  );
}

export default compose<Props, PropsFromCaller>(
  setDisplayName('CardSectionHOC'),
  getContext({ isThemeDark: PropTypes.bool })
)(CardSection);
