import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { classes as localNotificationClasses } from '@core/notifications/LocalNotification/getLocalNotificationCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('inlineNotification', [
  'inlineNotificationTitle',
  'inlineSeverityIcon',
]);

export const inlineNotificationCss = css`
  display: flex;
  padding: var(--cds-spacing-150) var(--cds-spacing-200);
  border-left: none;
  border-radius: var(--cds-border-radius-50);

  ${breakpoints.down('xs')} {
    padding: var(--cds-spacing-150);
  }

  .${localNotificationClasses.content} {
    ${breakpoints.down('xs')} {
      margin-left: 0;
      flex-direction: row-reverse;
      gap: 8px;

      .${classes.inlineNotificationTitle} {
        margin-top: var(--cds-spacing-50);
      }
    }
  }

  .${localNotificationClasses.actions} {
    margin: var(--cds-spacing-200) 0 0;
  }

  .${classes.inlineNotificationTitle} {
    color: var(--cds-color-neutral-primary);
    margin-bottom: 0;
  }

  .${classes.inlineSeverityIcon} {
    margin: var(--cds-spacing-50) 0;

    ${breakpoints.down('xs')} {
      margin: 0;
    }
  }

  .${localNotificationClasses.inlineActionContainer} {
    ${breakpoints.down('xs')} {
      display: flex;
      width: auto;
      margin: 0;
    }
  }

  &.${localNotificationClasses.dismissible} {
    .${localNotificationClasses.inlineActionContainer} {
      ${breakpoints.up('sm')} {
        margin-left: var(--cds-spacing-300);
      }

      ${breakpoints.up('md')} {
        margin-left: var(--cds-spacing-600);
      }
    }
  }

  &.${localNotificationClasses.hasDismiss} {
    .${localNotificationClasses.content} {
      ${breakpoints.down('xs')} {
        flex-direction: column-reverse;
        margin: 0;
      }
    }

    .${localNotificationClasses.inlineActionContainer} {
      ${breakpoints.down('xs')} {
        width: 100%;
      }
    }
  }
`;
