import * as Sentry from '@sentry/react';
import URI from 'jsuri';

import config from 'js/app/config';
import redirect from 'js/lib/coursera.redirect';

import type { AUTH_MODES_WITH_SINGLE_PAGE } from 'bundles/authentication/constants';

const navigateToFullPageAuth = (
  authMode: (typeof AUTH_MODES_WITH_SINGLE_PAGE)[keyof typeof AUTH_MODES_WITH_SINGLE_PAGE],
  redirectTo: string
) => {
  const authPageUrlLocation = new URI().setPath(`/${authMode}`).addQueryParam('redirectTo', redirectTo).toString();
  redirect.setLocation(authPageUrlLocation);
};

function toValidRedirect(redirectTo?: unknown) {
  if (!!redirectTo && typeof redirectTo === 'string') {
    let redirectDomain;
    try {
      redirectDomain = new URL(redirectTo).host;
    } catch (e) {
      if (redirectTo.match(/^[&?/]/)) {
        return redirectTo;
      }
    }
    const courseraDomain = new URL(config.url.base).host.replace(/(https:\/\/)?(www.)?/, '');
    if (redirectDomain?.endsWith(courseraDomain)) {
      return redirectTo;
    }
    Sentry.captureException(new Error('unexpected redirect URL'), { extra: { redirectTo } });
  }
  return config.url.base;
}

export { navigateToFullPageAuth, toValidRedirect };
