import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose, setDisplayName, withContext } from 'recompose';

import 'css!./__styles__/Card';

type PropsFromCaller = {
  isActive?: boolean;
  children: React.ReactNode;
  htmlAttributes?: Record<string, unknown>;
  isInteractive?: boolean;
  className?: string;
  showBorder?: boolean;
  isThemeDark?: boolean;
};

type Props = PropsFromCaller & {
  isThemeDark?: boolean;
};

function Card({ isActive, children, htmlAttributes, isInteractive, isThemeDark, className, showBorder }: Props) {
  return (
    <div
      className={classNames('rc-Card', className)}
      data-show-border={!!showBorder}
      data-interactive={!!isInteractive}
      data-dark-theme={!!isThemeDark}
      data-active={!!isActive}
      {...htmlAttributes}
    >
      {children}
    </div>
  );
}

export default compose<Props, PropsFromCaller>(
  setDisplayName('CardHOC'),
  withContext({ isThemeDark: PropTypes.bool }, (props: { isThemeDark?: boolean }) => ({
    isThemeDark: props.isThemeDark,
  }))
)(Card);
