/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import deferToClientSideRender from 'js/lib/deferToClientSideRender';

import type { Theme } from '@coursera/cds-core';
import { Button, Typography, useTheme } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/browse';

type Props = {
  router: InjectedRouter;
};

const styles = {
  ctaBanner: (theme: Theme) => css`
    .browse-cta-banner {
      height: 320px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: ${theme.palette.blue[900]};

      .banner-description {
        max-width: 1000px;
        text-align: center;
        color: white;
      }

      .join-free-button {
        margin-top: 16px;
        display: block;
        width: 220px;
        height: 48px;
        font-size: 16px;
        border-radius: 3px;
      }
    }

    ${theme.breakpoints.down('sm')} {
      .browse-cta-banner {
        min-height: 320px;
        padding: 28px 20px;
        height: auto;
      }
    }
  `,
};

export const CTABanner = ({ router }: Props) => {
  const trackEvent = useTracker();
  const theme = useTheme();
  const renderCTABannerContent = () => {
    const handleOnClick = () => {
      trackEvent('click_button', {
        button: {
          name: 'signup' as const,
        },
        pageSection: { sectionName: 'bottom_banner' },
      });
      router.replace({
        pathname: router.location.pathname,
        query: { ...router.location.query, ...{ authMode: 'signup' } },
      });
    };
    return (
      <section className="browse-cta-banner">
        <Typography className="banner-description" variant="h1" component="h3">
          {_t('Join for free and get personalized recommendations, updates and offers.')}
        </Typography>
        <Button
          variant="primary"
          component={TrackedButton}
          type="button"
          trackingName="join_free"
          withVisibilityTracking={false}
          requireFullyVisible={false}
          className="join-free-button"
          onClick={handleOnClick}
        >
          {_t('Join for free')}
        </Button>
      </section>
    );
  };

  return (
    <div className="rc-CTABanner" css={styles.ctaBanner(theme)}>
      {renderCTABannerContent()}
    </div>
  );
};

export default compose<Props, {}>(
  deferToClientSideRender,
  connectToRouter<Props, Omit<Props, 'router'>>((router) => ({ router }))
)(CTABanner);
