/* eslint-disable no-restricted-syntax */

/* eslint-disable camelcase */
import { Search_EntityType as SearchEntityType } from '__generated__/graphql-types';

import config from 'js/app/config';

import {
  ALL_PRODUCTS_INDEX_TYPE,
  ARTICLES_INDEX_TYPE,
  SEARCH_ARTICLES_INDEX_ID,
  SEARCH_AUTOCOMPLETE_MAIN_INDEX_ID,
  SEARCH_SUGGESTIONS_INDEX_ID,
  SUGGESTION_INDEX_TYPE,
} from 'bundles/search-common/constants';
import type { SearchStates } from 'bundles/search-common/providers/searchTypes';

import _t from 'i18n!nls/search-common';

export const ZERO_STATE_ITEMS_PER_SECTION = 3;
export const AUTO_COMPLETE_INDEX_TYPE_TO_TRACKING_NAME = {
  [SUGGESTION_INDEX_TYPE]: 'autocomplete_suggestion',
  [ALL_PRODUCTS_INDEX_TYPE]: 'autocomplete_product',
  [ARTICLES_INDEX_TYPE]: 'autocomplete_article',
};

export const ARIA_CONTENT_DEBOUNCE_DURATION_MS = 1000;
export const DEBOUNCE_DURATION_MS = 400;

export const AUTOCOMPLETE_SEARCH_CONFIG = [
  {
    id: SEARCH_AUTOCOMPLETE_MAIN_INDEX_ID,
    entityType: 'PRODUCTS' as const,
    limit: 15,
  },
  {
    id: SEARCH_SUGGESTIONS_INDEX_ID,
    entityType: 'SUGGESTIONS' as const,
    limit: 7,
  },
  {
    id: SEARCH_ARTICLES_INDEX_ID,
    entityType: 'ARTICLES' as const,
    limit: 3,
  },
];

export const AUTOCOMPLETE_PRODUCT_TYPE_TO_TRACKING_NAME = {
  Search_ProductHit: 'autocomplete_product',
  Search_SuggestionHit: 'autocomplete_suggestion',
  Search_ArticleHit: 'autocomplete_article',
};

export const AUTOCOMPLETE_ICON_SIZE = 16;
export const AUTOCOMPLETE_PHOTO_SIZE = 32;
export const DegreeIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/hat.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const CareerIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/collection.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const CourseIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/course.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const SearchesIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/Search.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const ArticlesIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/article.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const MasterTrackIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/hat.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const SpecializationIcon = {
  imageUrl: `${config.url.resource_assets}search/assets/specialization.png`,
  size: AUTOCOMPLETE_ICON_SIZE,
};
export const AUTOCOMPLETE_PRODUCT_TYPE_TO_ICON = {
  Search_SuggestionHit: SearchesIcon,
  Search_ArticleHit: ArticlesIcon,
};
export const getAutocompleteProductTypePrettyName = () => ({
  Search_SuggestionHit: _t('Popular Searches'),
  Search_ArticleHit: _t('Popular Articles'),
});

export const AUTOCOMPLETE_MODES = {
  AUTOCOMPLETE_ZERO_STATE: 'zeroState',
  AUTOCOMPLETE_AS_YOU_TYPE: 'asYouType',
  AUTOCOMPLETE_REPEAT_SEARCH: 'repeatSearch',
} as const;

export type AutocompleteMode = (typeof AUTOCOMPLETE_MODES)[keyof typeof AUTOCOMPLETE_MODES];
export const AUTOCOMPLETE_SUGGESTION_LIMIT: Record<AutocompleteMode, number> = {
  [AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE]: 5,
  [AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE]: 8,
  [AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH]: 7,
};

// autocomplete items for partners
export const getTop10PartnerOptions = () => [
  {
    id: 'Google',
    name: 'Google',
    supportText: _t('View all courses by Google'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/4a/cb36835ae3421187080898a7ecc11d/Google-G_360x360.png`,
    partnerId: '463',
  },
  {
    id: 'IBM',
    name: 'IBM',
    supportText: _t('View all courses by IBM'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/bb/f5ced2bdd4437aa79f00eb1bf7fbf0/IBM-Logo-Blk---Square.png`,
    partnerId: '1207',
  },
  {
    id: 'Microsoft',
    name: 'Microsoft',
    supportText: _t('View all courses by Microsoft'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/cc/61dbdf2c1c475d82d3b8bf8eee1bda/MSFT-stacked-logo_FINAL.png`,
    partnerId: '705',
  },
  {
    id: 'Google Cloud',
    name: 'Google Cloud',
    supportText: _t('View all courses by Google Cloud'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/74/fa9028074941789429dfc1d1b71ddf/gc-logo-360x360.png`,
    partnerId: '443',
  },
  {
    id: 'Stanford University',
    name: 'Stanford University',
    supportText: _t('View all courses by Stanford'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/e8/7cc3d09d3f11e698dfff46d35f2da1/Stanford_Coursera_Logo.png`,
    partnerId: '1',
  },
  {
    id: 'University of Michigan',
    name: 'University of Michigan',
    supportText: _t('View all courses by University of Michigan'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/70/de505d47be7d3a063b51b6f856a6e2/New-Block-M-Stacked-Blue-295C_600x600.png`,
    partnerId: '3',
  },
  {
    id: 'Meta',
    name: 'Meta',
    supportText: _t('View all courses by Meta'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/73/e03b13a8e44df9b19eb279e5506396/360-x-360.png`,
    partnerId: '641',
  },
  {
    id: 'Johns Hopkins University',
    name: 'Johns Hopkins University',
    supportText: _t('View all courses by John Hopkins University'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/74/7ae340ec6911e5b395490a2a565172/JHU-Logo-Square-Mini_180px.png`,
    partnerId: '8',
  },
  {
    id: 'Yale University',
    name: 'Yale University',
    supportText: _t('View all courses by Yale'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/b4/339da416653830d015f5a179f864e1/square_logo_large.png`,
    partnerId: '109',
  },
  {
    id: 'University of Pennsylvania',
    name: 'University of Pennsylvania',
    supportText: _t('View all courses by University of Pennsylvania'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/a2/66aaaad14d426fa9798ed714b3d0e5/UniversityofPennsylvania_Vertical_RGB_coursera-cert.png`,
    partnerId: '6',
  },
  {
    id: 'Duke University',
    name: 'Duke University',
    supportText: _t('View all courses by Duke University'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/6d/3cb3e06c357d40ef60000fb3d12d72/dukesquare_blue.png`,
    partnerId: '7',
  },
  {
    id: 'DeepLearning.AI',
    name: 'DeepLearning.AI',
    supportText: _t('View all courses by DeepLearning.AI'),
    imageUrl: `https://coursera-university-assets.s3.amazonaws.com/b4/5cb90bb92f420b99bf323a0356f451/Icon.png`,
    partnerId: '475',
  },
];

export const SEE_ALL_TEXT_PREFIX = 'SeeAllResultsFor~';
export const RECENTLY_VIEWED_PREFIX = 'recentlyViewed~';
export const RECENT_SEARCHES_PREFIX = 'recentSearches~';
export const AS_YOU_TYPE_SEARCH_PREFIX = 'search~';
export const TRENDING_SEARCHES_PREFIX = 'trending~';
export const TRENDING_SEARCHES_GROUP_ID = 'trending';
export const AS_YOU_TYPE_SEARCH_GROUP_ID = 'search';
export const autocompletePrefixes = [
  SEE_ALL_TEXT_PREFIX,
  RECENTLY_VIEWED_PREFIX,
  RECENT_SEARCHES_PREFIX,
  TRENDING_SEARCHES_PREFIX,
  AS_YOU_TYPE_SEARCH_PREFIX,
];

export const TRENDING_SEARCH_SUGGESTIONS_CONFIG: SearchStates = {
  trending: {
    id: SEARCH_SUGGESTIONS_INDEX_ID,
    entityType: SearchEntityType.Suggestions,
    limit: 5,
    maxValuesPerFacet: 1,
    query: '',
  },
};
