export const CAREER_GOAL_TYPE = 'career-goal';

export const ONBOARDING_MODAL_VIEW_EVENT_ID = 'dashboardEventKey~LAST_PROFILE_COMPLETION_MODAL_VIEW';

export const PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE = 'profileCompleterForExistingUsers';

const CUSTOM_OCCUPATION_ENTRY_TYPES = {
  DEFINED: 'OccupationId',
  USER_GENERATED: 'UserGeneratedOccupation',
};

const LOCAL_STORAGE_KEY_ONBOARDING_STATUS = 'onboardingStatus';
const ONBOARDING_STATUS_TYPE = {
  VIEWED: 'viewed',
  POSTPONED: 'postponed',
};

const exported = {
  CAREER_GOAL_TYPE,
  ONBOARDING_MODAL_VIEW_EVENT_ID,
  PROFILE_COMPLETER_FOR_EXISTING_USERS_COOKIE,
  CUSTOM_OCCUPATION_ENTRY_TYPES,
  LOCAL_STORAGE_KEY_ONBOARDING_STATUS,
  ONBOARDING_STATUS_TYPE,
};

export default exported;
export { CUSTOM_OCCUPATION_ENTRY_TYPES, LOCAL_STORAGE_KEY_ONBOARDING_STATUS, ONBOARDING_STATUS_TYPE };
