/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import classNames from 'classnames';
import _ from 'lodash';
import { compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import imgix from 'js/lib/imgix';

import { Typography } from '@coursera/cds-core';

import BrowseCarousel from 'bundles/browse/components/BrowseCarousel';
import { DomainGetAllQuery } from 'bundles/browse/components/Queries';
import type { DomainGetAllQuery as DomainGetAllQueryData } from 'bundles/browse/components/__generated__/DomainGetAllQuery';
import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import { domainBackgroundScoreMap } from 'bundles/browse/constants';
import {
  MOBILE_TOPIC_COLUMN_HEIGHT,
  NUMBER_OF_DOMAINS,
  mobileCarouselResponsiveProperty,
} from 'bundles/page-header/components/mobile/constants';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/page';

const LongTopicsListTransitionHeight = '-185px';
const TopicsListLineHeight = '50px';
const MobileSearchCardHeight = '100px';
const MobileSearchCardWidth = '150px';
const TransitionDelay = '300ms';

const shimmerAnimationDuration = 1.3;
const shimmerKeyframes = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;
// note: to increase performance, we decided to move all of the CSS injection to the page component
export const style = css`
  .rc-MobileFeaturedTopics {
    height: 450px;
    padding: 28px 0 20px;

    @media (max-width: 1023px) {
      height: auto;
      padding: 0;
    }

    transition: opacity ${TransitionDelay} ease-in;
    -webkit-transition: opacity ${TransitionDelay} ease-in;
    opacity: 1;

    &.hide-with-opacity {
      opacity: 0;
      pointer-events: none;
      transition: opacity ${TransitionDelay} ease-out;
      -webkit-transition: opacity ${TransitionDelay} ease-out;
    }

    .c-mobile-carousel-transition-wrapper {
      transition: opacity ${TransitionDelay} ease-in;
      -webkit-transition: opacity ${TransitionDelay} ease-in;
      opacity: 1;

      &.hide-with-opacity {
        opacity: 0;
        pointer-events: none;
        transition: opacity ${TransitionDelay} ease-out;
        -webkit-transition: opacity ${TransitionDelay} ease-out;
      }

      .c-mobile-topics-heading {
        font-size: 24px;
        margin-top: 8px;
      }

      .mobile-topics-carousel {
        margin: 0;

        .slick-track {
          display: flex;

          .slick-slide {
            .mobile-search-card {
              height: ${MobileSearchCardHeight};
              overflow: hidden;
              margin: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              border-radius: 3px;
              width: ${MobileSearchCardWidth};
              transform: translate3d(0, 0, 0);
              cursor: pointer;

              &:first-child {
                margin-left: 0;
              }

              span {
                position: absolute;
                bottom: 10px;
                color: white;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 13px;
                text-align: center;
                padding: 0 10px;
              }
            }
          }
        }
      }
    }

    .c-mobile-list-transition-wrapper {
      position: relative;
      transition: transform ${TransitionDelay};

      &.mobile-long-list {
        transform: translateY(${LongTopicsListTransitionHeight});
      }

      .c-mobile-topics-list-heading {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;

        /* gray[700] */
        color: #636363;
        margin-top: 30px;
      }

      .c-mobile-topics-list {
        margin: 0;
        padding: 0 0 80px 0;
        transition: max-height ${TransitionDelay};

        .c-mobile-list-link {
          line-height: ${TopicsListLineHeight};
          list-style-type: none;
          font-weight: 100;
          font-size: 18px;

          a {
            text-decoration: none;

            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .placeholder {
    /* shimmer */
    animation-name: ${shimmerKeyframes};
    animation-duration: ${shimmerAnimationDuration}s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;

    &.list {
      display: block;
      height: 35px;
      margin-bottom: 10px;
      width: calc(100vw - 30px);
      position: relative;
      overflow: hidden;
      background-color: #e5e7e8;
      animation-delay: ${shimmerAnimationDuration / 4}s;
    }

    &.card {
      height: ${MOBILE_TOPIC_COLUMN_HEIGHT}px;
      width: 150px;
      margin: 8px 8px 8px 0;
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      background-color: #e5e7e8;
      animation-delay: ${shimmerAnimationDuration / 4}s;
    }
  }
`;

type OuterProps = {
  hideMobileSearchPage?: () => void;
  searchIsFocused?: boolean;
  searchIsOpen?: boolean;
  hideExploreAllList?: boolean;
};

type PropsFromGraphQl = {
  domains: Array<Domain>;
  loading: boolean;
};

type Props = OuterProps & PropsFromGraphQl;

type State = {
  currentPageY: number | null;
  showCarousel: boolean;
};

type FilteredDomain = Omit<Domain, 'id'> & { id: keyof typeof domainBackgroundScoreMap };

type FilteredDomains = Array<FilteredDomain>;

export class MobileFeaturedTopics extends React.Component<Props, State> {
  domainListRef: HTMLElement | null | undefined;

  state: State = {
    currentPageY: null,
    showCarousel: true,
  };

  componentWillReceiveProps({ searchIsFocused, searchIsOpen }: Props) {
    const searchWasOpen = this.props.searchIsOpen;
    const searchWasFocused = this.props.searchIsFocused;
    const closingSearch = searchWasOpen && !searchIsOpen;
    const unfocusingSearch = searchWasFocused && !searchIsFocused;
    if (closingSearch || unfocusingSearch) {
      this.setState({ showCarousel: true, currentPageY: null });
    }
  }

  onTouchEnd = () => {
    this.setState({ currentPageY: null });
  };

  onTouchMove = (event: React.TouchEvent) => {
    const { pageY } = event.touches[0];
    const { currentPageY, showCarousel } = this.state;
    if (currentPageY) {
      const moveUp = currentPageY > pageY + 5;
      const moveDown = currentPageY < pageY - 5;
      const listAtTop = this.domainListRef && this.domainListRef.scrollTop === 0;
      if (moveUp && showCarousel) {
        this.setState({ showCarousel: false, currentPageY: null });
      } else if (moveDown && !showCarousel && listAtTop) {
        this.setState({ showCarousel: true, currentPageY: null });
      } else {
        this.setState({ currentPageY: pageY });
      }
    } else {
      this.setState({ currentPageY: pageY });
    }
  };

  renderDomainCard(domain: FilteredDomain) {
    const imageUrl = domainBackgroundScoreMap[domain.id].url;
    const { hideMobileSearchPage } = this.props;
    const domainPageUrl = `/browse/${domain.slug || domain.id}`;
    const optimzedImageUrl = imgix.processImage(imageUrl, {
      height: 100,
      fit: 'crop',
      auto: 'compress',
    });
    return (
      <TrackedLink2
        href={domainPageUrl}
        data={{ domain: domain.id }}
        trackingName="mobile_header_domain_carousel_card"
        className="mobile-search-card"
        onMouseDown={hideMobileSearchPage}
        aria-label={domain.name}
      >
        <img className="mobile-topic-image" src={optimzedImageUrl} height={100} alt={domain.name} />
        <FormattedMessage message={_t('{domainName}')} domainName={domain.name} />
      </TrackedLink2>
    );
  }

  renderDomains(filteredDomains: FilteredDomains) {
    const sortedDomains = [...filteredDomains].sort((a, b) => {
      return domainBackgroundScoreMap[b.id].score - domainBackgroundScoreMap[a.id].score;
    });

    return sortedDomains.map((domain) => (
      <div className="mobile-search-domain-card" key={domain.id}>
        {this.renderDomainCard(domain)}
      </div>
    ));
  }

  renderPlaceholder = (type: 'card' | 'list') => {
    return _.times(NUMBER_OF_DOMAINS, (i) => (
      <div key={i}>
        <div className={`placeholder ${type === 'list' ? 'list' : 'card'}`} />
      </div>
    ));
  };

  renderDomainsList = (filteredDomains: FilteredDomains) => {
    const { hideMobileSearchPage } = this.props;
    return filteredDomains.map((domain) => (
      <li key={domain.id} className="c-mobile-list-link">
        <TrackedLink2
          href={`/browse/${domain.slug || domain.id}`}
          onMouseDown={hideMobileSearchPage}
          data={{ domain: domain.id }}
          trackingName="mobile_header_domain_list_link"
          aria-label={domain.name}
        >
          <FormattedMessage message={_t('{domainName}')} domainName={domain.name} />
        </TrackedLink2>
      </li>
    ));
  };

  render() {
    const { loading, searchIsFocused, hideExploreAllList, domains } = this.props;
    const { showCarousel } = this.state;
    // Only include domains that have a 'score' in domainBackgroundScoreMap.
    const filteredDomains = domains.filter((domain) => {
      return !!domainBackgroundScoreMap[domain.id as keyof typeof domainBackgroundScoreMap];
    }) as FilteredDomains;

    return (
      <div
        className={classNames('rc-MobileFeaturedTopics c-mobile-search-topics', {
          'hide-with-opacity': searchIsFocused,
        })}
      >
        <div className={classNames('c-mobile-carousel-transition-wrapper', { 'hide-with-opacity': !showCarousel })}>
          <Typography className="c-mobile-topics-heading" variant="h1" component="h2">
            {_t('Featured Topics')}
          </Typography>
          <BrowseCarousel
            style={{ height: MOBILE_TOPIC_COLUMN_HEIGHT }}
            loadOnlyOnClient={false}
            showDots={false}
            responsive={mobileCarouselResponsiveProperty}
            showArrows={false}
            variableWidth={true}
            rootClassName="mobile-topics-carousel"
            trackingData={{ collectionName: 'Topics and Skills' }}
          >
            {loading ? this.renderPlaceholder('card') : this.renderDomains(filteredDomains)}
          </BrowseCarousel>
        </div>
        {!hideExploreAllList && (
          <div className={classNames('c-mobile-list-transition-wrapper', { 'mobile-long-list': !showCarousel })}>
            <h4 className="c-mobile-topics-list-heading headline-3-text">{_t('Explore all Topics and Skills')}</h4>
            <ul
              ref={(node: HTMLElement | null) => {
                this.domainListRef = node;
              }}
              className="c-mobile-topics-list"
              onTouchMove={this.onTouchMove}
              onTouchEnd={this.onTouchEnd}
            >
              {loading ? this.renderPlaceholder('list') : this.renderDomainsList(filteredDomains)}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default compose<Props, OuterProps>(
  // In some apps the mega menu data is SSR'd, so use data we already fetched if available.
  graphql<OuterProps, DomainGetAllQueryData, {}, PropsFromGraphQl>(DomainGetAllQuery, {
    options: {
      fetchPolicy: 'cache-first',
    },
    props: ({ data }) => ({
      domains: data?.loading ? [] : ((data?.DomainsV1Resource?.domains?.elements || []) as Array<Domain>),
      loading: !!data?.loading,
    }),
  })
)(MobileFeaturedTopics);
