/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { useId } from '@coursera/cds-core';

import Add from 'bundles/ai-course-coach/assets/mui/AddIcon';
import Minus from 'bundles/ai-course-coach/assets/mui/MinusIcon';
import { CoachButton } from 'bundles/ai-course-coach/components/common';

import _t from 'i18n!nls/learning-assistant-chat-ai';

type Props = {
  children: React.ReactNode[];
};

const styles = {
  buttonContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
};

/**
 * Enables truncation on a group of CoachDisclosure action buttons based on available container width.
 *
 * See `<CoachDisclosure>` for usage.
 */
export const ShowMoreDisclosureButtons = (props: Props): React.ReactElement => {
  const { children } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [disclosureButtons, setDisclosureButtons] = useState<Props['children']>(children);
  const [showExpandCta, setShowExpandCta] = useState<boolean>(false);
  const baseId = useId();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateButtonContainer = () => {
      setShowExpandCta(false);

      const containerWidth = containerRef.current?.offsetWidth ?? 0;

      if (expanded) {
        // show all buttons on expand
        setDisclosureButtons(children);
        setShowExpandCta(true);
      }
      // Set number of buttons to show based on container width -
      // truncation number is an approximation based on observation of current behavior
      // for these widths. These numbers are more granular than current CDS breakpoints,
      // giving us more finer control on the behavior.
      else if (containerWidth < 450 && children.length > 2) {
        setDisclosureButtons(children.slice(0, 2));
        setShowExpandCta(true);
      } else if (containerWidth < 500 && children.length > 3) {
        setDisclosureButtons(children.slice(0, 3));
        setShowExpandCta(true);
      } else if (containerWidth < 600 && children.length > 4) {
        setDisclosureButtons(children.slice(0, 4));
        setShowExpandCta(true);
      } else if (containerWidth < 700 && children.length > 6) {
        setDisclosureButtons(children.slice(0, 6));
        setShowExpandCta(true);
      } else {
        // show all buttons beyond this width,
        // assuming we will never have so many buttons that even
        // for >700 we need truncation, add another case here if that ever happens
        setDisclosureButtons(children);
        setShowExpandCta(false);
      }
    };

    updateButtonContainer();

    window.addEventListener('resize', updateButtonContainer);

    return () => {
      window.removeEventListener('resize', updateButtonContainer);
    };
  }, [expanded, children]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div ref={containerRef}>
      <div id={baseId} css={styles.buttonContainer}>
        {disclosureButtons}
        {showExpandCta && (
          <CoachButton
            onClick={handleToggle}
            size="small"
            variant="ghost"
            icon={expanded ? <Minus /> : <Add />}
            iconPosition="before"
            aria-label={expanded ? _t('Show less') : _t('Show more')}
            aria-expanded={expanded}
            aria-controls={baseId}
          >
            {expanded ? _t('Less') : _t('More')}
          </CoachButton>
        )}
      </div>
    </div>
  );
};

export default ShowMoreDisclosureButtons;
