/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { useEnterpriseProperties } from 'bundles/account-profile/common/eventing/useEnterpriseProperties';
import Notification from 'bundles/notification-center/components/Notification';
import type {
  CapstoneCompletionNotification as CapstoneCompletionNotificationType,
  Notification as NotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: CapstoneCompletionNotificationType;
  onClick: (notification: NotificationType) => void;
};

export const getTranslations = (courseName: string) => ({
  autoAddNotificationTextPart1: _t('The #{courseName} has been added to your Coursera Skills Profile.', {
    courseName,
  }),
  autoAddNotificationTextPart2: _t('This makes it easy for you to showcase your new skills to employers.'),
  viewSkillsProfile: _t('View Coursera Skills Profile'),
  manuallyAddedNotificationText: _t(
    'You recently completed a project that would look great to potential employers on your profile: #{courseName}.',
    { courseName }
  ),
});

export const capstoneNotificationTemplateId = 'CapstoneCompletionNotificationMessage';
export const capstoneNotificationChannelType = 'notification_center';

const styles = {
  underline: css`
    text-decoration: underline;
  `,
};

export const CapstoneCompletionNotification = ({ notification, onClick }: Props): React.ReactElement => {
  const enterpriseProperties = useEnterpriseProperties();

  const { courseName, isPublished } = notification.data;
  const {
    autoAddNotificationTextPart1,
    autoAddNotificationTextPart2,
    viewSkillsProfile,
    manuallyAddedNotificationText,
  } = getTranslations(courseName);

  const track = useTracker();

  const visibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_notification',
    {
      notification: {
        templateId: capstoneNotificationTemplateId,
        channelType: capstoneNotificationChannelType,
      },
      enterprise: {
        organizationSlug: enterpriseProperties?.organizationSlug,
        programSlug: enterpriseProperties?.programSlug,
      },
    }
  );

  const handleClick = (notification: NotificationType) => {
    track('click_notification', {
      notification: {
        templateId: capstoneNotificationTemplateId,
        channelType: capstoneNotificationChannelType,
      },
      enterprise: {
        organizationSlug: enterpriseProperties?.organizationSlug,
        programSlug: enterpriseProperties?.programSlug,
      },
    });

    onClick(notification);
  };

  const AutoAddedProjectNotificationText = () => (
    <>
      <p>{autoAddNotificationTextPart1}</p>
      <p>{autoAddNotificationTextPart2}</p>
      <p>
        <span css={styles.underline}>{viewSkillsProfile}</span>
      </p>
    </>
  );

  const ManuallyAddedProjectNotificationText = () => (
    <>
      <p>{manuallyAddedNotificationText}</p>
      <p>
        <span css={styles.underline}>{viewSkillsProfile}</span>
      </p>
    </>
  );

  return (
    <div ref={visibilityTrackingRef}>
      <Notification onClick={handleClick} href="/account-profile" notification={notification}>
        {isPublished ? <AutoAddedProjectNotificationText /> : <ManuallyAddedProjectNotificationText />}
      </Notification>
    </div>
  );
};

export default CapstoneCompletionNotification;
