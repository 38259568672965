import * as React from 'react';
import LazyLoad from 'react-lazyload';

import Imgix from 'js/components/Imgix';

import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import { getLowQualityImageSrc } from 'bundles/page/components/shared/utils/lazyImgixUtils';

type InputProps = {
  src: string;
  alt: string; // for accessibility
  role?: string; // for accessibility
  maxWidth: number;
  maxHeight: number;
  width?: number;
  height?: number;
  imgParams?: {}; // REF http://www.imgix.com/docs/reference
  disableDpr3SrcSet?: boolean;
  className?: string;
  onLoad?: () => {};
  style?: {};
  enableFluidWidth?: boolean;
  degradeForSsr?: boolean; // directive for SSR to render an image with a lower quality placeholder
};

const getLazyImgixStyles = (maxHeight: number, maxWidth: number, enableFluidWidth: boolean) => {
  const mediaQueries = {
    [`@media (max-width: ${breakPoint.md}px)`]: {
      maxWidth: '95vw',
    },
    [`@media (max-width: ${breakPoint.sm}px)`]: {
      maxWidth: '90vw',
    },
  };

  return StyleSheet.create({
    lazyImgixWrapper: {
      position: 'relative',
      width: enableFluidWidth ? '100%' : maxWidth,
      ...mediaQueries,
      paddingBottom: `${(maxHeight / maxWidth) * 100}%`,
      height: 0,
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  });
};

const LazyImgix = (props: InputProps) => {
  const defaultImgParams = {
    quality: 40,
    ignoreDpr3SrcSet: props.disableDpr3SrcSet,
  };
  const { maxHeight, maxWidth, enableFluidWidth } = props;

  const styles = getLazyImgixStyles(maxHeight, maxWidth, !!enableFluidWidth);
  const placeHolderRef = React.createRef<HTMLImageElement>();

  const removePlaceholder = () => {
    const placeHolderImgNode = placeHolderRef.current;
    if (placeHolderImgNode) {
      placeHolderImgNode.parentNode?.removeChild(placeHolderImgNode);
    }
  };

  const placeHolderSrc = getLowQualityImageSrc(props);

  const onImageLoad = () => {
    if (props.onLoad) {
      props.onLoad();
    }
    removePlaceholder();
  };

  return (
    <div {...css(styles.lazyImgixWrapper)}>
      <img ref={placeHolderRef} alt="Placeholder" src={placeHolderSrc} {...css(styles.image)} />
      <LazyLoad offset={100} once>
        <Imgix {...props} imgParams={{ ...defaultImgParams, ...props.imgParams }} onLoad={onImageLoad} />
      </LazyLoad>
    </div>
  );
};

export default LazyImgix;
