import appInfo from 'js/lib/appInfo';
import { get as getCookie, set as setCookie } from 'js/lib/cookie';
import { get as getUser, isAuthenticatedUser } from 'js/lib/user';

import { EXCLUDED_APP_NAMES, EXCLUDED_PATHS, PROFILE_CONSENT_COOKIE_NAME } from 'bundles/marketing-consent/constants';
import type { ProfileConsentCookieConsentNamesTypes } from 'bundles/marketing-consent/types';

export const isExcludedPage = (pathname?: string) => {
  const appName = appInfo.get().appName || '';

  return pathname?.match(EXCLUDED_PATHS) !== null || EXCLUDED_APP_NAMES.includes(appName);
};

export const isMissingCookieConsent = (consentName: ProfileConsentCookieConsentNamesTypes) => {
  try {
    const userId = getUser().id;
    const profileConsentCacheCookie = getCookie(PROFILE_CONSENT_COOKIE_NAME);
    if (profileConsentCacheCookie) {
      const profileConsentCacheValue = JSON.parse(atob(profileConsentCacheCookie));

      return !(profileConsentCacheValue[userId][consentName] === false);
    } else {
      return true;
    }
  } catch (err) {
    return true;
  }
};

export const setProfileConsentCookie = (consents: Partial<Record<ProfileConsentCookieConsentNamesTypes, boolean>>) => {
  if (isAuthenticatedUser()) {
    const userId = getUser().id;
    const profileConsentCacheCookie = getCookie(PROFILE_CONSENT_COOKIE_NAME);
    let newCookieValue = consents;
    if (profileConsentCacheCookie) {
      const profileConsentCacheValue = JSON.parse(atob(profileConsentCacheCookie));
      newCookieValue = { ...profileConsentCacheValue[userId], ...consents };
    }

    setCookie(PROFILE_CONSENT_COOKIE_NAME, btoa(JSON.stringify({ [userId]: newCookieValue })), {
      days: 1,
    });
  }
};

export default {
  isExcludedPage,
  isMissingCookieConsent,
  setProfileConsentCookie,
};
