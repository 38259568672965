import config from 'js/app/config';

class UTMSource {
  medium: string | null;

  source: string | null;

  constructor(url: string) {
    const source = new URL(url);

    this.medium = source.searchParams.get('utm_medium');
    this.source = source.searchParams.get('utm_source');
  }

  isEmail() {
    return this.medium === 'email';
  }
}

const url = typeof window === 'undefined' ? config.url.base : window.location.href;
const utmSource = new UTMSource(url);

export default utmSource;
