/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';

import { Button, Grid, Link, Typography2 } from '@coursera/cds-core';
import type { SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import CourseraPlusProductVariants from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { getLandingPageLink, numberOfProductsWithPlusSign } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';

import _t from 'i18n!nls/coursera-plus';

type Props = {
  tabIndex?: number;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLElement>) => void;
  targetRef?: (x0: HTMLDivElement | null) => void;
  role?: string;
  pageSectionName?: SectionName;
};

const styles = {
  container: css`
    width: 100%;
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
    padding: var(--cds-spacing-200) var(--cds-spacing-100);
    margin: 0;
  `,
  freeTrialButtonContainer: css`
    width: 100%;
  `,
  learnMoreLink: css`
    display: inline-block;
    padding-top: var(--cds-spacing-100);
  `,
};

const CourseraPlusValuePropositionBanner = ({
  targetRef,
  tabIndex,
  onKeyDown,
  role,
  pageSectionName = 'page_header_popup_menu',
}: Props) => {
  const track = useTracker();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const enrollInCourseraPlus = () => {
    setIsSubmitting(true);
    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS];
    const options = {
      isSubscriptionOnly: true,
      productItemId: CourseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
    };
    submitEnrollmentPromise({ handleSubmitPromise, options });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      css={styles.container}
      spacing={16}
      ref={(containerRef) => {
        if (targetRef) {
          targetRef(containerRef);
        }
      }}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      role={role}
    >
      <Grid item container direction="column" spacing={8}>
        <Grid item>
          <Typography2 variant="subtitleLarge" component="h3">
            {_t('Get Coursera Plus')}
          </Typography2>
        </Grid>
        <Grid item>
          <Typography2 variant="bodySecondary" component="p">
            {_t(
              'Unlimited access to #{numberOfCourses} courses, hands-on projects, and certificate programs—all included in your subscription',
              { numberOfCourses: numberOfProductsWithPlusSign }
            )}
          </Typography2>
        </Grid>
      </Grid>
      <Grid item css={styles.freeTrialButtonContainer}>
        <Button
          fullWidth
          size="small"
          onClick={() => {
            track('click_button', {
              button: { name: 'start_cplus_monthly' },
              pageSection: { sectionName: pageSectionName },
            });
            enrollInCourseraPlus();
          }}
          loading={isSubmitting}
        >
          {_t('Start 7-day free trial')}
        </Button>
      </Grid>
      <Grid item>
        <Link
          typographyVariant="bodySecondary"
          href={getLandingPageLink()}
          css={styles.learnMoreLink}
          onClick={() => {
            track('click_button', {
              button: { name: 'c_plus_learn_more' },
              pageSection: { sectionName: pageSectionName },
            });
          }}
        >
          {_t('Learn more')}
        </Link>
      </Grid>
    </Grid>
  );
};

export default CourseraPlusValuePropositionBanner;
