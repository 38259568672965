import type * as React from 'react';

type openLinkBuilderTypes = {
  href: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
};

const openLinkInNewTab = (href: string) => {
  window.open(href, '_blank');

  return undefined;
};

/**
 * Builds an onClick function from a supplied HREF value. This also supports
 * running a secondary onClick function, if necessary. This can be used with
 * buttons and other elements that don't natively support the `_target` attr.
 */
export const buildNewWindowOnClickViaUrl = ({ href, onClick }: openLinkBuilderTypes) => {
  return (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (e) e.preventDefault();

    // if an onclick exists along with a href value, it can be insinuated that it needs
    // to run before redirection/opening in new window.
    if (onClick) onClick();
    openLinkInNewTab(href);

    return undefined;
  };
};
