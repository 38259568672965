/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import user from 'js/lib/user';

import { track } from '@coursera/event-pulse/sdk';

import { isAdminV2Enabled } from 'bundles/admin-v2/utils/AdminV2ExperimentUtils';
import CourseraPlusValuePropositionBanner from 'bundles/coursera-plus/components/CourseraPlusValuePropositionBanner';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';
import SignoutButton from 'bundles/page-header/components/SignoutButton';
import UserPortrait from 'bundles/page-header/components/UserPortrait';
import UserPortraitV2 from 'bundles/page-header/components/UserPortraitV2';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import useNewCPlusEntryPointsEligibilityCheck from 'bundles/page-header/hooks/useNewCPlusEntryPointsEligibilityCheck';
import { generateImpressionForNewCPlusEntryPoints } from 'bundles/page-header/utils/experimentUtils';
import { getAccountDropdownOptions } from 'bundles/page-header/utils/pageHeaderNavUtils';
import TrackedButton from 'bundles/page/components/TrackedButton';
import ListWithKeyboardControls from 'bundles/phoenix/components/a11y/ListWithKeyboardControls';
import keys from 'bundles/phoenix/components/a11y/constants';
import type { ReferralOfferData } from 'bundles/referral/types';

import _t from 'i18n!nls/page';

import 'css!./__styles__/AuthenticatedAccountDropdown';

type NavButton = {
  href: string;
  label: string;
  name: string;
};

type Props = {
  isStaff: boolean;
  hideAvatarBorder: boolean;
  label: string;
  programs: Array<EnterpriseProgramsV1>;
  thirdPartyOrganizations?: Array<ThirdPartyOrganizationsV1>;
  degrees: Array<Record<string, string>>;
  showAdminLinks: boolean;
  showFullName?: boolean;
  isMobile?: boolean;
  hasCheatingIncidents?: boolean;
  hideDropdownOptions?: boolean;
  referralOfferData?: ReferralOfferData;
};

type DropDownProps = Props & {
  dropdownId: string;
  toggleMenu: () => void;
  setDropdownMenuRef: (ref: HTMLElement | null) => void;
  setFirstButtonRef: (ref: HTMLElement | null) => void;
};

type State = {
  showNavMenu: boolean;
};

const styles = {
  listItemContainer: css`
    && {
      width: auto;
      height: auto;
      top: 0;
      display: flex;
      align-items: center;
    }
  `,
  listItem: css`
    &&& {
      width: auto;
      padding: 0;
    }
  `,
  dropdownButton: css`
    &&& {
      background: none;
      border: none;
      padding: var(--cds-spacing-100);
      transition: background-color 0.3s ease;

      :hover {
        background-color: var(--cds-color-interactive-background-primary-hover-weak);
      }
    }
  `,
};

const DropdownMenu = ({
  thirdPartyOrganizations,
  isStaff,
  hasCheatingIncidents,
  hideDropdownOptions,
  programs,
  degrees,
  showAdminLinks,
  referralOfferData,
  dropdownId,
  toggleMenu,
  setDropdownMenuRef,
  setFirstButtonRef,
}: DropDownProps) => {
  const { isEligibleUser, shouldShowCourseraPlusBanner, shouldHideReferralLink } =
    useNewCPlusEntryPointsEligibilityCheck();

  useEffect(() => {
    if (isEligibleUser) generateImpressionForNewCPlusEntryPoints();
  }, [isEligibleUser]);

  const navButtons: NavButton[] = getAccountDropdownOptions({
    thirdPartyOrganizations,
    isStaff,
    hasCheatingIncidents,
    referralOfferData: shouldHideReferralLink ? undefined : referralOfferData,
  });

  const inEnterpriseOrDegree = !isEmpty(thirdPartyOrganizations) || !isEmpty(programs) || !isEmpty(degrees);

  // Add this to the front
  /* For user with admin role display `My Courses` link */
  if (inEnterpriseOrDegree || showAdminLinks) {
    navButtons.unshift({
      href: '/?skipBrowseRedirect=true',
      label: _t('My Courses'),
      name: 'my-courses',
    });
  }

  return (
    <ListWithKeyboardControls
      id="c-ph-aai-dropdown"
      domRef={(ref: HTMLElement | null) => {
        setDropdownMenuRef(ref);
      }}
      className="c-authenticated-dropdown-menu"
      role="menu"
      aria-labelledby={dropdownId}
      onEsc={toggleMenu}
      allowDefaultOnEnter
    >
      {[
        ...(shouldShowCourseraPlusBanner
          ? [<CourseraPlusValuePropositionBanner key="coursera-plus" role="menuitem" />]
          : []),
        ...(!hideDropdownOptions
          ? navButtons.map((navInfo, index) => (
              <HeaderRightNavButton
                domRef={(ref: HTMLElement | null) => {
                  if (index === 0) {
                    setFirstButtonRef(ref);
                  }
                }}
                wrapperClassName="dropdown-btn"
                linkClassName="dropdown-link"
                key={navInfo.name}
                linkRole="menuitem"
                {...navInfo}
              />
            ))
          : []),
        <SignoutButton key="sign-out" />,
      ]}
    </ListWithKeyboardControls>
  );
};

class AuthenticatedAccountDropdown extends React.Component<Props, State> {
  node: HTMLElement | null | undefined;

  dropdownButton: HTMLElement | null | undefined;

  firstButtonRef: HTMLElement | null | undefined;

  dropdownMenu: HTMLElement | null | undefined;

  state = { showNavMenu: false };

  componentDidUpdate(prevProps: Props, { showNavMenu: prevShowNavMenu }: State) {
    const { showNavMenu } = this.state;
    if (showNavMenu !== prevShowNavMenu) {
      if (showNavMenu) {
        // Set focus on the first menu item upon expansion so the user could navigate through the menu items
        const firstMenuItem = this.firstButtonRef?.querySelector('#c-ph-aai-dropdown > li > a') as HTMLElement;
        firstMenuItem?.focus();

        // attach / remove event listner to monitor click and close/open menu accordingly.
        // When user click anywhere  after menu is opened, toggleMenu() will close when
        // user clicked outside of the DropDown
        document.addEventListener('click', this.handleClick, false);
      } else {
        document.removeEventListener('click', this.handleClick, false);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  /* Function monitor clicks in DOM. menu will not be toggled if click target element is within menu container */
  handleClick = (e: MouseEvent) => {
    if (this.node && this.node.contains(e.target as Node)) {
      return;
    }
    this.toggleMenu();
  };

  handleBlur: React.FocusEventHandler<HTMLLIElement> = (event: React.FocusEvent<HTMLElement>) => {
    // issue with contains requiring a Node, so i need to typecast the target as the intended element node
    const relatedElement = event.target as Node;

    if (!this.node?.contains(relatedElement)) {
      if (this.state.showNavMenu) {
        this.toggleMenu();
      }
    }
  };

  handleKeyPress = (e: React.KeyboardEvent) => {
    // find first/last item in nav dropdown and focus on element so you can traverse list without first tabbing in
    if (this.dropdownMenu) {
      const key = e.keyCode;
      const dropdownElements = this.dropdownMenu.querySelectorAll(`li > a, button`);

      if (dropdownElements.length) {
        const focusElement = (
          key === keys.down ? dropdownElements[0] : dropdownElements[dropdownElements.length - 1]
        ) as HTMLElement;

        if (key === keys.up || key === keys.down) {
          focusElement.focus({
            preventScroll: true,
          });
        }
      }
    }
  };

  toggleMenu = () => {
    if (!this.state.showNavMenu) {
      track('open_navigation_menu', {
        navigationType: 'profile',
      });
    }

    this.setState((prevState) => ({
      showNavMenu: !prevState.showNavMenu,
    }));
  };

  setDropdownMenuRef = (ref: HTMLElement | null) => {
    this.dropdownMenu = ref;
  };

  setFirstButtonRef = (ref: HTMLElement | null) => {
    if (ref && !this.firstButtonRef) {
      this.firstButtonRef = ref;
    }
  };

  render() {
    const { hideAvatarBorder, showAdminLinks, showFullName = true, isMobile, hideDropdownOptions } = this.props;

    const { showNavMenu } = this.state;
    const learnerName = user.get().full_name;
    const ariaLabel = hideDropdownOptions
      ? _t('User menu for #{learnerName}', { learnerName })
      : _t('User dropdown menu for #{learnerName}', { learnerName });
    const listClassNames = classNames([
      'rc-AuthenticatedAccountDropdown',
      'c-ph-right-nav-button',
      'c-ph-right-nav-desktop-only',
      'c-ph-avatar-button',
      { 'c-ph-right-nav-no-border': hideAvatarBorder },
      { 'c-ph-right-nav-expanded': showNavMenu },
    ]);

    const dropdownButtonClassName = classNames([
      'button-link',
      'horizontal-box',
      'switcher_trigger',
      'align-items-absolute-center',
      'c-authenticated-dropdown-button',
      'c-ph-slim',
      'in-rebrand',
    ]);

    const menuContainerClassNames = classNames([
      'bt3-dropdown',
      'c-authenticated-dropdown-menu-container',
      { 'c-authenticated-dropdown-menu-container-v2': isAdminV2Enabled(), 'no-profile-name': !showFullName },
    ]);

    // There are two instances of this component at any time; one for mobile and one for desktop.
    // The IDs need to be different for lighthouse accessibility tests to pass.
    const dropdownId = isMobile ? 'right-nav-dropdown-btn-mobile' : 'right-nav-dropdown-btn';

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => (
          <li
            className={listClassNames}
            role="none"
            onBlur={this.handleBlur}
            {...(isSimplifiedPageHeader && { css: styles.listItemContainer })}
          >
            <div
              className={menuContainerClassNames}
              ref={(node) => {
                this.node = node;
              }}
              {...(isSimplifiedPageHeader && { css: styles.listItem })}
            >
              <div className="sr-only" aria-atomic="true" aria-live="polite">
                {`Menu pop-up ${showNavMenu ? 'Expanded' : 'Collapsed'}`}
              </div>
              <TrackedButton
                type="button"
                id={dropdownId}
                onSetRef={(ref) => {
                  this.dropdownButton = ref;
                }}
                {...(!isSimplifiedPageHeader && { className: dropdownButtonClassName })}
                {...(isSimplifiedPageHeader && { css: styles.dropdownButton })}
                aria-haspopup={!hideDropdownOptions ? true : undefined}
                aria-label={ariaLabel}
                onClick={this.toggleMenu}
                onKeyDown={this.handleKeyPress}
                dataE2e="header-profile"
                trackingName="profile_drop_down_btn"
              >
                {isAdminV2Enabled() ? (
                  <UserPortraitV2 user={user.get()} showCaret isCaretUp={showNavMenu} />
                ) : (
                  <UserPortrait user={user.get()} showFullName={showFullName} showCaret isCaretUp={showNavMenu} />
                )}
              </TrackedButton>
              {showNavMenu && (
                <DropdownMenu
                  {...this.props}
                  dropdownId={dropdownId}
                  toggleMenu={this.toggleMenu}
                  setDropdownMenuRef={this.setDropdownMenuRef}
                  setFirstButtonRef={this.setFirstButtonRef}
                />
              )}
            </div>
          </li>
        )}
      </PageHeaderContext.Consumer>
    );
  }
}

export default AuthenticatedAccountDropdown;
