/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';

import { REBRAND_COLORS } from 'bundles/front-page/components/modules/buttons/sharedStyles';
import EnterpriseProgramLogo from 'bundles/page-header/components/EnterpriseProgramLogo';
import ThirdPartyOrganizationLogo from 'bundles/page-header/components/ThirdPartyOrganizationLogo';
import type { EnterpriseProgram, ThirdPartyOrganization } from 'bundles/page-header/components/mobile/MobileHeader';
import CourseraLogo from 'bundles/page/components/CourseraLogo';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { TEAMS_LOGO_URL } from 'bundles/teams-v2/constants';

import 'css!./__styles__/HeaderMobileLogo';

const styles = {
  courseraTeamsLogoImage: () => css`
    width: 215px;
    height: 21px;
  `,
  pipe: (theme: Theme) => css`
    margin: 8px 16px;
    height: 26px;
    border-left: 1px solid ${theme.palette.gray[500]};
  `,
};

type Props = {
  showEnterpriseLogo?: boolean;
  showAccountDropdown?: boolean;
  isAdminOrTeachPage?: boolean;
  thirdPartyOrganization?: ThirdPartyOrganization;
  currentProgram?: EnterpriseProgram;
  disableHeaderLogoUserInteraction?: boolean;
};

const HeaderMobileLogo: React.FC<Props> = ({
  showEnterpriseLogo,
  showAccountDropdown,
  isAdminOrTeachPage,
  thirdPartyOrganization,
  currentProgram,
  disableHeaderLogoUserInteraction,
}) => {
  let logoHref: string;

  if (isAdminOrTeachPage) {
    logoHref = '/admin';
  } else if (showEnterpriseLogo) {
    logoHref = '/business/teams';
  } else {
    logoHref = '/';
  }

  const renderLogo = () => {
    if (showEnterpriseLogo) {
      return <CourseraLogo key="courseraLogo" imgSrc={TEAMS_LOGO_URL} ariaHidden css={styles.courseraTeamsLogoImage} />;
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          <CourseraLogo hexColorCode={REBRAND_COLORS.BLUE} />
        </div>
        {/* TODO: Might need a placeholder */}
        {thirdPartyOrganization && (
          <>
            <div css={styles.pipe} />
            <ThirdPartyOrganizationLogo thirdPartyOrganization={thirdPartyOrganization} />
            {currentProgram?.metadata?.squareLogo && (
              <>
                <div css={styles.pipe} />
                <EnterpriseProgramLogo preferRectangle={false} program={currentProgram} />
              </>
            )}
          </>
        )}
      </>
    );
  };

  const renderedLogo = renderLogo();
  const mobileLogoClassNames = `c-mobile-logo horizontal-box align-items-vertical-center isLohpRebrand ${
    showAccountDropdown ? 'mobile-header-logo-center' : ''
  }`;

  if (disableHeaderLogoUserInteraction) {
    return <div className={mobileLogoClassNames}>{renderedLogo}</div>;
  }

  return (
    <TrackedLink2 href={logoHref} className={mobileLogoClassNames} trackingName="mobile_header_logo">
      {renderedLogo}
    </TrackedLink2>
  );
};

export default HeaderMobileLogo;
