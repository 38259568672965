import type React from 'react';

import { FormHelperText as MuiFormHelperText } from '@material-ui/core';

import clsx from 'clsx';

import { useFormControlContext } from '@core/forms/FormControl/FormControlContext';

import getFormSupportTextCss, { classes } from './getFormSupportTextCss';

export type Props = React.ComponentPropsWithRef<'div'>;

/**
 * Support text component for usage with form fields.
 */
const FormSupportText = (props: Props): React.ReactElement<Props> => {
  const { invert, supportTextId } = useFormControlContext({
    supportTextId: props.id,
  });

  return (
    <MuiFormHelperText
      component="div"
      {...props}
      className={clsx(props.className, { [classes.invert]: invert })}
      classes={{ root: classes.root }}
      css={getFormSupportTextCss}
      id={supportTextId}
    />
  );
};

export default FormSupportText;
