/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import SimplifiedMegaMenuWrapper from 'bundles/megamenu/components/SimplifiedMegaMenuWrapper';
import MegaMenuWrapper from 'bundles/page-header/components/desktop/mega-menu/MegaMenuWrapperLite';
import LazyLoadingHandler from 'bundles/page/components/shared/LazyLoadingHandler';
import SearchBar from 'bundles/search-common/components/searchbar/SearchBar';
import { getShowNewMegamenu as isShowNewMegamenu } from 'bundles/search-common/utils/experimentUtils';

import 'css!bundles/page-header/components/desktop/__styles__/BrowseContentWrapper';

const LoadableMegaMenu = createLoadableComponent(
  () => import('bundles/page-header/components/optimized/MegaMenuContent'),
  LazyLoadingHandler
);

type Props = {
  className?: string;
};

type State = {
  searchInputIsFocused: boolean;
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,
};

class BrowseContentWrapper extends React.Component<Props, State> {
  state = { searchInputIsFocused: false };

  onFocus = () => {
    this.setState({ searchInputIsFocused: true });
  };

  onBlur = () => {
    this.setState({ searchInputIsFocused: false });
  };

  render() {
    const { className: customClassName } = this.props;
    const { searchInputIsFocused } = this.state;

    return (
      <div
        className={classNames('browse-content-wrapper horizontal-box', {
          [`${customClassName}`]: customClassName !== undefined,
        })}
      >
        <div className="MegamenuWrapperDiv" key="MegamenuWrapperDiv" id="MegamenuWrapperDiv" css={styles.wrapper}>
          {/* adding wrapper to fix GR-24338 */}
          {isShowNewMegamenu() ? (
            <SimplifiedMegaMenuWrapper />
          ) : (
            <MegaMenuWrapper key="MegamenuWrapper" LoadableMegaMenu={LoadableMegaMenu} />
          )}
        </div>
        <SearchBar
          key="SearchBar"
          isSearchPage={false}
          searchInputIsFocused={searchInputIsFocused}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...this.props}
        />
      </div>
    );
  }
}
export default BrowseContentWrapper;
