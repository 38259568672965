/** @jsx jsx */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { IconProps, Theme } from '@coursera/cds-core';
import { Button, useTheme } from '@coursera/cds-core';
import { CheckIcon, ErrorIcon } from '@coursera/cds-icons';
import type { ApiStatus } from '@coursera/coursera-ui';
import { API_BEFORE_SEND } from '@coursera/coursera-ui/lib/constants/sharedConstants';
import { SvgLoaderSignal } from '@coursera/coursera-ui/svg';

import A11yScreenReaderOnly from 'bundles/a11y/components/cds/A11yScreenReaderOnly';

/*
These are the changes that callers will need to deal with to adopt this component.
It's a fairly minimal set of changes that looks larger than it is.

type -> variant

label as ReactNode -> label as string

htmlAttributes={{
  onMouseOver: this.handleMouseOver,
  onMouseOut: this.handleMouseOut,
  onFocus: this.handleFocus,
  onBlur: this.handleBlur,
}}
->
onMouseOver={this.handleMouseOver}
onMouseOut={this.handleMouseOut}
onFocus={this.handleFocus}
onBlur={this.handleBlur}

ApiButton['size'] -> CDSButton['size']

apiStatusAttributesConfig.label -> label

apiStatusAttributesConfig.disabled -> disabled

// TODO(wbowers): This probably doesn't work right now. I will need to implement the
// ability to override styles when I go to actually use this component.
//
// Right now the only instances of ApiButton's styles actually being overridden are:
// static/bundles/labs-common/components/framed-lab/ExtendModal.tsx
// static/bundles/labs-common/components/framed-lab/SessionEndModal.tsx
// and it's just to float them. Perhaps I can lift those styles up to a parent container
// and shrink the interface of this component even further.
style -> css
*/

type PropsForHtmlAttributes = {
  'data-test'?: string;
};

type PropsForCdsButton = Omit<React.ComponentProps<typeof Button>, 'children'> & {
  beforeSendIcon?: React.ReactElement | undefined;
};

// These props types use non-standard naming conventions, but I wanted to make it clear
// that this component accepts two "kinds" of props: 1) those that are meant for the CDS
// Button directly, and 2) those that are adapted from the design of CUI's ApiButton.
type PropsAdaptedFromCuiApiButton = {
  label: JSX.Element | string;
  apiStatus: ApiStatus;
};

export type PropsToComponent = PropsForCdsButton & PropsAdaptedFromCuiApiButton & PropsForHtmlAttributes;

export const getIconSizeFromButtonSize = (buttonSize: PropsForCdsButton['size']): IconProps['size'] => {
  // Button size actually maps directly to icon size, except that icons can also be "large".
  // But we don't need that here.
  return buttonSize;
};

export const getIconFromApiStatus = (
  apiStatus: ApiStatus,
  beforeSendIcon: React.ReactElement | undefined,
  size: IconProps['size'],
  theme: Theme
): React.ReactElement | undefined => {
  if (apiStatus === 'API_IN_PROGRESS') {
    // TODO(wbowers): CDS icons do not support animation at this time. We will need
    // to use the CUI loader icon for now.
    // return <PreloaderIcon size={size} color="interactive" />;
    return <SvgLoaderSignal color={theme.palette.blue[600]} size={size === 'medium' ? 20 : 16} />;
  } else if (apiStatus === 'API_SUCCESS') {
    return <CheckIcon size={size} color="success" />;
  } else if (apiStatus === 'API_ERROR') {
    return <ErrorIcon size={size} color="error" />;
  } else if (apiStatus === 'API_BEFORE_SEND') {
    return beforeSendIcon;
  } else {
    return undefined;
  }
};

export const getIsDisabledFromApiStatus = (apiStatus: ApiStatus): boolean => {
  return apiStatus !== 'API_BEFORE_SEND';
};

export const getStyleFromApiStatus = (apiStatus: ApiStatus, theme: Theme): Interpolation<Theme> => {
  // Unfortunately the `!important`'s were necessary here as the CDS component is overwriting
  // these styles with a higher specificity than I am able to create here.
  if (apiStatus === 'API_SUCCESS') {
    return css`
      background-color: transparent !important;
      box-shadow: inset 0 0 0 1px var(--cds-color-feedback-success) !important;
      color: var(--cds-color-feedback-success) !important;
    `;
  } else if (apiStatus === 'API_ERROR') {
    return css`
      background-color: transparent !important;
      box-shadow: inset 0 0 0 1px var(--cds-color-feedback-error) !important;
      color: var(--cds-color-feedback-error) !important;
    `;
  } else if (apiStatus === 'API_IN_PROGRESS') {
    return css`
      background-color: transparent !important;
      box-shadow: inset 0 0 0 1px var(--cds-color-neutral-disabled-strong) !important;
      color: ${theme.palette.gray[800]} !important;
    `;
  } else {
    return null;
  }
};

/**
 * This component was adapted from CUI's ApiButton:
 * https://github.com/webedx-spark/coursera-ui/blob/master/src/components/basic/ApiButton.js
 *
 * but uses CDS's Button component instead:
 * https://tools.coursera.org/cds-storybook-dev/?path=/docs/inputs-button--default
 */
const ApiButton: React.FC<PropsToComponent> = ({
  label,
  disabled,
  beforeSendIcon,
  apiStatus = API_BEFORE_SEND,
  variant = 'secondary',
  size = 'medium',
  iconPosition = 'before',
  'data-test': dataTest,
  'aria-label': ariaLabel,
  ...rest
}) => {
  const theme = useTheme();
  const iconSize = getIconSizeFromButtonSize(size);
  const icon = getIconFromApiStatus(apiStatus, beforeSendIcon, iconSize, theme);
  const style = getStyleFromApiStatus(apiStatus, theme);
  // Allow callers to overwrite this functionality if they choose to pass a disabled prop.
  const isDisabled = disabled ?? getIsDisabledFromApiStatus(apiStatus);
  // Announce button label changes when status changes in order to support a11y except for initial display
  const isStatusChanged = apiStatus !== 'API_BEFORE_SEND';

  return (
    <React.Fragment>
      <Button
        css={style}
        variant={variant}
        size={size}
        iconPosition={iconPosition}
        icon={icon}
        disabled={isDisabled}
        data-test={dataTest}
        aria-label={ariaLabel}
        {...rest}
      >
        {label}
      </Button>
      <A11yScreenReaderOnly tagName="span" role="status" aria-live="polite">
        {isStatusChanged && (ariaLabel || label)}
      </A11yScreenReaderOnly>
    </React.Fragment>
  );
};

export default ApiButton;
