import urlJoin from 'url-join';

import config from 'js/app/config';
import helpCenterLinks from 'js/lib/courseraHelpcenter';
import requestCountry from 'js/lib/requestCountry';
import user from 'js/lib/user';

import { urlConstants } from 'bundles/common/utils/urlUtils';
import { isPartnersPageMigrationEnabled } from 'bundles/partners-graphql/utils/experiments';

import _t from 'i18n!nls/page';

const makeUrlRoot = (subdomain: string) => `https://${subdomain}.${urlConstants.domain}`;
const rootUrls = {
  www: makeUrlRoot('www'),
  about: makeUrlRoot('about'),
  blog: makeUrlRoot('blog'),
  building: makeUrlRoot('building'),
};

export default function getFooterLinks() {
  const isUserAuthenticated = user.isAuthenticatedUser();
  const requestCountryCode = requestCountry.get();
  const footerLinks = {
    about: { label: _t('About'), href: urlJoin(rootUrls.about, '/') },
    whatWeOffer: { label: _t('What We Offer'), href: urlJoin(rootUrls.about, '/how-coursera-works/') },
    leadership: {
      label: _t('Leadership'),
      href: urlJoin(rootUrls.about, '/leadership'),
    },
    careers: { label: _t('Careers'), href: 'https://careers.coursera.com/' },
    catalog: { label: _t('Catalog'), href: '/browse' },
    courseraPlus: { label: _t('Coursera Plus'), href: '/courseraplus' },
    certificates: { label: _t('Professional Certificates'), href: '/professional-certificate' },
    mastertrack: { label: _t('MasterTrack® Certificates'), href: '/mastertrack' },
    degrees: { label: _t('Degrees'), href: '/degrees' },
    enterprise: {
      label: _t('For Enterprise'),
      href: '/business?utm_campaign=website&utm_content=corp-to-home-footer-for-enterprise&utm_medium=coursera&utm_source=enterprise',
    },
    government: {
      label: _t('For Government'),
      href: '/government?utm_campaign=website&utm_content=corp-to-home-footer-for-government&utm_medium=coursera&utm_source=enterprise',
    },
    campus: {
      label: _t('For Campus'),
      href: '/campus?utm_campaign=website&utm_content=corp-to-home-footer-for-campus&utm_medium=coursera&utm_source=enterprise',
    },
    altEnterprise: {
      label: _t('Enterprise'),
      href: '/business?utm_campaign=website&utm_content=corp-to-home-footer-for-enterprise&utm_medium=coursera&utm_source=enterprise',
    },
    altGovernment: {
      label: _t('Government'),
      href: '/government?utm_campaign=website&utm_content=corp-to-home-footer-for-government&utm_medium=coursera&utm_source=enterprise',
    },
    altCampus: {
      label: _t('Campus'),
      href: '/campus?utm_campaign=website&utm_content=corp-to-home-footer-for-campus&utm_medium=coursera&utm_source=enterprise',
    },
    learners: {
      label: _t('Learners'),
      href: 'https://www.coursera.community',
    },
    partners: {
      label: _t('Partners'),
      href: isPartnersPageMigrationEnabled()
        ? urlJoin(rootUrls.www, '/partners')
        : urlJoin(rootUrls.www, '/about/partners'),
    },
    betaTesters: {
      label: _t('Beta Testers'),
      href: 'https://www.coursera.support/s/article/360000152926-Become-a-Coursera-beta-tester',
    },
    blog: {
      label: _t('Blog'),
      href: rootUrls.blog,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    facebook: {
      label: _t('Facebook'),
      href: 'https://www.facebook.com/Coursera',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    linkedin: {
      label: _t('LinkedIn'),
      href: 'https://www.linkedin.com/company/coursera',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    twitter: {
      label: _t('Twitter'),
      href: 'https://twitter.com/coursera',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    instagram: {
      label: _t('Instagram'),
      href: 'https://www.instagram.com/coursera/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    youtube: {
      label: _t('YouTube'),
      href: 'https://www.youtube.com/user/coursera',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    techBlog: {
      label: _t('Tech Blog'),
      href: 'https://medium.com/coursera-engineering',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    teachingCenter: {
      label: _t('Teaching Center'),
      href: '/teaching-center',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    terms: { label: _t('Terms'), href: '/about/terms' },
    tiktok: {
      label: _t('TikTok'),
      href: 'https://www.tiktok.com/@coursera',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    privacy: { label: _t('Privacy'), href: '/about/privacy' },
    help: { label: _t('Help'), href: helpCenterLinks.getNewHelpCenterHome(isUserAuthenticated) },
    accessibility: {
      label: _t('Accessibility'),
      href: 'https://learner.coursera.help/hc/articles/360050668591-Accessibility-Statement',
    },
    press: { label: _t('Press'), href: '/about/press' },
    contact: { label: _t('Contact'), href: '/about/contact' },
    directory: { label: _t('Directory'), href: '/directory' },
    affiliates: { label: _t('Affiliates'), href: urlJoin(rootUrls.about, '/affiliates') },
    articles: { label: _t('Articles'), href: urlJoin(rootUrls.www, '/articles') },
    becomeAPartner: {
      label: _t('Become a Partner'),
      href: 'https://partnerships.coursera.org/?utm_medium=coursera&utm_source=partnerships&utm_campaign=website&utm_content=corp-to-home-footer-become-a-partner',
    },
    investors: { label: _t('Investors'), href: 'https://investor.coursera.com' },
    modernSlavery: {
      label: _t('Modern Slavery Statement'),
      href: `${config.url.resource_assets}footer/Modern+Slavery+Statement+(April+2024).pdf`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    cookiePolicy: {
      label: requestCountryCode === 'US' ? _t('Do Not Sell/Share') : _t('Manage Cookie Preferences'),
      href: '/about/cookies-manage',
    },
    socialImpact: {
      label: _t('Social Impact'),
      href: '/social-impact',
    },
    podcast: {
      label: _t('The Coursera Podcast'),
      href: 'https://open.spotify.com/show/58M36bneU7REOofdPZxe6A',
    },
  };

  return footerLinks;
}
