/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';
import { branch, compose, withProps } from 'recompose';

import user from 'js/lib/user';

import { Typography } from '@coursera/cds-core';

import BrowseCarousel from 'bundles/browse/components/BrowseCarousel';
import type { DiscoveryCollectionEntity } from 'bundles/browse/components/types/DiscoveryCollections';
import { translateDiscoveryCollectionCarouselContentForSaveLookup } from 'bundles/browse/components/utils/BookmarkUtils';
// Save/Unsave Experiment
import withSavedDataFromProducts from 'bundles/browse/components/withSavedDataFromProducts';
import { responsiveProperty } from 'bundles/browse/constants';
import type { DegreeListsEntry } from 'bundles/browse/types/degree-list';
import type {
  ProductForSavedDataLookup,
  PropsForSavedDataLookup,
  withSavedProductsProps,
} from 'bundles/browse/types/withSavedDataFromProducts';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/browse';

export const style = css`
  .rc-Collection,
  &.rc-Collection {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 0;

    @media (max-width: 599px) {
      padding-bottom: 60px;
    }

    @media (max-width: 1023px) {
      padding: 60px 0 0;
    }

    &.placeholder {
      overflow: hidden;
    }

    .rc-mobileCollectionList {
      &.isClosed {
        .rc-CollectionItem-wrapper:nth-of-type(n + 5) {
          display: none;
        }
      }
    }

    .collection-name {
      margin-bottom: 20px;
    }

    .rc-CollectionItem-wrapper {
      display: flex;
      justify-content: center;
      width: fit-content !important;
      padding: 0 12px;
    }

    .rc-BrowseProductCard {
      margin-right: 0;
    }

    .collection-link {
      color: black;
    }

    .rc-BrowseCarousel {
      .slick-slide {
        min-width: 280px;

        .rc-BrowseDegreeCard:last-child,
        .rc-BrowseProductCard,
        .rc-BrowseProductCard .collection-product-card {
          .openContentBadge {
            background-color: #e8b329;
            color: #000;
            top: 20px;

            &::before {
              border-color: transparent #e8b329 transparent transparent;
            }

            &::after {
              border-color: transparent transparent #e8b329 transparent;
            }
          }
        }
      }

      .unslicked .slick-slide {
        width: auto !important;
      }

      .slick-list {
        min-width: 100%;
      }
    }
  }
`;

type product = DiscoveryCollectionEntity | DegreeListsEntry | null;

type InputProps = {
  tag?: string;
  tagClassNames?: string;
  CollectionItem: React.ElementType;
  entities: product[];
  collectionName: string | null | undefined;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  hideCollectionNameHeader?: boolean;
  collectionLink?: {
    url: string | null | undefined;
    label: string;
  } | null;
  collectionItemHeight?: number;
  loadOnClient?: boolean;
  trackingData?: Record<string, unknown>;
  forceOpenInNewTab?: boolean;
  showFreeBadge?: boolean;
  'data-e2e'?: string;
  shouldUseBareComponent?: boolean;
  isDegreeOrMastertrackCollection?: boolean;
  responsive?: typeof responsiveProperty;
  slideLength?: number;
  styleAdditions?: SerializedStyles;
};

type Props = InputProps & PropsForSavedDataLookup & withSavedProductsProps;

function isDegreeEntry(product: product): product is DegreeListsEntry {
  return (product as DegreeListsEntry)?.degree !== undefined;
}

/** @deprecated replaced by the new ProductCardCollection, do not use this component at all cost */
export class Collection extends React.PureComponent<Props> {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  getDataToTrack = ({
    product = {} as product,
    trackingData,
    entityIndex,
  }: {
    product: product;
    trackingData: Record<string, unknown>;
    entityIndex?: number;
  }) => {
    const isS12n = product && !isDegreeEntry(product) && product.__typename === 'DiscoveryCollections_specialization';
    let entityType = isS12n ? 's12n' : 'course';
    let productId: string | undefined;
    let name: string | undefined;
    let slug: string | undefined;
    if (isDegreeEntry(product)) {
      entityType = 'degree';
      productId = product.degree?.id;
      name = product.degree?.name;
      slug = product.degree?.slug;
    } else {
      productId = product?.id;
      name = product?.name;
      slug = product?.slug;
    }
    const entityId = `${entityType}~${productId}`;
    return { ...trackingData, entityId, entityIndex, name, slug, offeringType: entityType, id: productId };
  };

  renderCollectionCard = (productObj: product, index: number) => {
    const {
      CollectionItem,
      tag,
      tagClassNames,
      trackingData,
      forceOpenInNewTab,
      showFreeBadge,
      savedProductData,
      loadingSavedProducts,
      shouldUseBareComponent,
    } = this.props;
    // @ts-expect-error Find Does Not Exist On Array
    const isSaved = !!savedProductData?.find((savedProductItem) => savedProductItem.productId === productObj?.id);

    return (
      <CollectionItem
        tag={tag}
        tagClassNames={tagClassNames}
        product={productObj}
        entityIndex={index}
        trackingData={trackingData && this.getDataToTrack({ product: productObj, trackingData, entityIndex: index })}
        forceOpenInNewTab={forceOpenInNewTab}
        showFreeBadge={showFreeBadge}
        saved={isSaved}
        loadingSavedProducts={loadingSavedProducts}
        shouldUseBareComponent={shouldUseBareComponent}
        {...productObj}
      />
    );
  };

  renderCollectionName = () => {
    const { collectionName, hideCollectionNameHeader, collectionLink } = this.props;

    if (hideCollectionNameHeader) {
      return null;
    }

    const collectionNameWithOrWithoutLink =
      !user.isAuthenticatedUser() && collectionLink?.url ? (
        <TrackedA
          className="collection-link"
          href={collectionLink.url}
          data={{ url: collectionLink.url }}
          trackingName="collection_carousel_title_link_to_collection_page"
        >
          {collectionName || ''}
        </TrackedA>
      ) : (
        collectionName || ''
      );
    return (
      <div className="horizontal-box align-items-spacebetween">
        <Typography className="collection-name" variant="h1" component="h2">
          {collectionNameWithOrWithoutLink}
        </Typography>
      </div>
    );
  };

  render() {
    const {
      collectionName,
      header,
      footer,
      'data-e2e': e2eTesting,
      trackingData,
      entities,
      loadOnClient,
      collectionItemHeight,
      shouldUseBareComponent,
      styleAdditions,
      slideLength,
      responsive,
    } = this.props;
    const carouselStyle = collectionItemHeight ? { height: collectionItemHeight } : {};

    const e2eTestingObj: { [key: string]: string } = {};
    if (e2eTesting) e2eTestingObj['data-e2e'] = e2eTesting;

    let sectionStyle = {};
    if (!shouldUseBareComponent) {
      sectionStyle = {
        css: styleAdditions ? [style, styleAdditions] : style,
      };
    }

    return (
      <section
        className="rc-Collection"
        {...e2eTestingObj}
        aria-label={_t('#{collectionName} Carousel', { collectionName })}
        {...sectionStyle}
      >
        {header || this.renderCollectionName()}
        <div className="rc-desktopCollection">
          <BrowseCarousel
            loadOnlyOnClient={loadOnClient}
            style={carouselStyle}
            responsive={responsive ?? responsiveProperty}
            slidesToShow={slideLength ?? Math.min(4, entities.length)}
            slidesToScroll={slideLength ?? Math.min(4, entities.length)}
            trackingData={trackingData}
            enableRtl
          >
            {entities
              // Some entities can be null, make sure we filter out these entries as they will leave a blank space in the carousel.
              .filter((entity) => !!entity)
              .map((productObj, index) => (
                <div
                  className="rc-CollectionItem-wrapper"
                  key={isDegreeEntry(productObj) ? productObj.degree?.slug : productObj?.id}
                >
                  {this.renderCollectionCard(productObj, index)}
                </div>
              ))}
          </BrowseCarousel>
        </div>
        {footer}
      </section>
    );
  }
}

/** @deprecated replaced by the new ProductCardCollection, do not use this component at all cost */
export default Collection;

/** @deprecated replaced by the new ProductCardCollection, do not use this component at all cost */
export const CollectionWithSaveExperiment = compose<Props, InputProps>(
  // Skip fetching save product data for collection of degrees.
  // On browse we only support saving for non-degree/mastertrack products.
  branch(
    ({ isDegreeOrMastertrackCollection }: InputProps) => !isDegreeOrMastertrackCollection,
    compose(
      withProps<PropsForSavedDataLookup, InputProps>(({ entities }) => {
        const products = (entities as (DiscoveryCollectionEntity | null)[])
          .map(translateDiscoveryCollectionCarouselContentForSaveLookup)
          .filter((product) => !!product);

        return {
          products: products as ProductForSavedDataLookup[],
        };
      }),
      withSavedDataFromProducts
    )
  )
)(Collection);
