import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { useLocation } from 'js/lib/useRouter';
import { isAuthenticatedUser } from 'js/lib/user';

import { isTermsOfUseRolloutForUS, shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import {
  PROFILE_CONSENT_COOKIE_CONSENT_NAMES,
  SHOW_TERMS_OF_USE_ACCEPT_PARAM,
  TERMS_OF_USE_MODAL_TYPES,
} from 'bundles/marketing-consent/constants';
import ProfileConsentsQuery from 'bundles/marketing-consent/queries/ProfileConsentsQuery.graphql';
import type {
  ProfileConsentsQuery as ProfileConsentsQueryType,
  ProfileConsentsQueryVariables,
} from 'bundles/marketing-consent/queries/__generated__/ProfileConsentsQuery';
import type { TermsOfUseModalTypes } from 'bundles/marketing-consent/types';
import { isExcludedPage, isMissingCookieConsent, setProfileConsentCookie } from 'bundles/marketing-consent/utils';

export const useShowTermsOfUseModal = (shouldShowCCPAMarketingConsent = false) => {
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);

  const location = useLocation();

  const isUserEligibleBase = isAuthenticatedUser() && !isExcludedPage(location.pathname);

  const isUserEligibleForAcceptModal = isUserEligibleBase && isTermsOfUseRolloutForUS();
  const isUserEligibleForUpdateModal = isUserEligibleBase && isTermsOfUseRolloutForUS();

  let modalType: TermsOfUseModalTypes = TERMS_OF_USE_MODAL_TYPES.updateModal;
  let shouldShowModal: boolean;
  let skipProfileConsentsQuery: boolean;

  if (
    location.query?.[SHOW_TERMS_OF_USE_ACCEPT_PARAM] ||
    (shouldShowLegalConsolidationAuthFlow() && shouldShowCCPAMarketingConsent)
  ) {
    modalType = TERMS_OF_USE_MODAL_TYPES.acceptModal;
    skipProfileConsentsQuery = true;
    shouldShowModal = isUserEligibleForAcceptModal;
  } else {
    shouldShowModal = isUserEligibleForUpdateModal;
    skipProfileConsentsQuery =
      !isUserEligibleForUpdateModal || !isMissingCookieConsent(PROFILE_CONSENT_COOKIE_CONSENT_NAMES.touRequired);
  }

  const { data, error } = useQuery<ProfileConsentsQueryType, ProfileConsentsQueryVariables>(ProfileConsentsQuery, {
    skip: skipProfileConsentsQuery,
    context: { clientName: 'gatewayGql' },
  });

  const requiresTermsOfUseConsent = data?.ProfileConsents?.me?.requiresTermsOfUseConsent;

  if (modalType === TERMS_OF_USE_MODAL_TYPES.updateModal && (skipProfileConsentsQuery || !requiresTermsOfUseConsent)) {
    shouldShowModal = false;
  }

  useEffect(() => {
    setShowTermsOfUseModal(shouldShowModal);
  }, [shouldShowModal]);

  useEffect(() => {
    // Set the cookie to false when BE returns false or when BE returns an error
    if (requiresTermsOfUseConsent === false || !!error) {
      setProfileConsentCookie({ [PROFILE_CONSENT_COOKIE_CONSENT_NAMES.touRequired]: false });
    }
  }, [error, requiresTermsOfUseConsent]);

  return {
    shouldShowModal,
    showTermsOfUseModal,
    setShowTermsOfUseModal,
    modalType,
  };
};

export default useShowTermsOfUseModal;
