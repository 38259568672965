import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import Notification from 'bundles/notification-center/components/Notification';
import type {
  Notification as NotificationType,
  ProductPromotionNotification as ProductPromotionNotificationType,
} from 'bundles/notification-center/types';

type Props = {
  notification: ProductPromotionNotificationType;
  onClick: (notification: NotificationType) => void;
};

const ProductPromotionNotification: React.FC<Props> = ({ notification, onClick }) => (
  <Notification onClick={onClick} href={notification.data.redirectUrl} notification={notification}>
    <FormattedHTMLMessage message={notification.data.promotionMessage} />
  </Notification>
);

export default ProductPromotionNotification;
