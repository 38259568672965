import * as React from 'react';
import { useContext } from 'react';

import { compose } from 'recompose';

import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';

type ContextType = { ownsCourseraPlus: boolean; ownsCourseraLite: boolean };
const CourseraPlusOwnershipContext = React.createContext<ContextType>({
  ownsCourseraPlus: false,
  ownsCourseraLite: false,
});
CourseraPlusOwnershipContext.displayName = 'CourseraPlusOwnershipContext';

const withCourseraPlusOwnershipProviderSetup =
  <T extends PropsFromCourseraPlusProductOwnerships>(Component: React.ComponentType<T>) =>
  (props: T) =>
    (
      <CourseraPlusOwnershipContext.Provider
        value={{ ownsCourseraPlus: !!props.ownsCourseraPlus, ownsCourseraLite: !!props.ownsCourseraLite }}
      >
        <Component {...props} />
      </CourseraPlusOwnershipContext.Provider>
    );

export const withCourseraPlusOwnershipProvider = compose(
  withCourseraPlusProductOwnerships(),
  withCourseraPlusOwnershipProviderSetup
);

export type propsFromWithCourseraPlusOwnershipData = { ownsCourseraPlus: boolean; ownsCourseraLite: boolean };

export const withCourseraPlusOwnershipData =
  <T extends {}>(Component: React.ComponentType<T & propsFromWithCourseraPlusOwnershipData>) =>
  (props: T) =>
    (
      <Component
        {...props}
        ownsCourseraPlus={useContext(CourseraPlusOwnershipContext).ownsCourseraPlus}
        ownsCourseraLite={useContext(CourseraPlusOwnershipContext).ownsCourseraLite}
      />
    );
