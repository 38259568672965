/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import classNames from 'classnames';

type Props = {
  logoSrc?: string;
  className?: string;
  htmlAttributes?: Record<string, unknown>;
  shouldUseBareComponent?: boolean;
};

export const style = css({
  '.rc-PartnerLogo, &.rc-PartnerLogo': {
    position: 'absolute',
    border: `1px solid #E5E7E8`,
    borderRadius: 3,
    zIndex: 1,
    backgroundColor: 'white',
    '.partnerLogoImage': {
      width: '100%',
      height: '100%',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  },
});

export default function PartnerLogo({ logoSrc, className, htmlAttributes, shouldUseBareComponent }: Props) {
  return (
    <span
      className={classNames('rc-PartnerLogo', className)}
      {...(shouldUseBareComponent ? {} : { css: style })}
      {...htmlAttributes}
    >
      {logoSrc && <div className="partnerLogoImage" style={{ backgroundImage: `url(${logoSrc})` }} />}
    </span>
  );
}
