import * as React from 'react';

import { getLanguageCode } from 'js/lib/language';

import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';
import { getCareerAcademyLink, getCareerAcademyName } from 'bundles/page/components/shared/utils/careerAcademyUtils';

import _t from 'i18n!nls/page';

type Props = {
  shouldShow: boolean;
};

const CareerAcademyLink: React.FC<Props> = ({ shouldShow }) => {
  if (!shouldShow) {
    return null;
  }
  const language = getLanguageCode();
  const isEnglish = language === 'en';
  const label = isEnglish ? getCareerAcademyName() : _t('Find Careers');
  const careerAcademyProps = {
    href: getCareerAcademyLink('globalnav'),
    label,
    name: 'career-academy',
    noBorder: true,
    wrapperClassName: 'c-ph-career-academy',
  };
  return <HeaderRightNavButton {...careerAcademyProps} key={label} buttonWrapperType="span" />;
};

export default CareerAcademyLink;
