import { useQuery } from '@apollo/client';

import WeekToModuleStatusQuery from 'bundles/custom-labels/queries/CourseIsWeekToModuleEnabled.graphql';
import type {
  WeekToModuleStatusQuery as WeekToModuleStatusQueryResponse,
  WeekToModuleStatusQueryVariables,
} from 'bundles/custom-labels/queries/__generated__/CourseIsWeekToModuleEnabled';

export const useIsWeekToModuleEnabledForCourse = (courseId: string | undefined): boolean => {
  const { data } = useQuery<WeekToModuleStatusQueryResponse, WeekToModuleStatusQueryVariables>(
    WeekToModuleStatusQuery,
    {
      variables: { courseId: `${courseId}` },
      context: { clientName: 'gatewayGql' },
      skip: !courseId,
    }
  );

  return !!data?.Course?.queryById?.isWeekToModuleEnabledForCourse;
};
