/** @jsx jsx */
import { css as emotionCss, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';
import { API_IN_PROGRESS } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { PropsForEnrollPresentation } from 'bundles/coursera-plus/components/CourseraPlusEnrollButton';
import ApiButton from 'bundles/red-shibas-cds/components/ApiButton';

const TrackedCdsButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);
const TrackedEnrollButton = withSingleTracked({ type: 'BUTTON' })(ApiButton);

const emotionStyles = {
  link: emotionCss`
    max-width: 420px;
    padding-top: 0;
  `,
};

const styles = StyleSheet.create({
  singleBigButton: {
    [`@media (max-width: ${breakPoint.sm - 1}px)`]: {
      width: '100%',
    },
  },
  singleBigButtonFullWidthMdAndDown: {
    [`@media (max-width: ${breakpoints.values.md - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  singleBigButtonFullWidthSmAndDown: {
    [`@media (max-width: ${breakpoints.values.sm - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  singleBigButtonSecondary: {
    [`@media (max-width: ${breakpoints.values.sm - 1}px)`]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  link: {
    textDecoration: 'underline !important',
  },
  linkWhite: {
    marginTop: '5px',
    fontSize: '16px',
    color: 'white!important',
    fontWeight: 'normal',
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    ':hover': {
      color: '#eeeeee !important',
    },
  },
});

export const BigButtonNoExpand: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const BigButton: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  const className = css(styles.singleBigButton).className;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
      className={className}
    />
  );
};

export const BigButtonFullWidthMdAndDown: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  const className = css(styles.singleBigButtonFullWidthMdAndDown).className;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
      className={className}
    />
  );
};

export const BigButtonFullWidthSmAndDown: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  const className = css(styles.singleBigButtonFullWidthSmAndDown).className;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
      className={className}
    />
  );
};

export const BigButtonSecondary: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  const className = css(styles.singleBigButtonSecondary).className;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="secondary"
      size="medium"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
      className={className}
    />
  );
};

export const SmallButton: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;
  return (
    <TrackedEnrollButton
      trackingName={trackingName}
      onClick={onClick}
      variant="primary"
      size="small"
      label={props.label as string}
      apiStatus={apiStatus}
      data-e2e="coursera-plus-enroll-button"
      disabled={apiStatus === API_IN_PROGRESS}
    />
  );
};

export const Link: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;

  return (
    <TrackedCdsButton
      disabled={apiStatus === API_IN_PROGRESS}
      loading={apiStatus === API_IN_PROGRESS}
      trackingName={trackingName}
      variant="ghost"
      edgeAlign="start"
      onClick={onClick}
      css={emotionStyles.link}
    >
      {props.label}
    </TrackedCdsButton>
  );
};

export const LinkOnDark: React.FC<PropsForEnrollPresentation> = (props) => {
  const { apiStatus, trackingName, onClick } = props;

  return (
    <TrackedCdsButton
      disabled={apiStatus === API_IN_PROGRESS}
      loading={apiStatus === API_IN_PROGRESS}
      trackingName={trackingName}
      variant="ghostInvert"
      edgeAlign="start"
      onClick={onClick}
      css={emotionStyles.link}
    >
      {props.label}
    </TrackedCdsButton>
  );
};
