import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import BrowseApp from 'bundles/browse/components/BrowseApp';
import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';

const BrowsePage = loadOnRoute(() => import('bundles/browse/components/BrowsePage'));
const DomainPage = loadOnRoute(() => import('bundles/browse/components/Domain/DomainPage'));

export default (
  <CourseraRoute
    path="/browse"
    component={BrowseApp}
    handle={{
      tracking: {
        data: () => {
          return {
            page: {
              segment: 'consumer',
              type: 'browse',
            },
          };
        },
      },
    }}
  >
    <IndexRoute getComponent={BrowsePage} />;
    <Route path="/browse/:domainSlug(/:subdomainSlug)" getComponent={DomainPage} />;
    <Route path="/*" component={NotFound} />
  </CourseraRoute>
);
