import LearnerProfileEpicClient from 'bundles/epic/clients/LearnerProfile';

/*
 * This file includes utilities to check whether specific features related to Learner Profile should be enabled.
 */

/**
 * @returns true if the user is in the treatment group for the custom links (profile slugs) rollout
 */
export const shouldEnableProfileSlugs = () => {
  return LearnerProfileEpicClient.get('enableProfileSlugs');
};

/**
 * @returns true if the user is in the treatment group for resume upload rollout
 */
export const shouldEnableResumeUpload = () => {
  return LearnerProfileEpicClient.get('enableResumeUpload');
};

/**
 *
 * @returns true if the user is in the treatment group for the capstone projects on profile experiment
 */
export const shouldEnableProjectEnhancementsEpic = () => {
  return LearnerProfileEpicClient.get('enableProjectEnhancements');
};

/**
 * @returns true if the enableGlobalCHSOptInFlow epic is rolled out
 */
export const shouldEnableGlobalCHSOptInFlow = () => {
  return LearnerProfileEpicClient.get('enableGlobalCHSOptInFlow');
};

/**
 *
 * @returns true if the user is in the treatment group for the auto published projects a/b experiment
 */
export const shouldEnableAutoPublishedProjects = () => {
  return LearnerProfileEpicClient.get('enableAutoPublishedProjects') === 'enableAutoPublish';
};

/**
 * @returns true if the enableConsumerRoleDiscoveryPages epic is rolled out
 */
export const shouldEnableConsumerRoleDiscoveryPages = () => {
  return LearnerProfileEpicClient.get('enableConsumerRoleDiscoveryPages');
};

/**
 * @returns true if the enableConsumerCareerAcademyBanner epic is rolled out
 */
export const enableConsumerCareerAcademyBanner = () => {
  const value = LearnerProfileEpicClient.get('enableConsumerCareerAcademyBanner');
  return LearnerProfileEpicClient.get('enableConsumerCareerAcademyBanner');
};

/**
 * Function for calling the epic for the s12n auto enroll in next course rollout experiment
 *
 * @returns true if the enableConsumerAutoEnrollment epic is rolled out
 */
export const enableS12nCourseAutoEnrollment = () => {
  return LearnerProfileEpicClient.preview('enableConsumerAutoEnrollment');
};
