import * as React from 'react';
import { graphql } from 'react-apollo';

import { branch, compose } from 'recompose';

import a11yKeyPress from 'js/lib/a11yKeyPress';
import connectToRouter from 'js/lib/connectToRouter';
import { isRightToLeft } from 'js/lib/language';

import { Button, StyleSheet, color, css, fontFamily } from '@coursera/coursera-ui';
import { SvgBookmark, SvgBookmarkFilled } from '@coursera/coursera-ui/svg';

import A11yScreenReaderOnly from 'bundles/a11y/components/A11yScreenReaderOnly';
import type { Bookmark as BookmarkProps } from 'bundles/browse/components/types/Bookmark';
import type {
  SavingBookmarkProps as Props,
  SavingBookmarkPropsFromRouter as PropsFromRouter,
} from 'bundles/browse/components/types/SavingBookmark';
import { getAriaText, getBookmarkText, getTooltipText } from 'bundles/browse/components/utils/BookmarkUtils';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { DeleteSavedProductQuery, SaveProductQuery } from 'bundles/unified-common/utils/restQueries';

import _t from 'i18n!nls/save';

import 'css!./__styles__/SavingBookmark';

type PropsFromCaller = Props & BookmarkProps;
type PropsToComponent = PropsFromCaller & PropsFromRouter;

const styles = StyleSheet.create({
  button: {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
  },
  bookmarkContainer: {
    display: 'inline-block',
  },
  text: {
    fontFamily: fontFamily.bold,
    fontSize: 16,
    marginLeft: 10,
    paddingRight: 4,
  },
  smallText: {
    fontSize: 12,
  },
  darkSmallColor: {
    color: color.black,
  },
  darkColor: {
    color: color.primary,
  },
  lightColor: {
    color: color.white,
  },
  tooltip: {
    top: '-48px',
    display: 'flex',
    opacity: 0,
    transition: 'opacity 500ms',
    alignItems: 'center',
    background: color.black,
    fontFamily: fontFamily.body,
    color: color.white,
    fontSize: '12px',
    lineHeight: '18px',
    position: 'absolute',
    justifyContent: 'center',
    padding: '12px',
    zIndex: 9999999,
    borderRadius: '3px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    ':after': {
      display: 'inline-block',
      content: "' '",
      height: 14,
      width: 14,
      transform: 'rotate(45deg)',
      borderRadius: 2,
      bottom: '-6px',
      zIndex: 0,
      position: 'absolute',
      backgroundColor: color.black,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    },
    ':hover': {
      opacity: 1,
    },
  },
  rightAlignedTooltip: {
    right: -10,
    ':after': {
      right: 14,
    },
  },
  leftAlignedTooltip: {
    left: -10,
    ':after': {
      left: 14,
    },
  },
  showTooltip: {
    opacity: 1,
  },
  hideTooltip: {
    pointerEvents: 'none',
    opacity: 0,
  },
  hideContentFromLayout: {
    visibility: 'hidden',
  },
  savedListLink: {
    textDecoration: 'underline !important',
    color: `${color.white} !important`,
    paddingLeft: 4,
  },
});

const TrackedButton = withSingleTracked({ type: 'BUTTON' })(Button);

export class BookmarkWithSaving extends React.Component<PropsToComponent, {}> {
  handleOnClickLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open('/saved', '_blank');
  };

  renderSaveText = () => {
    const { saved, color: colorFromProps, shouldUseSmallSize } = this.props;

    return (
      <span
        data-e2e="bookmark-text"
        {...css('bookmark-text', styles.text, shouldUseSmallSize && styles.smallText)}
        style={{ color: colorFromProps }}
      >
        {saved ? getBookmarkText.saved() : getBookmarkText.unsaved()}
      </span>
    );
  };

  renderTooltipVOText = () => {
    const { saved, hidingViaCSS } = this.props;

    return !hidingViaCSS ? (saved ? getAriaText.saved() : getAriaText.unsaved()) : null;
  };

  render() {
    const {
      saved,
      hidingViaCSS,
      hidingFromLayout,
      shouldShowText,
      color: colorFromProps,
      shouldUseSmallSize,
      isRightAligned,
      isLeftAligned,
      onClick,
      clearTimer,
      hideNotification,
      product,
    } = this.props;

    const { product_id, product_type } = product || {};
    const bookmarkHeight = shouldUseSmallSize ? 14 : 20;

    return (
      <div
        data-e2e="saving-bookmark"
        {...css('rc-Bookmark', styles.bookmarkContainer)}
        onKeyDown={(event: React.KeyboardEvent) => a11yKeyPress(event, onClick)}
        data-is-rtl={isRightToLeft(_t.getLocale())}
      >
        <TrackedButton
          {...css('saving-bookmark-button', styles.button)}
          data-e2e="bookmark-button"
          type="icon"
          onClick={onClick}
          style={{ margin: 0, padding: 0, border: 0 }}
          trackingName="saved_courses_bookmark"
          data={{ product_id, product_type }}
          aria-label={_t('Save this product')}
        >
          {saved ? (
            <SvgBookmarkFilled size={bookmarkHeight} color={colorFromProps} />
          ) : (
            <SvgBookmark size={bookmarkHeight} color={colorFromProps} />
          )}

          <div
            data-e2e="saving-bookmark-tooltip"
            {...css(
              styles.tooltip,
              hidingFromLayout && styles.hideContentFromLayout,
              hidingViaCSS ? styles.hideTooltip : styles.showTooltip,
              isRightAligned && !isLeftAligned && styles.rightAlignedTooltip,
              isLeftAligned && !isRightAligned && styles.leftAlignedTooltip
            )}
            onMouseOver={clearTimer}
            onMouseLeave={hideNotification}
          >
            {saved ? getTooltipText.saved() : getTooltipText.unsaved()}
            <TrackedA
              href="/saved"
              {...css(styles.savedListLink)}
              onClick={this.handleOnClickLink}
              trackingName="saved_courses_dash_link"
              data={{ product_id, product_type }}
              onFocus={clearTimer}
              onMouseOver={clearTimer}
              onBlur={hideNotification}
              onKeyDown={(event: React.KeyboardEvent) => a11yKeyPress(event, this.handleOnClickLink)}
              tabIndex={hidingFromLayout ? -1 : 0}
            >
              {_t('Saved List')}
            </TrackedA>
          </div>

          {shouldShowText && this.renderSaveText()}
        </TrackedButton>
        <A11yScreenReaderOnly tagName="span">
          <span aria-live="polite">{this.renderTooltipVOText()}</span>
        </A11yScreenReaderOnly>
      </div>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  connectToRouter((router) => {
    const isOnSearch = router.location.pathname.includes('/search');

    return {
      isOnSearch,
    };
  }),
  branch<PropsFromCaller>(
    ({ saved }) => saved,
    graphql<PropsFromCaller>(SaveProductQuery, {
      options: ({ product, onError, onComplete }) => {
        const { product_id, product_type } = product || {};

        return {
          variables: {
            input: {
              product: {
                product_id,
                product_type,
              },
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: onComplete,
          onError,
        };
      },
      skip: ({ shouldQuery, product }) => {
        const { product_id, product_type } = product || {};

        return !shouldQuery || !product_id || !product_type;
      },
    }),
    graphql<PropsToComponent>(DeleteSavedProductQuery, {
      options: ({ product, onError, onComplete }) => {
        const { product_id, product_type } = product || {};

        return {
          variables: {
            input: {
              product: {
                product_id,
                product_type,
              },
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: () => {
            onComplete();
          },
          onError,
        };
      },
      skip: ({ shouldQuery, product }) => {
        const { product_id, product_type } = product || {};

        return !shouldQuery || !product_id || !product_type;
      },
    })
  )
)(BookmarkWithSaving);
