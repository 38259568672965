/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose, getContext, setDisplayName } from 'recompose';

import { Link } from '@coursera/cds-core';

import 'css!./__styles__/CardText';

const styles = {
  clampText: (maxLines: number) => css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  `,
};

export const CARD_TEXT_TYPE = {
  LABEL: 'label',
  SUBTITLE: 'subtitle',
  TITLE: 'title',
};

const CONFIG: {
  tag: Record<string, 'div' | 'h4' | 'label'>;
} = {
  tag: {
    label: 'label',
    subtitle: 'div',
    title: 'h4',
  },
};

type PropsFromCaller = {
  htmlAttributes?: Record<string, unknown>;
  maxLines?: number;
  className?: string;
  text?: string | JSX.Element;
  type?: string;
  shouldRenderDiv?: boolean;
  href?: string;
};

type Props = PropsFromCaller & {
  isThemeDark?: boolean;
};

function CardText({
  htmlAttributes,
  isThemeDark,
  maxLines = 3,
  className,
  text,
  type = CARD_TEXT_TYPE.LABEL,
  shouldRenderDiv,
  href,
}: Props) {
  const ElementTag: 'div' | 'h4' | 'label' = shouldRenderDiv ? 'div' : CONFIG.tag[type];

  return (
    <ElementTag
      className={classNames('rc-CardText', className)}
      data-type={type}
      data-dark-theme={isThemeDark}
      {...htmlAttributes}
      css={styles.clampText(maxLines)}
    >
      {href ? (
        <Link className="CardText-link" href={href} data-dark-theme={isThemeDark}>
          {text}
        </Link>
      ) : (
        text
      )}
    </ElementTag>
  );
}

export default compose<Props, PropsFromCaller>(
  setDisplayName('CardTextHOC'),
  getContext({ isThemeDark: PropTypes.bool })
)(CardText);
