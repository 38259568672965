import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import * as userLib from 'js/lib/user';

import enterpriseProgramsV1ResourceGetQuery from 'bundles/account-profile/common/eventing/queries/EnterpriseProgramsV1ResourceGet.graphql';
import programSwitcherSelectionsV1ResourceGetQuery from 'bundles/account-profile/common/eventing/queries/ProgramSwitcherSelectionsV1ResourceGet.graphql';
import type {
  EnterpriseProgramsV1ResourceGetQuery,
  EnterpriseProgramsV1ResourceGetQueryVariables,
} from 'bundles/account-profile/common/eventing/queries/__generated__/EnterpriseProgramsV1ResourceGet';
import type {
  ProgramSwitcherSelectionsV1ResourceGetQuery,
  ProgramSwitcherSelectionsV1ResourceGetQueryVariables,
} from 'bundles/account-profile/common/eventing/queries/__generated__/ProgramSwitcherSelectionsV1ResourceGet';
import type { SharedEventingV3Data } from 'bundles/account-profile/components/recruiting-profile-viewer/eventing/types';

type EnterpriseProperty = SharedEventingV3Data['enterprise'];

export const useEnterpriseProperties = (): EnterpriseProperty | null => {
  const user = userLib.isAuthenticatedUser() ? userLib.get() : null;

  /**
   * This query should be "free" in the future in many situations as
   * the query is a small subset of the required data for the organization
   * switcher component in the header.
   *
   * At the time of writing the org switcher component uses the assembler context so
   * the cache will not work yet.
   */
  const { data: programSwitcherData } = useQuery<
    ProgramSwitcherSelectionsV1ResourceGetQuery,
    ProgramSwitcherSelectionsV1ResourceGetQueryVariables
  >(programSwitcherSelectionsV1ResourceGetQuery, {
    variables: { getId: String(user?.id) },
    skip: !user,
    ssr: true,
    errorPolicy: 'all', // Dont want to block render in case of an error.
    context: { clientName: 'gatewayGql' },

    onError: (errorObj) => {
      Sentry.captureException(errorObj, {
        extra: { message: 'Error fetching program switcher in useEnterpriseProperties.' },
      });
    },
  });

  const programId = programSwitcherData?.NaptimeQueries.ProgramSwitcherSelectionsV1Resource.get?.programId;

  const { data: enterpriseProgramData } = useQuery<
    EnterpriseProgramsV1ResourceGetQuery,
    EnterpriseProgramsV1ResourceGetQueryVariables
  >(enterpriseProgramsV1ResourceGetQuery, {
    variables: { getId: programId ?? '' },
    skip: !programId,
    ssr: true,
    errorPolicy: 'all', // Dont want to block render in case of an error.
    context: { clientName: 'gatewayGql' },

    onError: (errorObj) => {
      Sentry.captureException(errorObj, {
        extra: { message: 'Error fetching enterprise programs in useEnterpriseProperties.' },
      });
    },
  });

  return {
    organizationSlug: enterpriseProgramData?.NaptimeQueries.EnterpriseProgramsV1Resource.get?.thirdPartyOrg?.slug,
    programSlug: enterpriseProgramData?.NaptimeQueries.EnterpriseProgramsV1Resource.get?.metadata.slug,
  };
};
