import qs from 'qs';

export type SalesforceCommunityPageType = 'TextPost' | 'QuestionPost';

export type SalesforceLanguageCode = 'en_US' | 'ar' | 'es' | 'in'; // Indonesian language code is now 'id' but SF is still using the old code 'in'

// eslint-disable-next-line no-restricted-syntax
const salesforceBaseUrl = 'https://www.coursera.support/s/';
const communityBaseUrl = salesforceBaseUrl; // there's a good chance this could be a different URL in the future, since they really wanted a separate domain for this
const communityFeedBaseUrl = communityBaseUrl + 'feed/';
const communityQuestionBaseUrl = communityBaseUrl + 'question/';
const communityGroupBaseUrl = communityBaseUrl + 'group/';
const communityHomeUrlSlug = 'community';
const communityFallbackUrlSlug = communityHomeUrlSlug; // leaving room to have a not found / sorry page that is different than the home page
const salesforceFallbackSlug = 'page-not-found';

export const HelpCenters = {
  ENTERPRISE: 'ENTERPRISE',
  LEARNER: 'LEARNER',
  PARTNER: 'PARTNER',
} as const;

export type HelpCenterValue = typeof HelpCenters[keyof typeof HelpCenters];

export type HelpCenterInfo = {
  type: HelpCenterValue;
  baseUrl: string;
  baseUrlNoSso: string;
  homeUrlSlug: string;
  webformUrlSlug: string;
};

// In the original plan, each help center was supposed to have a different URL domain / base URL.  That still might happen someday, so leaving it as a separate field per help center.
const baseUrl =
  // eslint-disable-next-line no-restricted-syntax
  'https://courseraservices.my.salesforce.com/services/auth/sso/Coursera_Account_SSO?community=https%3A%2F%2Fstudentserviceportal.force.com&startURL=%2Fs%2F';

// We can leave all SSO URLs below using the .force.com URL, since after SSO is done it will automatically go to the custom domain URL (e.g. www.coursera.support) -- this way when they change the custom domain we don't have to update the code
export const HelpCenterInfos: Record<HelpCenterValue, HelpCenterInfo> = {
  [HelpCenters.ENTERPRISE]: {
    type: HelpCenters.ENTERPRISE,
    baseUrl,
    baseUrlNoSso: salesforceBaseUrl, // use the vanity URL instead of the .force.com URL when not going through SSO
    homeUrlSlug: 'admin-help-center',
    webformUrlSlug: 'admin-help-center-contact-us',
  },
  [HelpCenters.LEARNER]: {
    type: HelpCenters.LEARNER,
    baseUrl,
    baseUrlNoSso: salesforceBaseUrl,
    homeUrlSlug: 'learner-help-center',
    webformUrlSlug: 'learner-help-center-contact-us',
  },
  [HelpCenters.PARTNER]: {
    type: HelpCenters.PARTNER,
    baseUrl,
    baseUrlNoSso: salesforceBaseUrl,
    homeUrlSlug: 'educator-resource-center',
    webformUrlSlug: 'educator-resource-center-contact-us',
  },
} as const;

// If the user is authenticated on coursera.org, we pass them through Salesforce SSO so that they stay logged in on the Salesforce support side
// If not currently authenticated on coursera.org, we don't want to try to authenticate them through SSO since they may be seeking support for password or registration issues
// We allow an override to avoid the SSO URL (forceBypassSso), which was created to allow the redirected links to be used between SF articles when you are already within SF
export const getSalesforceBaseUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  forceBypassSso = false
): string => {
  if (forceBypassSso || (!isAuthenticated && helpCenter === HelpCenters.LEARNER)) {
    return String(HelpCenterInfos.LEARNER.baseUrlNoSso);
  }
  return HelpCenterInfos[helpCenter].baseUrl; // for enterprise/partner we always want the user to be authenticated
};

/**
 * WARNING - URL returned can depend on whether user is authenticated.  Do not call this in module scope (i.e. do not call this from the top of your code file).
 * Builds a URL to a page within a Salesforce help center
 * @param helpCenter e.g. HelpCenters.LEARNER
 * @param isAuthenticated Whether the current user is authenticated e.g. user.get().authenticated (you can always pass 'true' if linking to partner/educator help or if the current page always requires auth)
 * @param sfLanguageCode Pass an empty string to use Salesforce's logic to determine which language to use (this will be the language of the last SF page visited, or else it follows the user's Coursera preference)
 * @param urlPathExcludingBase e.g. Pass 'learner-help-center-all-topics' to build a link to /s/learner-help-center-all-topics
 * @param queryParams Optional. e.g. Pass { topic: 'my-topic' } to build a link to https://<url>?topic=my-topic
 * @param forceBypassSso Optional. If true, links directly to the article with a non-SSO link.  Do not use this unless you know what you're doing.
 * @returns Typically returns a link to the SF page via SSO i.e. https://courseraservices.my.salesforce.com/services/auth/sso/Coursera_Account_SSO?community=https%3A%2F%2Fstudentserviceportal.force.com&startURL=%2Fs%2F<encoded path>, or if you pass isAuthenticated=false and helpCenter=HelpCenters.LEARNER it will link directly to the page without SSO i.e. https://www.coursera.support/s/<path>
 */
export const buildSalesforceUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  sfLanguageCode: SalesforceLanguageCode | '',
  urlPathExcludingBase: string,
  queryParams: Record<string, string> = {},
  forceBypassSso = false
): string => {
  const sfBaseUrl = getSalesforceBaseUrl(helpCenter, isAuthenticated, forceBypassSso); // in Salesforce, the base URL is the same for all articles (unlike Zendesk where we have 3 different URL domains for the 3 help centers learner, partner, business)
  const sfQueryParams = { ...queryParams };
  if (sfLanguageCode) {
    // We don't need to worry about whether this page exists in that language in Salesforce.  SF will automatically fall back to English if it needs to.
    // If language code is supplied as an empty string, we want to omit the SF language URL param entirely so it uses the SF language logic
    sfQueryParams.language = sfLanguageCode;
  }

  let endOfUrl = urlPathExcludingBase;
  if (Object.keys(sfQueryParams).length > 0) {
    endOfUrl += '?' + qs.stringify(sfQueryParams);
  }

  if (sfBaseUrl.indexOf('?') > 0) {
    // The article URL is going into a URL param value, e.g. ?startURL=<desired page URL>.  We need to escape the value of that param.
    return sfBaseUrl + encodeURIComponent(endOfUrl);
  }
  return sfBaseUrl + endOfUrl;
};

// Disable the warning about using buildSalesforceUrl() for the next few functions
/* eslint-disable no-restricted-syntax */

/**
 * WARNING - URL returned can depend on whether user is authenticated.  Do not call this in module scope (i.e. do not call this from the top of your code file).
 * Builds a Salesforce help center article URL.  Equivalent to calling buildSalesforceUrl and passing urlPathExcludingBase='article/<slug>'
 * @param helpCenter e.g. HelpCenters.LEARNER
 * @param isAuthenticated Whether the current user is authenticated e.g. user.get().authenticated (you can always pass 'true' if linking to partner/educator help or if the current page always requires auth)
 * @param sfLanguageCode Pass an empty string to use Salesforce's logic to determine which language to use (this will be the language of the last SF page visited, or else it follows the user's Coursera preference)
 * @param salesforceArticleSlug e.g. 'How-to-reset-your-password'
 * @param forceBypassSso Optional. If true, links directly to the article with a non-SSO link.  Do not use this unless you know what you're doing.
 * @returns Typically returns a link to the SF article via SSO https://courseraservices.my.salesforce.com/services/auth/sso/Coursera_Account_SSO?community=https%3A%2F%2Fstudentserviceportal.force.com&startURL=%2Fs%2Farticle%2F<slug>, or if you pass isAuthenticated=false and helpCenter=HelpCenters.LEARNER it will link directly to the page without SSO i.e. https://www.coursera.support/s/article/<slug>
 */
export const buildSalesforceArticleUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  sfLanguageCode: SalesforceLanguageCode | '' = '',
  salesforceArticleSlug: string,
  forceBypassSso = false
): string =>
  buildSalesforceUrl(
    helpCenter,
    isAuthenticated,
    sfLanguageCode,
    'article/' + salesforceArticleSlug,
    {},
    forceBypassSso
  );

export const buildSalesforceFallbackUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  sfLanguageCode: SalesforceLanguageCode | '' = '',
  sourceUrl?: string,
  forceBypassSso = false
): string =>
  buildSalesforceUrl(
    helpCenter,
    isAuthenticated,
    sfLanguageCode,
    salesforceFallbackSlug,
    /* queryParams : */ sourceUrl ? { sourceUrl } : undefined,
    forceBypassSso
  );

export const buildSalesforceHelpCenterHomeUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  sfLanguageCode: SalesforceLanguageCode | '' = '',
  forceBypassSso = false
): string =>
  buildSalesforceUrl(
    helpCenter,
    isAuthenticated,
    sfLanguageCode,
    HelpCenterInfos[helpCenter].homeUrlSlug,
    {},
    forceBypassSso
  );

export const buildSalesforceWebformUrl = (
  helpCenter: HelpCenterValue,
  isAuthenticated: boolean,
  sfLanguageCode: SalesforceLanguageCode | '' = '',
  forceBypassSso = false
): string =>
  buildSalesforceUrl(
    helpCenter,
    isAuthenticated,
    sfLanguageCode,
    HelpCenterInfos[helpCenter].webformUrlSlug,
    {},
    forceBypassSso
  );

/* eslint-enable no-restricted-syntax */

// For community URLs, we're not considering isAuthenticated because they can always optionally log in (if they want to comment etc). If it's a private community, SF will require login on its end anyway.
export const buildCommunityFeedUrl = (sfPostId: string, sfCommunityPageType?: SalesforceCommunityPageType) =>
  (sfCommunityPageType === 'QuestionPost' ? communityQuestionBaseUrl : communityFeedBaseUrl) + sfPostId;

export const buildCommunityGroupUrl = (sfGroupId: string) => communityGroupBaseUrl + sfGroupId;

export const buildCommunityHomeUrl = () => communityBaseUrl + communityHomeUrlSlug; // using a function for future flexibility

export const buildCommunityFallbackUrl = () => communityBaseUrl + communityFallbackUrlSlug;
