/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { memo } from 'react';

import classNames from 'classnames';

import imgix from 'js/lib/imgix';

import { TextTruncate } from '@coursera/coursera-ui';

import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import type { DegreeCardDegreeProps } from 'bundles/browse/types/degree-list';
import { buildNewWindowOnClickViaUrl } from 'bundles/common/utils/newWindowUtils';
import { getContrastRatio } from 'bundles/coursera-ui/utils/colorUtils';
import Card from 'bundles/growth-ui/components/Card';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/browse';

const WCAG_NORMAL_AA = 4.5;

export const style = css`
  .rc-BrowseDegreeCard,
  &.rc-BrowseDegreeCard {
    width: 268px;
    height: 250px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    background-color: transparent;
    margin: auto;

    > div {
      background-color: white;
      width: 100%;
      height: 100%;
      position: relative;
    }

    @media (max-width: 1023px) {
      margin-bottom: 12px;
    }

    .degree-card-anchor-link {
      padding: 0;
      border-width: 0;
      background-color: transparent;
      width: unset;
      height: unset;
      display: unset;

      &:focus {
        outline-offset: 3px;
        outline: 5px solid #9ecaed;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .degree-icon {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .degree-card {
      border-left: none;
      border-right: none;
      height: 100%;
    }

    .card-icon {
      width: 100%;
      height: 90px;

      /* background-color: theme.palette.gray[300]; */
      background-color: '#E5E7E8';
    }

    .card-title {
      padding: 15px;
      width: 100%;
      display: block;
      text-align: left;

      h2 {
        /* theme.typography.h2 */
        font-family: 'Source Sans Pro', Arial, sans-serif;
        line-height: 22px;
        font-size: 16px;
      }

      .card-title-link {
        display: 'flex';
        -webkit-text-decoration: none;
        text-decoration: none;
      }
    }

    .degree-partner {
      font-size: 12px;
      display: block;
    }

    .degree-card-footer {
      height: 46px;
      width: 100%;
      position: absolute;
      bottom: 0;
      overflow: hidden;

      .footer-shape {
        height: 50px;
        width: 180%;
        left: -40%;
        bottom: -16px;
        position: absolute;

        &.right-shape {
          transform: rotateZ(-4deg);
          outline: 1px solid transparent;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }

        &.left-shape {
          opacity: 0.5;
          transform: rotateZ(4deg);
          outline: 1px solid transparent;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }
      }

      .degree-format {
        position: absolute;
        left: 14px;
        bottom: 6px;
        font-size: 12px;
        font-weight: bold;
        color: white;
        line-height: initial;

        &.dark {
          color: #1f1f1f;
        }
      }

      .degree-partner-wrapper {
        position: absolute;
        right: 5px;
        bottom: 3px;
        height: 36px;
        max-width: 70px;

        .degree-partner-image {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          vertical-align: top;
        }
      }
    }
  }
`;

type Props = {
  degree: DegreeCardDegreeProps;
  forceOpenInNewTab?: boolean;
  entityIndex: number;
  showBorder?: boolean;
  shouldLazyLoad?: boolean;
  footerLabel?: string | null;
};

export const BrowseDegreeCardContent = memo((props: Props) => {
  const { degree, forceOpenInNewTab, entityIndex, showBorder = true, shouldLazyLoad, footerLabel } = props;
  const { name, marketingName, bannerImage, partner, partners, type, slug } = degree;
  const firstPartner = partners?.elements[0] || partner;
  const partnerLogo = firstPartner?.squareLogo;
  const partnerName = firstPartner?.name;
  const isDegree = type === PRODUCT_TYPE_NAMES.DEGREE;
  const builtUrl = props?.degree?.link ?? (isDegree ? '/degrees/' : '/mastertrack/') + `${slug}`;
  const entityType = isDegree ? PRODUCT_TYPE_NAMES.DEGREE.toLowerCase() : PRODUCT_TYPE_NAMES.MASTERTRACK.toLowerCase();

  const isBannerImageGif = bannerImage && bannerImage.substring(bannerImage.length - 3).toLowerCase() === 'gif';
  const isLogoImageGif = partnerLogo && partnerLogo.substring(partnerLogo.length - 3).toLowerCase() === 'gif';
  const gifCompressionOptions = { format: 'jpg' };

  const optimizedBannerImageUrl = imgix.processImage(bannerImage, {
    width: 270,
    height: 90,
    fit: 'crop',
    auto: 'compress',
    ...(isBannerImageGif ? gifCompressionOptions : {}),
  });
  const optimizedSquareLogoUrl = imgix.processImage(partnerLogo, {
    width: 42,
    fit: 'crop',
    auto: 'compress',
    ...(isLogoImageGif ? gifCompressionOptions : {}),
  });

  const footerStyle = { backgroundColor: firstPartner?.primaryColor || '#003584' }; // theme.palette.blue[800]
  // A11Y: use text color with highest contrast ratio.
  const isLightColor = getContrastRatio('#ffffff', footerStyle.backgroundColor) < WCAG_NORMAL_AA;

  return (
    <TrackedLink2
      trackingName="degree_card"
      data={{ name, id: slug, entityType, entityIndex }}
      className="nostyle degree-card-anchor-link"
      href={builtUrl}
      withVisibilityTracking={true}
      target={forceOpenInNewTab ? '_blank' : undefined}
      rel={forceOpenInNewTab ? 'noopener noreferrer' : undefined}
      // This fixes a strange gotcha that the first degree card among hidden degree cards generates
      // a visibility tracking impression. Also this is consistent with other cards in non-degree
      // collections  where requireFullyVisible is true and makes our impression signals "clearer",
      // reducing false positive visibility tracking
      requireFullyVisible={true}
      atMostOnce={true}
      tabIndex={0}
      onClick={forceOpenInNewTab ? buildNewWindowOnClickViaUrl({ href: builtUrl }) : undefined}
    >
      <Card isInteractive={true} isThemeDark={false} showBorder={showBorder} className="vertical-box degree-card">
        <div className="card-icon">
          {shouldLazyLoad ? (
            <LazyImgix
              className="degree-icon"
              src={bannerImage || ''}
              maxWidth={270}
              maxHeight={90}
              alt={`${partnerName}`}
              imgParams={{ ...(isBannerImageGif ? gifCompressionOptions : {}) }}
            />
          ) : (
            <img className="degree-icon" src={optimizedBannerImageUrl} alt={`${partnerName}`} width={270} />
          )}
        </div>
        <div className="card-title headline-2-text">
          <p data-e2e="degree-card-name">
            <a className="card-title-link" href={builtUrl}>
              <TextTruncate line={3} text={isDegree ? marketingName : name} />
            </a>
          </p>
          {!!partnerName && (
            <span className="degree-partner headline-2-text" aria-hidden={true}>
              from {partnerName?.startsWith('University') ? ' the ' : ' '}
              {` ${partnerName}`}
            </span>
          )}
        </div>
        <div className="degree-card-footer">
          <div className="footer-shape left-shape" style={footerStyle} />
          <div className="footer-shape right-shape" style={footerStyle} />
          <span className={classNames('degree-format', { dark: isLightColor })} style={footerStyle}>
            {footerLabel ?? _t('100% ONLINE')}
          </span>
          {firstPartner?.squareLogo && (
            <div className="degree-partner-wrapper vertical-box align-items-absolute-center" aria-hidden={true}>
              {shouldLazyLoad ? (
                <LazyImgix
                  className="degree-partner-image"
                  src={partnerLogo || ''}
                  maxWidth={36}
                  maxHeight={36}
                  alt={`${partnerName}`}
                  imgParams={{ ...(isLogoImageGif ? gifCompressionOptions : {}) }}
                />
              ) : (
                <img className="degree-partner-image" src={optimizedSquareLogoUrl} alt={`${partnerName}`} width={42} />
              )}
            </div>
          )}
        </div>
      </Card>
    </TrackedLink2>
  );
});

// this is intended for performance optimization where we combine the css at the top level component
export const BrowseDegreeCardBare = memo((props: Props) => {
  return (
    <div className="rc-BrowseDegreeCard" data-e2e="degree-card">
      <BrowseDegreeCardContent {...props} />
    </div>
  );
});

function BrowseDegreeCard(props: Props) {
  return (
    <div className="rc-BrowseDegreeCard" data-e2e="degree-card" css={style}>
      <BrowseDegreeCardContent {...props} />
    </div>
  );
}

export default memo(BrowseDegreeCard);
