import * as React from 'react';
import { useContext } from 'react';

import { compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import type { InjectedRouter } from 'js/lib/connectToRouter';

import { IS_FOR_CREDIT, IS_PART_OF_COURSERA_PLUS } from 'bundles/search-common/constants/filter';

type InputProps = { children: React.ReactNode };
type Props = InjectedRouter & InputProps;

const RouterContext = React.createContext({});

export const useHasCourseraPlusSearchFilterParam = () => {
  const router = useContext(RouterContext) as InjectedRouter;
  return router?.location?.query?.[IS_PART_OF_COURSERA_PLUS] === 'true';
};

export const useHasForCreditSearchFilterParam = () => {
  const router = useContext(RouterContext) as InjectedRouter;
  return router?.location?.query?.[IS_FOR_CREDIT] === 'true';
};

const RouterProvider = ({ children, ...rest }: Props) => {
  return <RouterContext.Provider value={rest}>{children}</RouterContext.Provider>;
};

export default compose<Props, InputProps>(connectToRouter((props) => props))(RouterProvider);
