/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToStores from 'js/lib/connectToStores';
import redirect from 'js/lib/coursera.redirect';
import fullStory from 'js/lib/fullStoryUtils';
import user from 'js/lib/user';

import type { Theme } from '@coursera/cds-core';
import { withTheme } from '@coursera/cds-core';

import CTABanner from 'bundles/browse/components/CTABanner';
import { style as CollectionStyle } from 'bundles/browse/components/Collection';
import OfferExplanationTableStyle from 'bundles/browse/components/OfferExplanationTableStyle';
import { style as BrowseDegreeCardStyle } from 'bundles/browse/components/ProductCard/BrowseDegreeCard';
import { legacyStyle as BrowseProductCardStyle } from 'bundles/browse/components/ProductCard/BrowseProductCard';
import { style as TopicAndSkillsStyle } from 'bundles/browse/components/TopicAndSkills';
import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';
import { style as CardSectionStyle } from 'bundles/growth-ui/components/CardSection';
import { style as PartnerLogoStyle } from 'bundles/growth-ui/components/PartnerLogo';
import { style as TagBadgeStyle } from 'bundles/growth-ui/components/TagBadge';
import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { style as MobileFeaturedTopicsStyle } from 'bundles/page-header/components/mobile/MobileFeaturedTopics';
import { RedirectError } from 'bundles/ssr/lib/errors/directives';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';
import inServerContext from 'bundles/ssr/util/inServerContext';

import 'css!bundles/browse/styl/index';

const PAGE_HEADER_HEIGHT = 60;

const styles = {
  browseApp: (theme: Theme) => css`
    .c-phoenix-template-header,
    .c-phoenix-template-footer,
    .c-error-body,
    .c-cs-layout {
      max-width: 960px;
      margin: 0 auto;
    }

    .browse-children-wrapper {
      min-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
      background-color: ${theme.palette.gray[100]};
    }
    ${MobileFeaturedTopicsStyle}
    ${TopicAndSkillsStyle}
    ${CollectionStyle}
    ${BrowseDegreeCardStyle}
    ${BrowseProductCardStyle}
    ${CardSectionStyle}
    ${PartnerLogoStyle}
    ${TagBadgeStyle}
    ${OfferExplanationTableStyle}
  `,
};

type InputProps = {
  children?: JSX.Element;
};

type Props = InputProps & {
  requestCountryCode?: string;
  csrfToken?: string;
  theme: Theme;
};

type Context = {
  router: InjectedRouter;
};

export class BrowseApp extends React.PureComponent<Props> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    csrfToken: PropTypes.string,
    requestCountryCode: PropTypes.string,
  };

  constructor(props: Props, context: Context) {
    super(props);

    // temporary redirect this slug to /browse
    if (context.router.params && context.router.params.domainId === 'degrees-and-professional-certificates') {
      const redirectUrl = '/browse';
      if (inServerContext) {
        throw new RedirectError(302, redirectUrl);
      } else {
        redirect.setLocation(redirectUrl);
      }
    }
  }

  getChildContext() {
    const { csrfToken, requestCountryCode } = this.props;
    return { csrfToken, requestCountryCode };
  }

  componentDidMount() {
    if (GrowthDiscoveryExperiments.get('fullStoryBrowseEnabled')) {
      fullStory.init();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className="rc-BrowseApp">
        <PageHeader showGDPRBanner showEnterpriseReturnToProgramBanner showLanguagesDropdown />
        <div className="browse-children-wrapper">
          {children}
          {/* Added a wrapper to the CTA so we can track the element visibility on the page in SSR and CSR */}
          {!user.isAuthenticatedUser() && (
            <div className="browse-cta-wrapper">
              <CTABanner />
            </div>
          )}
        </div>
        <PageFooter />
      </div>
    );
  }
}

export const browseThemeProvider =
  <T extends {}>(Component: React.ComponentType<T & any>) =>
  (props: T) =>
    (
      <div className="rc-BrowseThemeProvider" css={styles.browseApp}>
        <Component {...props} />
      </div>
    );

export default compose<Props, InputProps>(
  connectToStores<InputProps, Omit<InputProps, 'userAgent'>>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  })),
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'browse',
      page: 'browse',
    },
  })),
  withTheme,
  browseThemeProvider,
  withGDPR,
  withCCPA
)(BrowseApp);
