import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('inputAdornment', [
  'root',
  'top',
  'center',
  'hiddenLabel',
  'filled',
  'standard',
  'valid',
  'invalid',
]);

const getInputAdornmentCss = css`
  margin: 0;
  height: unset;
  max-height: unset;
  align-self: stretch;
  padding: 0 var(--cds-spacing-200);
  color: var(--cds-color-neutral-primary);
  border-radius: var(--cds-border-radius-50);

  ${typography.bodySecondary}

  &.${classes.filled}:not(.${classes.hiddenLabel}) {
    margin: 0;
  }

  &.${classes.top} {
    top: 0;
    position: absolute;
    right: 0;
    align-items: flex-start;
    padding-top: var(--cds-spacing-150);
  }

  &.${classes.center} {
    align-items: center;
  }

  &.${classes.filled} {
    background: var(--cds-color-neutral-background-primary-weak);
  }

  &.${classes.standard} {
    background: unset;
  }

  &.${classes.valid} {
    color: var(--cds-color-feedback-success);
    padding-left: var(--cds-spacing-150);
    padding-right: var(--cds-spacing-150);
  }

  &.${classes.invalid} {
    color: var(--cds-color-feedback-error);
    padding-left: var(--cds-spacing-150);
    padding-right: var(--cds-spacing-150);
  }
`;

export default getInputAdornmentCss;
