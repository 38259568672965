/** @jsx jsx */
import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import { useEffect, useState } from 'react';

import { AiCoach_Action } from '__generated__/graphql-types';

import localStorageEx from 'bundles/common/utils/localStorageEx';

// localStorage key generator for a feature
// note: does not check for dupes, assumes feature name is unique
const getFeatureKey = (feature: string) => {
  return `AiCoach_NewFeatureInteracted_${feature}`;
};

/**
 * Allow list of new coach features to enable styling for.
 * Newer features are added to the end.
 *
 * Cleanup: when removing a usage of `useNewFeature` hook, the feature must
 * also be removed from this list.
 */
export const NEW_FEATURES = [AiCoach_Action.Recap, 'ExternalCoachLauncher'];

/**
 * Max number of new features to show at a time.
 * In the future we may have lots of new features that a learner
 * might see at once, this is used to limit that overwhelming UX.
 */
const MAX_NEW_FEATURES = 2;

const isNewFeatureInteracted = (feature: string): boolean => {
  return localStorageEx.getItem<boolean>(getFeatureKey(feature), (value) => value === 'true', false);
};

/**
 * Whether `feature` is considered "new"
 * i.e.
 *   whether feature belongs to latest subset of the "new features" list,
 *   and whether user already interacted with it
 */
const isNewCoachFeature = (feature: string): boolean => {
  return NEW_FEATURES.slice(-MAX_NEW_FEATURES).includes(feature) ? !isNewFeatureInteracted(feature) : false;
};

/**
 * Call this function when a user interacts with your `feature` (e.g. button click)
 */
export const setNewFeatureInteracted = (feature: string): void => {
  localStorageEx.setItem(getFeatureKey(feature), true, () => 'true');
};

const styles = (animation: Props['animation']) => css`
  position: relative;

  ${animation === 'bounce' &&
  `
    animation: bounce 1s linear 0.5s 2;
  `}

  /**
   * Bounce animation for new Coach features.
   * Restrict usage to CTAs only, like buttons e.g. <ExternalCoachLauncher/>
   */
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-8px);
    }

    60% {
      transform: translateY(-4px);
    }
  }

  /** 
   * Pulsating animation around any new Coach feature,
   * Can be used on buttons or DIVs, takes the shape of the container.
   */
  @keyframes pulsate {
    0% {
      box-shadow: 0 0 0 0 rgb(88 120 243 / 100%);
    }

    70% {
      box-shadow: 0 0 0 10px rgb(88 120 243 / 0%);
    }

    100% {
      box-shadow: 0 0 0 48px rgb(88 120 243 / 0%);
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: inherit;
    z-index: 999;

    ${animation === 'pulsate' && `animation: 2s pulsate 1s 3;`}
  }
`;

type Props = {
  feature: string;
  animation?: 'pulsate' | 'bounce';
};

export const useNewFeatureStyles = ({ feature, animation = 'pulsate' }: Props) => {
  const [newFeatureCSS, setNewFeatureCSS] = useState<SerializedStyles | null>(null);

  useEffect(() => {
    if (isNewCoachFeature(feature)) {
      setNewFeatureCSS(styles(animation));
    }
  }, [feature, animation]);

  const newFeatureInteractedCallback = () => {
    setNewFeatureCSS(null);
    setNewFeatureInteracted(feature);
  };

  return { newFeatureInteractedCallback, newFeatureCSS };
};
