/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import cx from 'classnames';

import { IconButton } from '@coursera/cds-core';
import type { IconButtonProps } from '@coursera/cds-core';

type Props = {
  selected?: boolean;
} & IconButtonProps;

const styles = {
  coachIconButton: css`
    /* CDS overrides */
    --cds-border-radius-50: 50%;
    --cds-color-neutral-disabled: #dae1ed;
    --cds-color-interactive-primary-selected: #1e72eb;
    --cds-color-interactive-background-primary-hover-weak: #f0f6ff;
    --cds-color-interactive-background-primary-pressed-weak: #f0f6ff;
    --cds-color-interactive-stroke-primary-focus: #002761;
    --cds-color-interactive-stroke-primary-focus-invert: #e3eeff;

    box-shadow: none;

    &.selected {
      svg path {
        fill: var(--cds-color-interactive-primary-selected);
      }

      background: #f0f6ff;
    }

    &[aria-disabled='true'] {
      background-color: transparent;

      svg path {
        fill: var(--cds-color-neutral-disabled);
      }
    }
  `,
};

/**
 * Rebrand CoachIconButton
 * Simple wrapper around CDS IconButton to provide Coach-specific styles.
 */
const CoachIconButton = (props: Props): React.ReactElement => {
  const { size = 'medium', selected = false, disabled, ...rest } = props;

  // use ariaDisabled when button is disabled but there is a tooltip
  // this allows users to focus/hover on the button to see the tooltip
  // while still keeping the underlying disabled styling for CDS IconButton
  const shouldAriaDisableOnly = disabled && !!rest.tooltip;

  return (
    <IconButton
      size={size}
      variant="ghost"
      css={styles.coachIconButton}
      className={cx({ selected })}
      {...rest}
      aria-disabled={shouldAriaDisableOnly}
      disabled={!shouldAriaDisableOnly && disabled}
    />
  );
};

export default CoachIconButton;
