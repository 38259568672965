/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, useTheme } from '@coursera/cds-core';

import getAccessibleTextColor from 'bundles/program-common/utils/getAccessibleTextColor';

type ThirdPartyOrganization = {
  name?: string | null;
  primaryColor?: string | null;
  iconColor?: string | null;
};

type DefaultOrganizationProps = {
  size?: number;
  thirdPartyOrganization: ThirdPartyOrganization;
};

const DefaultOrganizationLogo: React.FC<DefaultOrganizationProps> = ({
  size = 48,
  thirdPartyOrganization: { name, primaryColor, iconColor },
}) => {
  const theme = useTheme();
  const backgroundColor = iconColor || primaryColor || theme.palette.blue[900];
  const darkTextColor = theme.palette.black[500];
  const lightTextColor = theme.palette.white;
  const accessibleTextColor = getAccessibleTextColor(backgroundColor, darkTextColor, lightTextColor);
  const typographyColor = accessibleTextColor === darkTextColor ? 'body' : 'invertBody';

  const styles = {
    iconBackground: css`
      width: ${size}px;
      height: ${size}px;
      background-color: ${backgroundColor};
      border-radius: 50%;
      display: flex;
      align-items: center;
      text-align: center;
    `,
    iconText: css`
      width: 100%;

      /* Need to use an ID on the Typography component to override the CDS font size. */
      &#defaultOrganizationLogoIconText {
        font-size: ${size * 0.65}px;
      }
    `,
  };

  return (
    <div css={styles.iconBackground} aria-label={name || ''}>
      <Typography
        color={typographyColor}
        variant="h1semibold"
        component="span"
        css={styles.iconText}
        id="defaultOrganizationLogoIconText"
        data-testid="defaultOrganizationLogoIconText"
      >
        {name?.charAt(0).toLocaleUpperCase()}
      </Typography>
    </div>
  );
};

export default DefaultOrganizationLogo;
