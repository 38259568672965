import React from 'react';

import clsx from 'clsx';

import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';

import type { BaseFormControlProps } from '@core/forms/FormControl';
import { FormControl } from '@core/forms/FormControl';
import type {
  OptionalInputLabelProps,
  RequireIndicatorProps,
} from '@core/forms/FormLabel';
import { FormLabel } from '@core/forms/FormLabel';
import { FormSupportText } from '@core/forms/FormSupportText';
import { FormValidationLabel } from '@core/forms/FormValidationLabel';
import i18nMessages from '@core/forms/i18n';
import { classes as inputClasses } from '@core/forms/Input/getInputCss';
import type { SelectProps } from '@core/forms/Select';
import { Select, SelectOption } from '@core/forms/Select';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

import getSelectFieldCss, { classes } from './getSelectFieldCss';

export type Props = {
  /**
   * Defines placeholder.
   */
  placeholder?: string;

  /**
   * Label to describe validation state of the SelectField.
   */
  validationLabel?: string;
  /**
   * When set, the user can not edit the control.
   * The value however, can still be changed via script.
   * @default false
   */
  readOnly?: boolean;
} & OptionalInputLabelProps &
  RequireIndicatorProps &
  Pick<
    SelectProps,
    | 'onChange'
    | 'onClose'
    | 'onOpen'
    | 'inputProps'
    | 'name'
    | 'value'
    | 'defaultValue'
    | 'open'
    | 'children'
    | 'autoFocus'
  > &
  BaseFormControlProps;

/**
 * Renders select field with label, support text and validation styles/label
 *
 * See [Props](__storybookUrl__/components-inputs-selectfield--default#props)
 */
const SelectField = React.forwardRef(function SelectField(
  props: Props,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement<Props> {
  const {
    name,
    label,
    renderLabel,
    children,
    placeholder,
    value,
    onChange,
    onClose,
    onOpen,
    invert,
    className,
    disabled,
    fullWidth,
    optional,
    validationStatus,
    validationLabel,
    supportText,
    defaultValue,
    inputProps,
    readOnly,
    necessityIndicator,
    'aria-label': ariaLabel = inputProps?.['aria-label'],
    'aria-labelledby': ariaLabelledBy = inputProps?.['aria-labelledby'],
    ...rest
  } = props;

  const id = useId(props.id);
  const valueId = `${id}-value`;
  const labelId = `${id}-label`;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const defaultLabelledById = ariaLabelledByForMultipleLabels(labelId, valueId);
  const hideLabel = !label && !renderLabel;

  // If the label is not present, don't add the default labelledby ID because the element isn't rendered.
  const ariaLabelledByValue =
    ariaLabelledBy ?? (hideLabel ? undefined : defaultLabelledById);

  /* Append the required text to the aria-label if a label is not defined.
   * This ensures the text is still announced even if the label is omitted and the FormLabel component doesn't render.
   */
  const ariaLabelExtended =
    ariaLabel &&
    [ariaLabel, optional ? '' : stringFormatter.format('required')]
      .filter((str) => str)
      .join(', ');

  return (
    <FormControl
      ref={ref}
      className={className}
      css={getSelectFieldCss}
      disabled={disabled}
      fullWidth={fullWidth}
      id={id}
      invert={invert}
      optional={optional}
      supportText={supportText}
      validationStatus={validationStatus}
    >
      {!hideLabel && (
        <FormLabel
          announceRequiredIndicator
          necessityIndicator={
            !necessityIndicator && optional ? 'text' : necessityIndicator
          }
          renderLabel={renderLabel}
        >
          {label}
        </FormLabel>
      )}

      {supportText && (
        <FormSupportText className={classes.formSupportText}>
          {supportText}
        </FormSupportText>
      )}

      {validationStatus && validationLabel && (
        <FormValidationLabel
          className={classes.formValidationLabel}
          label={validationLabel}
        />
      )}

      <Select
        displayEmpty
        SelectDisplayProps={{
          id: valueId,
          'aria-label': label ? ariaLabel : ariaLabelExtended,
          'aria-labelledby': ariaLabelledByValue,
        }}
        className={clsx(classes.root, {
          [classes.readOnly]: readOnly,
        })}
        defaultValue={defaultValue}
        inputProps={{ ...inputProps, readOnly }}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        {...rest}
      >
        {Array.isArray(children)
          ? [
              <SelectOption key="placeholder" disabled hidden value="">
                <span className={inputClasses.placeholder}>{placeholder}</span>
              </SelectOption>,
              ...children,
            ]
          : children}
      </Select>
    </FormControl>
  );
});

export default SelectField;
