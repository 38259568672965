import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

const RecentlyUpdatedSlugs = [
  'google-data-analytics',
  'google-digital-marketing-ecommerce',
  'google-it-automation',
  'google-it-support',
  'google-project-management',
  'google-ux-design',
  'google-business-intelligence',
  'google-cybersecurity',
];

const JulyAICampaignRecentlyUpdatedSlugs = [
  'microsoft-power-bi-data-analyst',
  'microsoft-cybersecurity-analyst',
  'ibm-data-engineer',
  'ibm-data-science',
  'facebook-social-media-marketing',
  'facebook-marketing-analytics',
  'ibm-full-stack-cloud-developer',
  'ibm-data-analyst',
];

export const isPartOfRecentlyUpdatedBadgeExperiment = () => GrowthDiscoveryEpicClient.get('showRecentlyUpdatedBadge');

export const shouldShowRecentlyUpdatedBadge = (slug: string) => {
  return (
    JulyAICampaignRecentlyUpdatedSlugs.includes(slug) &&
    GrowthDiscoveryEpicClient.get('showJulyAICampaignBadgeAndBanner')
  );
};
