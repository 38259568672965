import gql from 'graphql-tag';

/* eslint-disable graphql/template-strings */
export const FindSavedProductsByListOfProductsQuery = gql`
  query FindSavedProductsByListOfProductsQuery($input: FindSavedProductsByListOfProductsQueryVariables!) {
    FindSavedProductsByListOfProductsResponse(input: $input)
      @rest(
        type: "FindSavedProductsByListOfProductsResponse"
        path: "grpc/discoverybehaviors/savedproducts/v1beta1/SavedProductsAPI/FindSavedProductsByListOfProducts"
        method: "POST"
      ) {
      savedProduct
    }
  }
`;
