import React from 'react';

import { css } from '@emotion/react';

import clsx from 'clsx';

import type { IconProps } from '@coursera/cds-common';
import { useId } from '@coursera/cds-common';

import type { ButtonProps, default as Button } from '@core/Button';
import Hidden from '@core/Hidden';
import type { ActionsProps } from '@core/notifications/Actions';
import { Actions } from '@core/notifications/Actions';
import type { DismissActionProps } from '@core/notifications/DismissAction';
import { DismissAction } from '@core/notifications/DismissAction';
import { SeverityIcon } from '@core/notifications/SeverityIcon';
import type { TitleProps } from '@core/notifications/Title';
import { Title } from '@core/notifications/Title';
import type { NotificationSeverity } from '@core/notifications/types';
import useNotificationAriaLabel from '@core/notifications/useNotificationAriaLabel';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import { getLocalNotificationCss, classes } from './getLocalNotificationCss';

export type Props = {
  titleProps?: TitleProps;
  /**
   * Severity type of Notification
   */
  severity: NotificationSeverity;
  /**
   * Notification content
   */
  children: React.ReactChild;
  /**
   * Inline action
   */
  inlineAction?: React.ReactElement<ButtonProps, typeof Button>;
  /**
   * Callback fired when dismiss button is clicked.
   * Dismiss button only appears if either onDismiss function or
   * onClick function within dismissActionProps is provided.
   */
  onDismiss?: () => void;
  /**
   * Provide any additional props to the dismiss action.
   * Use it to adjust the text or provide an `aria-*` property to the dismiss action.
   */
  dismissActionProps?: DismissActionProps;

  severityIcon?: React.ReactElement<IconProps>;
} & ActionsProps &
  React.ComponentPropsWithRef<'div'>;

const LocalNotification = React.forwardRef(function LocalNotification(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    id: idFromProps,
    className,
    titleProps,
    children,
    primaryAction,
    secondaryAction,
    actions,
    inlineAction,
    severity,
    onDismiss,
    dismissActionProps,
    severityIcon,
    ...rest
  } = props;

  const id = useId(idFromProps);
  const ariaLabel = useNotificationAriaLabel(severity, titleProps?.children);
  const titleId = titleProps?.children ? `${id}-title` : undefined;
  const contentId = `${id}-content`;

  const localNotificationCss = getLocalNotificationCss(props);
  const dismissActionHandler = onDismiss
    ? onDismiss
    : dismissActionProps?.onClick;

  return (
    <div
      ref={ref}
      aria-describedby={titleProps?.children ? contentId : undefined}
      aria-labelledby={titleProps?.children ? titleId : contentId}
      css={localNotificationCss}
      id={id}
      {...rest}
      className={clsx(className, {
        [classes.dismissible]: inlineAction || Boolean(dismissActionHandler),
        [classes.hasDismiss]: Boolean(dismissActionHandler),
      })}
    >
      <Hidden xsDown>
        {severityIcon ?? <SeverityIcon severity={severity} />}
      </Hidden>

      <div className={classes.content}>
        <div>
          <Title aria-label={ariaLabel} id={titleId} {...titleProps} />
          <Typography component="div" id={contentId} variant="bodyPrimary">
            {titleProps?.children === undefined && (
              <VisuallyHidden>{ariaLabel}</VisuallyHidden>
            )}
            {children}
          </Typography>

          {inlineAction && (
            <Hidden
              mdUp
              css={css`
                margin-left: -8px;
                margin-top: var(--cds-spacing-300);
              `}
            >
              {inlineAction}
            </Hidden>
          )}

          <Actions
            actions={actions}
            className={classes.actions}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
        </div>

        <div className={classes.inlineActionContainer}>
          <Hidden smUp>
            {severityIcon ?? <SeverityIcon severity={severity} />}
          </Hidden>

          {inlineAction &&
            React.cloneElement(inlineAction, {
              className: clsx(
                classes.inlineAction,
                inlineAction.props.className
              ),
            })}

          {dismissActionHandler && (
            <DismissAction
              {...dismissActionProps}
              onClick={dismissActionHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default LocalNotification;
