import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useRetracked } from 'js/lib/retracked';

import { Link } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { useEnterpriseProperties } from 'bundles/account-profile/common/eventing/useEnterpriseProperties';
import Notification from 'bundles/notification-center/components/Notification';
import type {
  DegreeApplicationDeadlineNotification as DegreeApplicationDeadlineNotificationType,
  Notification as NotificationType,
} from 'bundles/notification-center/types';

import _t from 'i18n!nls/notification-center';

type Props = {
  notification: DegreeApplicationDeadlineNotificationType;
  onClick: (notification: NotificationType) => void;
};

const DegreeApplicationDeadlineNotification = ({ notification, onClick }: Props) => {
  const trackComponent = useRetracked();
  const trackV3 = useTracker();
  const enterpriseProperties = useEnterpriseProperties();

  const { degreeName: programName, degreeSlug, universityName: partnerName } = notification.data;
  const slug = degreeSlug;
  const degreeUrl = `/degrees/${slug}`;
  const lengthOfTime =
    // eslint-disable-next-line no-restricted-syntax
    notification.messageType === 'DegreeDeadlineOneWeekAheadMessage' ? _t('one week') : _t('30 days');

  const trackingData = {
    notification: {
      templateId: notification.messageType,
      channelType: 'notification_center' as const,
    },
    product: {
      id: notification.data.degreeId,
      name: programName,
      slug,
      type: 'degree' as const,
    },
    enterprise: {
      organizationSlug: enterpriseProperties?.organizationSlug,
      programSlug: enterpriseProperties?.programSlug,
    },
  };

  const degreeLink = (
    <Link href={degreeUrl} variant="quiet">
      {_t('#{partnerName} #{programName}', { partnerName, programName })}
    </Link>
  );

  useEffect(() => {
    if (!notification.isRead) {
      trackComponent({
        action: 'view',
        trackingData: {
          degreeSlug: slug,
          notificationType: notification.messageType,
          isRead: false,
        },
        trackingName: 'degreeApplicationDeadline',
      });
      trackV3('view_notification', trackingData);
    }
    // only run once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Notification
      href={degreeUrl}
      notification={notification}
      onClick={(clickedNotification) => {
        onClick(clickedNotification);
        trackComponent({
          action: 'click',
          trackingData: {
            degreeSlug: slug,
            notificationType: clickedNotification.messageType,
          },
          trackingName: 'degreeApplicationDeadline',
        });
        trackV3('click_notification', trackingData);
      }}
    >
      <FormattedMessage
        message={_t(
          'The application deadline for the {partnerNameDegreeName} is only {lengthOfTime} away! Don’t delay. Start your application today.'
        )}
        partnerNameDegreeName={degreeLink}
        lengthOfTime={lengthOfTime}
      />
    </Notification>
  );
};

export default DegreeApplicationDeadlineNotification;
