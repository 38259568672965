import * as React from 'react';

import { get as getUser } from 'js/lib/user';

import { isCoachGAEpic, useProductFeatures } from 'bundles/ai-course-coach/utils/productFeatureFlags';
import { captureMessageInSentry } from 'bundles/ai-course-coach/utils/sentryUtils';
import { IdTypes } from 'bundles/product-features';

type Props = {
  children: (props: { showEmbeddedCoach: boolean }) => JSX.Element | null;
};

function useCoachPermissions() {
  const userId = getUser().id?.toString();
  const [showEmbeddedCoach, setShowEmbeddedCoach] = React.useState<boolean>(false);

  const { loading, error, features } = useProductFeatures({ idType: IdTypes.User, id: userId });
  const isCoachEnterpriseEnabled = features?.get?.('enterprise', 'enableAiCoach');
  const isCoachGA = isCoachGAEpic();

  React.useEffect(() => {
    if (loading || error || !features) {
      if (error) {
        captureMessageInSentry({ error, source: 'useCoachPermissions', extra: { features } });
      }
      setShowEmbeddedCoach(false);
      return;
    }

    // always enabled for Enterprise, if it is not enterprise enabled then check the holdout GA epic
    setShowEmbeddedCoach(isCoachEnterpriseEnabled || isCoachGA);
  }, [loading, error, features, isCoachEnterpriseEnabled, isCoachGA]);

  return { showEmbeddedCoach };
}

const CoachPermissionsWrapper: React.FC<Props> = ({ children }) => {
  const { showEmbeddedCoach } = useCoachPermissions();

  return children({ showEmbeddedCoach });
};

export { CoachPermissionsWrapper, useCoachPermissions };
