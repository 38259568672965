import { css } from '@emotion/react';

import { createZIndex } from '@coursera/cds-common';

import paperStyles, { classes } from '@core/Popover/styles/dropdownCss';

const styles = css`
  box-sizing: border-box;
  z-index: ${createZIndex.modal || 10000};
  ${paperStyles({ marginTop: 'var(--cds-spacing-150)' })};

  .${classes.paper} {
    background: var(--cds-color-neutral-background-primary);
  }
`;

export { classes };

export default styles;
