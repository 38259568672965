/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import { BellIcon } from '@coursera/cds-icons';
import { color } from '@coursera/coursera-ui';
import { SvgBell } from '@coursera/coursera-ui/svg';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';

import _t from 'i18n!nls/notification-center';

import 'css!./__styles__/NotificationIcon';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type Props = {
  active: boolean;
  unreadCount: number;
  onClick: () => void;
};

const styles = {
  buttonInUnifiedPageHeader: css`
    && {
      margin: 0;
      padding: var(--cds-spacing-150);
      color: var(--cds-color-neutral-primary-weak);
      transition: background-color 0.3s ease;
    }
  `,
  buttonLabel: css`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  `,
  icon: css`
    display: block;

    ${breakpoints.up('sm')} {
      width: 24px;
      height: 24px;
    }
  `,
  unreadCount: css`
    top: 6px;
    right: 14px;
  `,
};

const NotificationIcon: React.FC<Props> = ({ active, unreadCount, onClick }) => {
  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();
  return (
    <TrackedButton
      onClick={onClick}
      trackingData={{ unreadCount, active }}
      className="rc-NotificationIcon"
      trackingName="notification_icon"
      css={isSimplifiedPageHeader && styles.buttonInUnifiedPageHeader}
      variant="ghost"
      aria-label={_t('Notifications')}
      aria-expanded={active}
    >
      {isSimplifiedPageHeader ? (
        <React.Fragment>
          <BellIcon size="medium" css={styles.icon} color="support" />
          <span css={styles.buttonLabel}>{active ? _t('Hide notifications') : _t('Show notifications')}</span>
        </React.Fragment>
      ) : (
        <SvgBell
          title={active ? _t('Hide notifications') : _t('Show notifications')}
          color={active ? color.primary : color.black}
          desc="Notification Center Icon"
        />
      )}
      <div
        data-testid="unread-count"
        className="rc-NotificationIcon__UnreadCount"
        role="presentation"
        hidden={unreadCount <= 0}
        css={isSimplifiedPageHeader && styles.unreadCount}
      >
        {unreadCount}
      </div>
    </TrackedButton>
  );
};

export default NotificationIcon;
