// https://github.com/akiran/react-slick
import * as React from 'react';
import type { ResponsiveObject } from 'react-slick';
import Slider from 'react-slick';

import classNames from 'classnames';

import { isRightToLeft } from 'js/lib/language';
import type { TrackingData } from 'js/lib/retracked';

import { breakPoint, color } from '@coursera/coursera-ui';
import { SvgChevronLeft, SvgChevronRight } from '@coursera/coursera-ui/svg';

import { updateSlickAccessibilityAttributes } from 'bundles/design-system/utils/react-slick-utils';
import withUserAgent from 'bundles/page/common/withUserAgent';
import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/browse';

import 'css!./__styles__/BrowseCarousel';

type NavButtonProps = {
  className?: string;
  customClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  arrowColor?: string;
  hoverColor?: string;
  trackingData: TrackingData;
  isProductCardExperiment?: boolean;
};

function RightNavButton(props: NavButtonProps) {
  const { className, customClassName, onClick, arrowColor, hoverColor, trackingData } = props;
  const computedID = trackingData?.id || trackingData?.collectionName;

  return (
    <TrackedButton
      id={computedID ? `${computedID.replace(/\s+/g, '-')}:carousel-right` : undefined}
      className={classNames(className, customClassName)}
      trackingName="carousel_right_button"
      aria-label={_t('Next Slide')}
      onClick={onClick}
      data={trackingData}
      style={{ right: '-73px', left: 'auto' }}
    >
      {/* pass suppressTitle prop to SvgChevronLeft to enforce aria-hidden=true for JAWS screen readers" */}
      <SvgChevronRight
        size={48}
        color={arrowColor || color.black}
        hoverColor={hoverColor || color.darkPrimary}
        suppressTitle={true}
        desc={_t('Black right arrow icon indicating next slide')}
      />
    </TrackedButton>
  );
}

function LeftNavButton(props: NavButtonProps) {
  const { className, customClassName, onClick, arrowColor, hoverColor, trackingData, isProductCardExperiment } = props;
  const computedID = trackingData?.id || trackingData?.collectionName;

  return (
    <TrackedButton
      id={computedID ? `${computedID.replace(/\s+/g, '-')}:carousel-left` : undefined}
      className={classNames(className, customClassName)}
      trackingName="carousel_left_button"
      aria-label={_t('Previous Slide')}
      onClick={onClick}
      data={trackingData}
      style={{ left: isProductCardExperiment ? '-60px' : '-46px', right: 'auto' }}
    >
      {/* pass suppressTitle prop to SvgChevronLeft to enforce aria-hidden=true for JAWS screen readers" */}
      <SvgChevronLeft
        size={48}
        color={arrowColor || color.black}
        hoverColor={hoverColor || color.darkPrimary}
        suppressTitle={true}
        desc={_t('Black left arrow icon indicating previous slide')}
      />
    </TrackedButton>
  );
}

type BrowseCarouselProps = {
  children: JSX.Element[] | JSX.Element;
  rootClassName?: string;
  style?: React.CSSProperties;
  loadOnlyOnClient?: boolean;
  trackingData?: TrackingData;
  slidesToShow?: number;
  slidesToScroll?: number;
  enableRtl?: boolean;
  responsive?: Array<ResponsiveObject>;
  arrowColor?: string;
  hoverColor?: string;
  showDots?: boolean;
  showArrows?: boolean;
  variableWidth?: boolean;
  infinite?: boolean;
  prevArrowClassName?: string;
  nextArrowClassName?: string;
  dotsClassName?: string;
  isProductCardExperiment?: boolean;
} & PropsFromWithUserAgent;

type BrowseCarouselState = {
  currentPage: number;
  componentDidMount: boolean;
};

/** @deprecated replaced by the new ProductCardCollection, do not use this component at all cost */
export class BrowseCarousel extends React.PureComponent<BrowseCarouselProps, BrowseCarouselState> {
  carouselRef: Slider | HTMLElement | null | undefined;

  timeoutId = 0;

  static defaultProps = {
    showDots: true,
    showArrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  constructor(props: BrowseCarouselProps) {
    super(props);
    this.state = {
      currentPage: 1,
      componentDidMount: false,
    };
  }

  get shouldUseRTL() {
    // We use tricks to make react-slick somewhat functional in RTL, but we only want to do that if the document is
    // served in RTL and the parent component is prepared for them.
    return Boolean(this.props.enableRtl) && isRightToLeft(_t.getLocale());
  }

  componentDidMount() {
    this.timeoutId = window.setTimeout(() => {
      let event;
      if (typeof Event === 'function') {
        event = new Event('resize');
      } else {
        // IE 11 compatibility: https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11
        event = window.document.createEvent('Event');
        event.initEvent('resize', true, true);
      }
      window.dispatchEvent(event);
      this.triggerSlideChangeForRTL();
    }, 0);

    this.setState(() => ({ componentDidMount: true }));

    this.updateSlickAccessibilityAttributes();
  }

  componentDidUpdate() {
    this.updateSlickAccessibilityAttributes();
  }

  componentWillUnmount = () => {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  };

  updateSlickAccessibilityAttributes() {
    // Reach into slick to get a handle on the track DOM node.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const listElement: HTMLElement | undefined | null = this.carouselRef?.innerSlider?.list;
    if (listElement) updateSlickAccessibilityAttributes(listElement);
  }

  beforeChange = (_oldIndex: number, newIndex: number) => {
    this.onCarouselChange(newIndex + 1);
  };

  onCarouselChange = (idx: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const innerSlider = this.carouselRef?.innerSlider;

    if (innerSlider) {
      const spec = { ...innerSlider.props, ...innerSlider.state };
      this.setState({ currentPage: Math.round(idx / spec.slidesToScroll) + 1 });
    }
  };

  setRef = (node: Slider | null) => {
    this.carouselRef = node;
  };

  triggerSlideChangeForRTL = () => {
    if (this.shouldUseRTL) {
      // react-slick is super broken in RTL, seemingly never tested for it with advanced layouts. When it initializes
      // in RTL, crucial state members are not set correctly, which breaks the carousel on first interaction. I've
      // found that clicking the "first" dot gets it to fix itself, so I'm doing that programmatically here. The
      // maintainer has not been attentive recently and many RTL issues/PRs have been dismissed or ignored.
      //
      // Long term, we should consider forking react-slick to fix the issues (and PR them back, hopefully they get
      // merged), or we should switch to a better maintained dependency.
      //
      // `innerSlider` is internal state that we are punching through to for the purpose of implementing the above
      // trick.
      //
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const innerSlider = this.carouselRef?.innerSlider;
      if (innerSlider) {
        const spec = { ...innerSlider.props, ...innerSlider.state };
        const lastIndex = Math.max(0, Math.ceil((spec.slideCount - spec.slidesToShow) / spec.slidesToScroll));
        // another hack to fix the RTL issue
        this.triggerSlideChange(0); // initially set to zero (last index is zero in RTL)
        this.triggerSlideChange(lastIndex); // then set back to the first slide
      }
    }
  };

  triggerSlideChange = (index: number) => {
    // @ts-expect-error
    const innerSlider = this.carouselRef?.innerSlider;
    if (innerSlider) {
      const spec = { ...innerSlider.props, ...innerSlider.state };
      innerSlider.changeSlide(
        /* options: */ {
          message: 'dots',
          index,
          slidesToScroll: spec.slidesToScroll,
          currentSlide: spec.currentSlide,
        },
        /* dontAnimate: */ true
      );
    }
  };

  render() {
    const {
      rootClassName,
      prevArrowClassName,
      nextArrowClassName,
      dotsClassName,
      style: inputStyle,
      arrowColor,
      hoverColor,
      variableWidth,
      showDots,
      loadOnlyOnClient,
      trackingData,
      slidesToShow = BrowseCarousel.defaultProps.slidesToShow,
      slidesToScroll,
      showArrows,
      infinite = false,
      responsive,
      userAgent,
      isProductCardExperiment,
    } = this.props;
    const { currentPage, componentDidMount } = this.state;
    const isMobile = userAgent?.isMobileBrowser;
    // Default settings would be overwritten by responsive if passed into the Slider component
    const responsiveSettings: ResponsiveObject[] = responsive || [
      {
        breakpoint: breakPoint.md,
        settings: {
          slidesToShow: slidesToShow - 2,
          slidesToScroll: 1,
          infinite,
        },
      },
      {
        breakpoint: breakPoint.lg,
        settings: {
          slidesToShow: slidesToShow - 1,
          slidesToScroll: slidesToShow - 1,
          infinite,
        },
      },
    ];

    const isRTL = this.shouldUseRTL;
    const canRender = (loadOnlyOnClient && componentDidMount) || !loadOnlyOnClient;

    return (
      <div className={classNames('rc-BrowseCarousel', rootClassName || '')} style={inputStyle}>
        {canRender && (
          <Slider
            accessibility={true}
            rtl={isRTL}
            ref={this.setRef}
            responsive={responsiveSettings}
            speed={500}
            dotsClass={classNames('carousel-dots', dotsClassName)}
            dots={showDots}
            infinite={infinite}
            lazyLoad={componentDidMount ? 'ondemand' : undefined}
            appendDots={(dots) => <ul role="list">{dots}</ul>}
            onInit={this.triggerSlideChangeForRTL}
            customPaging={(i) => {
              return (
                <button type="button" aria-label={`Slide ${i + 1}`} aria-current={Math.round(currentPage) === i + 1}>
                  {i + 1}
                </button>
              );
            }}
            slidesToScroll={slidesToScroll}
            slidesToShow={slidesToShow}
            variableWidth={isMobile || variableWidth}
            arrows={showArrows}
            beforeChange={this.beforeChange}
            prevArrow={
              <LeftNavButton
                customClassName={prevArrowClassName}
                arrowColor={arrowColor}
                hoverColor={hoverColor}
                trackingData={{ currentPage, ...trackingData }}
                isProductCardExperiment={isProductCardExperiment}
              />
            }
            nextArrow={
              <RightNavButton
                customClassName={nextArrowClassName}
                arrowColor={arrowColor}
                hoverColor={hoverColor}
                trackingData={{ currentPage, ...trackingData }}
              />
            }
          >
            {this.props.children}
          </Slider>
        )}
        {canRender && (
          <div aria-live="polite" aria-atomic="true" aria-relevant="text" className="screenreader-only">
            {_t('You are Currently on slide #{currentPage}', { currentPage: Math.round(currentPage) })}
          </div>
        )}
      </div>
    );
  }
}

/** @deprecated replaced by the new ProductCardCollection, do not use this component at all cost */
export default withUserAgent()(BrowseCarousel);
