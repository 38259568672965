import user from 'js/lib/user';

import { PROFILE_CONSENT_COOKIE_CONSENT_NAMES } from 'bundles/marketing-consent/constants';
import useEnterpriseUserInfo from 'bundles/marketing-consent/hooks/useEnterpriseUserInfo';
import type { ProfileConsent } from 'bundles/marketing-consent/types';
import { isMissingCookieConsent } from 'bundles/marketing-consent/utils';
import { useNaptime } from 'bundles/naptimejs';
import ProfileConsentsV1 from 'bundles/naptimejs/resources/profileConsents.v1';

type ProfileConsentData = {
  profileConsents?: ProfileConsent;
};

const useProfileConsents = (userId: number) => {
  const {
    data: { profileConsents },
  } = useNaptime<ProfileConsentData>(() => {
    const isMissingCCPAConsentCookie = isMissingCookieConsent(PROFILE_CONSENT_COOKIE_CONSENT_NAMES.ccpaRequired);
    const isMissingGDPRConsentCookie = isMissingCookieConsent(PROFILE_CONSENT_COOKIE_CONSENT_NAMES.gdprRequired);

    if (!isMissingCCPAConsentCookie && !isMissingGDPRConsentCookie) {
      return {};
    }

    return {
      profileConsents: ProfileConsentsV1.get(userId, {
        fields: [
          ...(isMissingGDPRConsentCookie ? ['requireGdprConsent'] : []),
          ...(isMissingCCPAConsentCookie ? ['requireCcpaConsent'] : []),
        ],
      }),
    };
  }, [userId]);

  return { profileConsents };
};

const useMarketingConsentData = (userAccountId?: number) => {
  const userId = userAccountId || user.get().id;
  const { profileConsents } = useProfileConsents(userId);
  const requireCCPAConsent = !!profileConsents?.requireCcpaConsent;
  const requireGDPRConsent = !!profileConsents?.requireGdprConsent;
  const shouldSkipEnterpriseQuery = !requireCCPAConsent && !requireGDPRConsent;
  const { isEnterpriseAdmin, isRestrictedEnterpriseUser } = useEnterpriseUserInfo(shouldSkipEnterpriseQuery, userId);
  const shouldShowCCPAMarketingConsent = !!(requireCCPAConsent && !isRestrictedEnterpriseUser);
  const shouldShowGDPRMarketingConsent = !!(requireGDPRConsent && !isRestrictedEnterpriseUser);

  return { shouldShowCCPAMarketingConsent, shouldShowGDPRMarketingConsent, isEnterpriseAdmin };
};

export default useMarketingConsentData;
