/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { scrubKeyComponentName } from 'js/lib/retrackedUtils';

import { Link, typography2 } from '@coursera/cds-core';

import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

export type Props = {
  label?: string;
  href?: string;
  component?: React.ReactElement;
  isCurrent?: boolean;
  name?: string;
};

export type PageNavLinkElement = React.ReactElement<Props, typeof PageNavLink> | null;

const styles = {
  link: (isCurrent?: boolean) => css`
    // chaining the selectors to override CDS styles
    &&& {
      display: block;
      white-space: nowrap;
      padding: var(--cds-spacing-150) var(--cds-spacing-150);
      color: var(--cds-color-neutral-primary-weak);
      text-decoration: none;
      ${typography2.bodyPrimary}

      :hover {
        color: var(--cds-color-interactive-primary);
      }

      :focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;

        /* override box-shadow from .cds-link-focusVisible */
        box-shadow: none !important;
      }

      ${isCurrent === true &&
      `
        font-weight: bold;
        color: var(--cds-color-interactive-primary);
        border-bottom: 4px solid var(--cds-color-interactive-primary);
      `}
    }
  `,
};

/**
 * PageNavLink renders a link in the page navigation.
 * It's used as child comoponent of PageNavigation.
 * If the "component" prop is passed in, it will be rendered as is, and should manage its state/styling/a11y etc.
 */
const PageNavLink = ({ component, label, href, isCurrent, name }: Props) => {
  if (component) {
    return component;
  }

  if (!label || !href) {
    return null;
  }

  return (
    <Link
      href={href}
      variant="quiet"
      css={styles.link(isCurrent)}
      component={TrackedLink2}
      trackingName={scrubKeyComponentName(`page_nav_link_${name ?? href}`)}
      {...(isCurrent ? { 'aria-current': 'page' } : {})}
      data-e2e={`page-nav-link-${name ?? href}`}
    >
      {label}
    </Link>
  );
};

export default PageNavLink;
