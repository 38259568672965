import type React from 'react';

import type { IconProps } from '@coursera/cds-common';
import {
  ErrorFilledIcon,
  InfoFilledIcon,
  SuccessFilledIcon,
  WarningFilledIcon,
} from '@coursera/cds-icons';

import type { severities } from '@core/notifications/LocalNotification/getLocalNotificationCss';

export type SeverityIconProps = {
  /**
   * Notification severity type
   */
  severity: keyof typeof severities;
};

const SeverityIcon = (
  props: SeverityIconProps
): React.ReactElement<SeverityIconProps> => {
  const { severity } = props;
  const commonProps: IconProps = {
    size: 'large',
  };

  switch (severity) {
    case 'success':
      return <SuccessFilledIcon color="success" {...commonProps} />;
    case 'warning':
      return <WarningFilledIcon color="warning" {...commonProps} />;
    case 'error':
      return <ErrorFilledIcon color="error" {...commonProps} />;
    case 'information':
    default:
      return <InfoFilledIcon color="interactive" {...commonProps} />;
  }
};

export default SeverityIcon;
